.promotional-sidebar {
    margin-bottom: 1.5rem;
    overflow: auto;
    
    img,
    h3 {
        margin-bottom: 1rem;
        width: 100%;
    }

    h3 {
        color: $color-zeus-black;
    }

    p {
        margin-bottom: 2rem;
    }

    .button--gilded {
        float: right;
    }
}