@mixin editView($w) {
  cursor: pointer;
  text-transform: uppercase;
  font-size: 0.8125em;
  font-family: "Roboto";
  font-weight: 500;
  width: $w;
  text-align: center;
  display: inline-block;
  //background: $color-yellow-orange;
  color: $color-white;
}

.personalisationhome {
  .article-preview--small.topics {
    padding: 0px;
    .article-metadata {
      margin: 0px;
      &.sponsored_cont {
        border-bottom: 1px solid #d1d3d4;
        padding: 20px;
        height: 150px;
        &:last-child {
          border-bottom: none;
        }
      }
      &.nosponsored_cont {
        border-bottom: 1px solid #d1d3d4;
        padding: 10px 20px;
        height: 150px;
        background: $color-concrete-gray;
        &:last-child {
          border-bottom: none;
        }
      }
      ul li {
        max-width: none;
        margin-right: 0;
        padding-right: 0;
      }
    }
  }
  @include breakpoint("large") {
    .topic-featured-article__headline {
      font-size: 1.2rem;
      line-height: 1.625rem;
    }
    .article-preview--small {
      .article-preview__headline {
        font-size: 1.375rem;
        line-height: 1.625rem;
      }
    }
  }
  @include breakpoint("small") {
    .article-preview {
      position: inherit;
    }
    .article-summary {
      height: 15.625rem;
      overflow: hidden;
    }
  }
  .hidespin {
    display: none;
  }
  .hidden-lg {
    display: none !important;
  }
  .article-preview__headline {
    font-size: 1.625rem;
  }
  .article-preview__tags {
    position: absolute;
    bottom: 1.5rem;
    width: calc(100% - 2.5rem);
    @include breakpoint("medium") {
      width: calc(100% - 2.5rem);
    }
    @include breakpoint("small") {
      position: static;
    }
  }
  .article-metadata {
    margin-top: 0px;
    ul {
      display: block;
      li {
        display: inline-block;
        vertical-align: middle;
        margin-right: 0.375rem;
        padding-right: 0.375rem;
        /*&:nth-child(2){
					max-width: 85px;
					text-overflow: ellipsis;
				}*/
      }
    }
    @include breakpoint("large") {
      li {
        margin-right: 0.25rem;
        padding-right: 0.25rem;
      }
    }
  }
  .personalHead {
    border-bottom: 1px solid $color-light-gray;
    padding-bottom: 0.625rem;
    margin-bottom: 1.25rem;
    h1 {
      float: left;
      @include breakpoint("small") {
        font-size: 2rem;
      }
    }
		.onoffswitch {
			clear: none;
			margin-top: 14px;
      margin-left: 1.5625rem;
			&-label {
				width: calc(50% + 22px);
			}
			&-inner {
				&:before,
				&:after {
					display: none;
				}
			}
			&-off, &-on {
				@include textMix('Roboto', 9px, 400);
				display: block;
				float: left;
				width: 50%;
				height: 22px;
				padding: 0;
				line-height: 22px;
				color: $color-white;
				box-sizing: border-box;
			}
			&-off {
				padding-left: 9px;
				background-color: $color-white;
				color: $color-old-silver-gray; // also try $color-dusty-gray
			}
	
			&-on {
				padding-right: 9px;
				background-color: $color-white;
				color: $color-old-silver-gray; // also try $color-dusty-gray
				text-align: right;
			}
			&-switch {
				left: 50%;
			}
			.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
				margin-left: -100%;
			}
		}
    @include breakpoint("small") {
      border-bottom: none;
      margin-bottom: 0.875rem;
      padding-bottom: 0rem;
    }
    .view-mode {
      padding: 0;
      float: right;
      @include breakpoint("small") {
        display: none;
      }
      li {
        list-style-type: none;
        float: left;
        &.selected {
          background: #f0f1f3;
        }
        a {
          width: 50px;
          height: 50px;
          float: left;
          border: 1px solid #f0f1f3;
          color: #f0f1f3;
          position: relative;
          text-decoration: none;
          font-size: 0;
          &:before {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 26px;
          }
          &.active {
            background-color: #f0f1f3;
          }
        }
      }
      li[data-view="listView"] {
        a {
          &:before {
            content: "";
            height: 24px;
            width: 22px;
            background: url(/dist/img/list-view.png) no-repeat;
          }
        }
      }
      li[data-view="tileView"] {
        a {
          &:before {
            content: "";
            height: 26px;
            width: 22px;
            background: url(/dist/img/dashboard.png) no-repeat;
          }
        }
      }
    }
  }
  .sub-heading-text {
    margin-bottom: 1.25rem;
    p {
      //line-height: 1rem;
      font-style: normal;
      font-weight: 400;
      font-size: 1.125em;
      @include breakpoint("small") {
        font-size: 0.875em;
        margin: 0px;
        padding: 0px;
        font-family: "Roboto";
        line-height: 1.3125rem;
      }
    }
  }
  @media screen and (min-width: 768px) {
    .latestSubject {
      color: $color-zeus-black;
      position: relative;
      margin: 3.125rem 0;
      span {
        background: $color-white;
        padding-right: 1.25rem;
        font-size: 2rem;
        font-family: "Roboto condensed";
        position: relative;
        font-weight: bold;
        z-index: 2;
        top: -20px;
      }
      .fllatestSub {
        background-color: $color-cardinal-red;
        content: "";
        display: block;
        height: 4px;
        top: calc(60% - -8px);
        z-index: 1;
        @media screen and (min-width: 1025px) {
          position: absolute;
          float: left;
        }
        @media screen and (min-width: 768px) and (max-width: 1024px) {
          margin-bottom: 10px;
        }
        @media screen and (min-width: 767px) and (max-width: 768px) {
          margin: 20px 0 10px;
        }
      }
      .frEditview {
        float: right;
      }
      .editView {
        @include editView(8.75rem);
        float: right;
        margin: 0px;
        position: relative;
        top: 5px;
      }
      .seeAllChannels {
        @include editView(auto);
        float: right;
        margin: 0px;
        position: relative;
        top: 5px;
        margin-right: 10px;
      }
    }
  }
  .latest-news__articles {
    .article-small-preview {
      border: 1px solid $color-light-gray;
      -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
      flex: 1 0 auto;
      height: 13.75rem;
      width: 18.75rem;
      margin: 0 1rem 2.25rem;
      padding: 1rem 1.25rem 3rem;
      line-height: 1.5rem;
      line-height: 1.5rem;
      overflow: hidden;
      @include breakpoint("small") {
        &.mobview {
          border: none;
          border-top: 2px solid #d1d3d4;
          padding: 1rem 0 0;
          margin: 0 0.9rem;
        }
        &.mobview.sponsored_cont {
          padding: 1rem;
        }
      }
    }
    .sm-articles {
      height: 29.375rem;
      border: none;

      .sm-article {
        margin: 0 -1rem;
        padding: 1rem 1.25rem 3rem;
        border: 1px solid #d1d3d4;
        -webkit-box-flex: 1;
        -ms-flex: 1 0 auto;
        flex: 1 0 auto;
        height: 13.75rem;
        line-height: 1.5rem;
        overflow: hidden;
        @include breakpoint("small") {
          &.mobview {
            border: none;
            border-top: 2px solid #d1d3d4;
            padding: 1rem 0 0;
            margin: 0 -1.25rem !important;
            height: auto;
          }
          &.mobview.sponsored_cont {
            padding: 1rem;
          }
        }
      }
    }
  }

  .editView {
    @include editView(8.75rem);
    float: left;
    margin: 0.325rem 0 0 1.5625rem;
  }
  .seeAllChannels {
    @include editView(auto);
    float: left;
    margin: 1.125rem 0 0;
  }

  .article-preview--small {
    &.topics {
      //background: $color-concrete-gray;
      &:after {
        height: 0rem;
      }
      h6 {
        margin-bottom: 0.625rem;
      }
      @include breakpoint("small") {
        height: auto;
      }
    }
    .article-preview_rheadline {
      font-family: "Roboto Condensed", Arial Narrow, sans-serif;
      border-bottom: 1px solid $color-light-gray;
      margin-bottom: 1rem;
      padding-bottom: 1rem;
      font-size: 1.2rem;
      font-weight: 700;
      margin-top: 10px;
      line-height: 2.125rem;
      height: 7.5rem;
      overflow: hidden;
      &:last-child {
        border-bottom: none;
      }
      &.sponsored_cont {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
      }
      &.nobord {
        border-bottom: none;
        @include breakpoint("small") {
          margin-bottom: -0.625rem;
          padding: 0px;
        }
      }
      a {
        height: 70px;
        overflow: hidden;
        display: block;
        color: #000;
        &:hover {
          color: #be1e2d;
        }
      }
      @include breakpoint("small") {
        height: auto;
      }
      @include breakpoint("large") {
        a {
          font-size: 1.375rem;
          height: 6.25rem;
          overflow: hidden;
          display: block;
        }
      }
    }
  }
  .loadmore {
    text-align: center;
    margin-bottom: 2.5rem;
    span {
      font-family: "Roboto";
      font-size: 0.875rem;
      padding: 1.25rem 4.375rem 1.25rem 3.75rem;
      border: 1px solid $color-light-gray;
      display: inline-block;
      cursor: pointer;
      font-weight: bold;
      position: relative;
      &:after {
        content: "";
        background: url("/dist/img/sprite-image.png") no-repeat -17px -124px;
        width: 18px;
        height: 14px;
        position: absolute;
        right: 40px;
        top: 20px;
      }
    }
  }

  .topic-featured-article__image2 {
    margin-bottom: 1rem;
    width: 21.0625rem;
    height: 11.875rem;
    @media screen and (max-width: 1024px) and (min-width: 768px) {
      width: 100%;
      height: auto;
    }
    @include breakpoint("small") {
      margin-top: 1.25rem;
      width: 100%;
    }
  }
  .googleAdd {
    margin-bottom: 5rem;
    text-align: center;
  }

  .article-preview--small {
    &.preview2 {
      height: 43.75rem;
      @include breakpoint("small") {
        //padding: 0 15px;
        margin-bottom: 0px;
        height: auto;
      }
      .article-preview__inner-wrapper {
        height: 15.3125rem;
      }
    }
  }
  .mtop {
    margin-top: -15rem !important;
    @include breakpoint("medium-small") {
      margin-top: 0px !important;
    }
  }
  @include breakpoint("medium-small") {
    .googleAdd {
      margin-bottom: 2.5rem;
      img {
        max-width: 100%;
        width: 100%;
      }
    }
  }
  .artheight {
    height: 28.75rem;
  }
  .sm-article {
    &:nth-child(2n + 1) {
      position: relative;
      top: -0.4375rem;
      margin-bottom: 0.625rem !important;
    }
  }

  @include breakpoint("small") {
    .hidden-xs {
      display: none;
    }
    .hidden-lg {
      display: block !important;
    }
    .article-preview--small {
      border: none;
      border-top: 2px solid #d1d3d4;
      position: relative;
      &.mobview {
        padding: 1rem 0 0;
        margin: 0 1rem;
      }
      &.mobview.sponsored_cont {
        padding: 1rem;
      }
    }
    .topic-featured-article {
      &.article-preview {
        padding: 1rem;
      }
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1090px) {
    .article-preview--small {
      width: 12.5rem;
    }
    .latest-news__articles {
      .article-small-preview {
        width: 12.5rem;

        .article-preview__inner-wrapper {
          height: 100px;
          overflow: hidden;

          @include breakpoint("large") {
            .article-preview__headline {
              font-size: 1.375rem;
              line-height: 1.625rem;
            }
          }
        }
        ul li {
          font-size: 0.875rem;
          .article-metadata {
            font-size: 0.8125rem;
          }
          h6 {
            font-size: 0.8125rem;
          }
        }
      }
    }
    .article-metadata {
      ul {
        display: block;
        width: calc(100% - 20px);
        li {
          display: inline-block;
          vertical-align: middle;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .article-preview--small {
      width: 90%;
    }
    .latest-news__articles .article-small-preview {
      width: 90%;
    }
    .latest-news__articles .article-preview--small .article-preview__tags {
      left: 0;
      position: absolute;
    }
    .latest-news__articles
      .article-preview--small.sponsored_cont
      .article-preview__tags {
      left: 1rem;
    }
    .mtop {
      margin-top: 0px !important;
    }
    .googleAdd {
      img {
        max-width: 100%;
        width: 100%;
      }
    }
    .latestSubject {
      margin-bottom: 1rem;
      color: $color-zeus-black;
      span.sub {
        //border-left: 0.625rem solid $color-cardinal-red;
        font-size: 2rem;
        font-family: "Roboto condensed";
        font-weight: bold;
        min-height: 2.3125rem;
        display: block;
        padding-top: 1rem;
        padding-left: 0.625rem;
        letter-spacing: -1px;
      }
      .editView {
        @include editView(8.75rem);

        &.mobview {
          margin: 4rem 1rem 0.2rem;
        }
      }
      .seeAllChannels {
        @include editView(auto);
        &.mobview {
          display: none;
        }
      }
    }
  }

  .article-preview__inner-wrapper {
    &.showarticle {
      height: 6.25rem;
      overflow: hidden;
      .article-preview__headline {
        height: 4.375rem;
        overflow: hidden;
        @media screen and (min-width: 768px) and (max-width: 1024px) {
          height: 3.125rem;
          margin-bottom: 1.25rem;
        }
      }
      .article-preview__byline {
        height: 1.5625rem;
        overflow: hidden;
      }
    }
  }
  .articleloadInfo {
    text-align: right;
    font-style: italic;
    margin-bottom: 5px;
    font-family: "Roboto";
    @media screen and (max-width: 767px) {
      text-align: left;
      margin-bottom: 1.25rem;
    }
  }
}

.listViewCont {
  display: none;
  @media screen and (max-width: 767px) {
    display: none !important;
  }
}
.gridViewCont {
  display: none;
  @media screen and (max-width: 767px) {
    display: block !important;
  }
}
@media screen and (min-width: 768px) {
  .listView {
    .listViewCont {
      display: block;
    }
    .topic-featured-article__headline {
      font-size: 1.2rem;
      margin-bottom: 0.5rem;
      line-height: 2.125rem;
      @media screen and (min-width: 600px) {
        max-height: 65px;
        overflow: hidden;
      }
    }
    .article-metadata__date {
      font-size: 0.9375rem;
      font-weight: normal;
    }
    .topic-article-image_pan {
      margin-top: 0px;
      min-height: 11.875rem;
      float: left;
      .topic-featured-article__image {
        width: 17.5rem !important;
        height: 10rem !important;
        margin-right: 32px;
        margin-top: 0px;
      }
    }
    .topic-featured-article__headline {
      margin-top: 0px;
    }
    .bar-separated-link-list a {
      padding: 0 17px 0 0;
      margin-right: 17px;
    }
    .topic-article-rig_pan {
      //float: left;
    }
    .article-preview__tags {
      @media screen and (min-width: 1025px) {
        width: 75%;
      }
      @media screen and (min-width: 767px) and (max-width: 768px) {
        width: 56%;
      }
      @media screen and (min-width: 1023px) and (max-width: 1024px) {
        width: 65%;
      }
      @media screen and (max-width: 680px) {
        width: auto;
      }
      @media screen and (min-width: 768px) {
        &.wid {
          width: 100%;
        }
      }
      z-index: 999;
      bottom: 1.25rem;
      a {
        @media screen and (min-width: 1025px) {
          max-width: inherit;
        }
        @media screen and (min-width: 768px) and (max-width: 1024px) {
          max-width: 32%;
          font-size: 1rem;
        }
      }
    }
    .article-metadata {
      margin-bottom: 0px;
      ul li {
        &:nth-child(2) {
          max-width: inherit;
        }
        @media screen and (min-width: 767px) and (max-width: 768px) {
          &.wid {
            max-width: 85px !important;
          }
        }
      }
    }
    .sponsored_cont {
      .article-metadata {
        margin-bottom: 10px;
      }
    }
    .list-featured-article {
      border-bottom: 1px solid #e7e7e7;
      margin-bottom: 1rem;
      padding-bottom: 2px;
      width: 100%;
      .article-preview__byline {
        margin-bottom: 0.3125rem;
        .authorTitle {
          width: 35%;
          float: left;
        }
        ul {
          display: inline-block;
          float: right;
          width: 65%;
          text-align: right;
          margin-top: 1px;
        }
        &:after {
          clear: both;
          content: "";
          display: block;
        }
      }
    }
    .topic-featured-article__inner-wrapper {
      font-size: 18px;
      color: #231f20;
      height: 8.125rem;
      line-height: 24px;
      &:after {
        background: linear-gradient(
          to bottom,
          rgba(241, 242, 242, 0) 0%,
          #fff 65%,
          #fff 100%
        );
        height: 5rem;
      }
    }
    .sponsored_cont {
      .topic-featured-article__inner-wrapper {
        &:after {
          background: linear-gradient(
            to bottom,
            rgba(241, 242, 242, 0) 0%,
            #fef3e9 65%,
            #fef3e9 100%
          );
          height: 60px;
        }
      }
    }
  }
}
.gridView {
  .gridViewCont {
    display: block;
  }
  .article-preview__tags a {
    max-width: 29%;
    @media screen and (min-width: 768px) and (max-width: 1024px) {
      &.tabTexonomy {
        max-width: 16%;
      }
    }
  }
  .topic-featured-article__image {
    @media screen and (min-width: 767px) and (max-width: 768px) {
      margin-bottom: 8rem;
    }
  }
}

.personalisationhome {
  .layout2 {
    .article-preview--small.topics {
      .article-metadata {
        &.sponsored_cont {
          height: 153px;
        }
        &.nosponsored_cont {
          height: 153px;
        }
      }
    }
  }
}

// TODO: move to theme files
@mixin mysettings-scrip-medtech($bg, $hoverbg) {
  .personalisationhome .latestSubject .seeAllChannels {
    background: $bg;
    border: 1px solid $bg;
    color: #fff !important;
    &:hover {
      background: $hoverbg;
      border: 1px solid $hoverbg;
      color: #fff !important;
    }
  }
  .personalisationhome .editView {
    background: $bg !important;
    border: 1px solid $bg !important;
    color: #fff !important;
    &:hover {
      background: $hoverbg !important;
      border: 1px solid $hoverbg !important;
      color: #fff !important;
    }
  }
  @include breakpoint("desktop") {
    header .header-account-access .myView-placeholder .btn-my-view,
    .header-account-access__free-trial a,
    .header-account-access__free-trial-signin a {
      background: $bg;
      &:hover {
        background: $hoverbg;
      }
    }
  }
  .js-debug-expand-button {
    background: $bg !important;
    &:hover {
      background: $hoverbg !important;
    }
  }
}
@mixin apply-myview-theme($bgc) {
  @media screen and (min-width: 768px) {
    .latestSubject {
      .fllatestSub {
        background-color: $bgc;
      }
    }
  }
  .personalisationhome
    .article-preview--small
    .article-preview_rheadline
    a:hover {
    color: $bgc;
  }
}

.theme-lloydslist {
  @include apply-myview-theme(#1191d1);
  @media screen and (max-width: 767px) {
    .personalisationhome .latestSubject span.sub {
      border-left: 0.625rem solid #1191d1;
    }
  }
}

.theme-insurance-day {
  @include apply-myview-theme(#de4361);
  @media screen and (max-width: 767px) {
    .personalisationhome .latestSubject span.sub {
      border-left: 0.625rem solid #de4361;
    }
  }
}

.theme-commodities {
  @include apply-myview-theme(#9e310d);
  @media screen and (max-width: 767px) {
    .personalisationhome .latestSubject span.sub {
      border-left: 0.625rem solid #9e310d;
    }
  }
}

.common-font {
  color: #231f20;
  font-weight: 700;
  text-transform: uppercase;
  color: #231f20;
}

li.common-font h6 {
  display: inline-block;
  float: left;
  border-right: 1px solid #737373;
  padding-right: 8px;
  font-size: 22px;
  margin-bottom: 0 !important;
  margin-right: 8px;
}
h1.article-preview_rheadline {
  display: inline;
  line-height: 22px !important;
  height: auto !important;
  text-transform: capitalize;
}
li.common-font h1 a {
  display: inline !important;
  height: auto !important;
}
