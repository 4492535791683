#foodNews{
	font-family: "Roboto";
	.subtitle{
		font-size: 1.6875em;
		margin-bottom: 25px;
		font-weight: normal;
		font-style: italic;
		font-family: "PTSerif Italic";
		span{
			font-weight: bold;
		}
		
	}
	.searchpan{
		border: 1px solid #d1d3d4;
		background: #fbfbfb;
		padding: 20px;
		label[for=searchNews]{
			float: left;
			font-size: 1.5em;
			color: #231f20;
			margin-right: 22px;
		}
		#searchNews{
			width: 300px;
			height: 34px;
			float: left;
			font-size: 0.9375em;
			font-style: italic;
		}
		.searchImg{
			cursor: pointer;
			margin: 6px 0 0 20px;
			float: left;
		}
	}
	.page-account__table{
		
	}
	
	.downloadBtn{
		border: 1px solid #f7941e;
		cursor: pointer;
		text-transform: uppercase;
		font-size: 0.75em;
		font-weight: 700;
		width: 120px;
		height: 32px;
		text-align: center;
		display: inline-block;
		line-height: 32px;
		&:hover{
			background: #f7941e;
			color: #fff;
		}
	}
	.curSubscriber{
		border-top: 1px solid #d1d3d4;
		margin-top: 30px;
		padding-top: 30px;
		h3{
			font-size: 1.2rem;
			color: #231f20;
			font-weight: bold;
			margin-bottom: 20px;
		}
		p{
			font-family: "PTSerif regular";
			font-size: 1.29em;
			line-height: 32px;
			a{
				color: #f38220;
			}
		}
	}
	.pagination{
		li{
			> a{
				background: url(/dist/img/sprite-image.png) no-repeat;
				height: 40px;
				width: 40px;
				overflow: hidden;
				display: block;
				float: left;
				margin-right: 10px;
				text-indent: -9999px;
			}
		}
		span a{
			float: left;
			height: 40px;
			width: 40px;
			margin-right: 10px;
			background: #f1f1f1;
			text-align: center;
			color: #000;
			line-height: 40px;
			&:hover{
				text-decoration: none;
			}
			&.active{
				background: #c6c2c2;
			}
		}
		a.prev{ 
			background-position: -4px -400px;
		}
		a.next{
			background-position: -45px -400px;
		}
		 
			
		.nums{
			text-align: center;
			li{
				display: inline-block; 
			}
		} 
	}
	.hidden-lg{
		display: none !important;
	} 
	@include breakpoint('medium-small') {
		.sortable-table__col{
			padding: 1rem;
		}
	}
	@include breakpoint('small') {
		.page-account__table{margin-bottom: 0px;}
		.searchpan{
			margin-bottom: 20px;
			
			label[for=searchNews]{
				font-size: 1.1em;
				color: #000;
				float: none;
				display: block;
				margin-bottom: 10px; 
			}
			#searchNews {
				width: 205px;
				height: 44px;
			}
			.searchImg {
				margin-top: 10px;
			}
		}
		.subtitle {
			font-size: 1.2em; 
			font-style: normal;
			font-family:  "Roboto";
		}
		
		.eachCategory{
			display: block;
			font-size: 1.125em;
			.tableMrow {
				clear: both;
				border: 1px solid #d2d4d3;
				border-bottom: none; 
				.title{
					background: #ececec;
					padding: 12px; 
					color: #231f20;
					font-weight: bold;
				}
				.item{
					padding: 18px 12px 12px 12px;
					border-bottom: 1px solid #d1d3d4;
				}
				.download{ 
					padding: 18px 12px 12px 12px;
					border-bottom: 1px solid #d1d3d4;
					text-align: center;
					.downloadBtn {
						cursor: pointer; 
						font-size: .95em; 
						width: 170px;
						height: 52px; 
						line-height: 52px;
						color: #000;
						letter-spacing: 1px;
					}
				}
			}
		}
		.sortable-table tbody tr{
			border: none;
		}
		.sortable-table tbody tr td{
			display: block;
		}
		.hidden-lg{
			display: block !important;
		} 
	}
}	