@mixin fontFamily($family){
	font-family: $family;
}

#main-carousel {
	@media only screen and (min-width: 767px) and (max-width: 1024px) {
		padding-right: 12px;
	}
	a[title="JavaScript charts"] {
		display: none !important;
	}
	.item-wrapper {
	    width: 25%;
    	float: left;
    	@media only screen and (min-width: 420px) and (max-width: 1024px) {
    		width: 50%;
    		height: 265px;
    	}
    	@include breakpoint('small') {
    		width: 100%;
    	}
		.amcharts-chart-div{
			width: auto !important;
			@media only screen and (min-width: 668px) and (max-width: 1024px) {
				width: auto !important;
			}
		}
	}
		.owl-carousel .item{
			@media only screen and (min-width: 767px) and (max-width: 1024px) {
				height: auto;
			}
			@include breakpoint('small') {
				height: auto;
			}
		}
		.sorting-arrows__arrow {
			width: 35px;
			height: 35px;
			fill: black;
			&.left-arrow {
				transform: rotate(-90deg);
			}
			&.right-arrow {
				transform: rotate(90deg);
			}
		}
		.owl-theme .owl-nav [class*='owl-']{
			background: none;
		}
		.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span{
			background: #fff;
		}
		.li-content-company {
		  border: 1px solid transparent;
		  background-color: #fff;
		  height: 234px;
		  padding: 15px 18px;
		  margin-top: 10px;
		  &:after{
				display: block;
				clear: both;
				content: '';
			}
			@include breakpoint('medium') {
				&:after{
					display: block;
					clear: both;
					content: '';
				}
			}
		}
		@media only screen and (min-width: 767px) and (max-width: 1024px) {
			.showinTab {
				display: block !important;
			}
		}
		.li-content-company img {
		  float: left;
		  margin: 0 10px 10px 0;
		  width:100px;
		}
		.li-content-company p.li-content-company__headline {
		  padding: 0 0 8px 0;
		  font-size: 1.25rem;
		  margin-bottom: 20px;
		}
		.li-content-company p.view-more-info.li-content-company__headline {
		   font-size: 0.875rem;
		}
		.li-content-graph {
		  border: none;
		  background-color: #eeeeee;
		  padding: 20px 30px 25px;
		  margin: 0.625rem 0.375rem 0 0.375rem;
		  @media (min-width: 1025px){
		      margin-top: 0.625rem;
		  }
		  > a{
			  display: block;
			  background: #fff;
			  div{
				  div.amcharts-chart-div{
					  height: 150px !important;
					  svg{
						height: 150px !important;
						
					  }
				  }
			  }
		  }
		}
		.li-content-graph p {
		  font-size: 0.875rem;
		  padding: 0 0 0 0;
		  margin-bottom: 22px;
		}
		
		.hideinMob{
			display: block;
			@include breakpoint('medium-small') {
				display: none;
			}
		}
		.showinMob{
			display: none;
			@include breakpoint('medium-small') {
				display: block;
				width: 100%;
				-o-transition-property: none !important;
				-moz-transition-property: none !important;
				-ms-transition-property: none !important;
				-webkit-transition-property: none !important;
				transition-property: none !important;
				/*CSS transforms*/
				-o-transform: none !important;
				-moz-transform: none !important;
				-ms-transform: none !important;
				-webkit-transform: none !important;
				transform: none !important;
				 /*CSS animations*/
				-webkit-animation: none !important;
				-moz-animation: none !important;
				-o-animation: none !important;
				-ms-animation: none !important;
				animation: none !important;
			}
		}
		.owl-dots{
			position: absolute;
			top: -1px;
			margin-top: -28px;
			margin-left: 0;
			margin-right: 0;
			width: 100%;
			right: 15px;
			text-align: right;
			@include breakpoint('small') {
				right: 10px;
			}
		}
		.owl-prev{
			position: absolute;
			top: 140px;
			left: -34px;
			margin-top: 0;
			margin-bottom: 0;
			opacity: 0.7;
			filter: alpha(opacity=70);
			@include breakpoint('medium') {
				top: 40px;
			}
		}
		.owl-next{
			position: absolute;
			top: 140px;
			right: -44px;
			@media screen and (min-width: 768px) and (max-width: 1024px) {
				right: -32px;
				margin-left: 0;
    			margin-right: 0;
			}
			@include breakpoint('small') {
				right: -31px;
			}
			margin-top: 0;
			margin-bottom: 0;
			opacity: 0.7;
			filter: alpha(opacity=70);
			@include breakpoint('medium') {
				top: 40px;
			}
		}
		.companies_heading {
			display: block;
			width: 100%;
			position: relative;
			background-color: #de4361;
			span{
				width: 100%;
				display: inline-block;
				padding: 10px 0 10px 20px;
				color: #fff;
				@include textMix('Roboto', 1rem, 500);
				@media (max-width: 667px){
					padding-bottom: 30px;
				}
				@media only screen and (min-width: 768px) and (max-width: 1024px){					
					width: 70%;
				}
			}
		}
}
#graph-carousel{
	.company_title{
		padding-bottom: 20px;
	}
	margin-bottom: 2rem;
	&:after{
		clear: both;
		content: '';
		display: block;
	}
	.loadChart{
		width: 24%;
		float: left;
		@media screen and (min-width: 768px) and (max-width: 1024px)  {
			width: 49%;
		}
		@media screen and (max-width: 767px) {
			width: 100%;
			float: inherit;
		}
	}
	.owl-controls{
		.owl-nav{
			display: none !important;
		}
	}
	#chartdiv_GWP, #chartdiv_NWP, #chartdiv_UR, #chartdiv_NP, #chartdiv_SF, #chartdiv_NWPNR, #chartdiv_SFNR, #chartdiv_NPSFR{
		background: #fff;
		margin: 0px auto;
		width: 100%;
		height: 150px;

	}
	.graph_heading {
		display: block;
		width: 100%;
		position: relative;
		border-top: 1px solid #d1d3d4;
		padding-top: 20px;
		@media screen and (max-width: 767px) {
			border-top: none;
			padding-top: 0px;
		}
		span{
			width: 100%;
			display: inline-block;
			padding: 10px 0 10px 20px;
			color: #fff;
			background-color: #de4361;
			font-size: 1rem;
			line-height: 19px;
			font-weight: 500;
			font-family:"Roboto", Arial;
		}
	}
	.owl-theme .owl-dots{
		text-align: right;
	}
	.owl-theme .owl-dots .owl-dot{
		span{
			margin: 5px;
			background: #ad001b !important; 
		}
		&.active{
			span{
				background: #fff !important;
			}
		}
	}
	.graph-container{
		margin-top: 0.625rem;
		margin-left: 0.5%;
		margin-right: 0.5%;
		background-color: #eee;
		height: 237px;  
		padding: 20px 30px;
		h5{ 
			font-size: 14px;
			font-weight: 500;
			height: 40px;
			vertical-align: middle;
			display: inline-block;
			text-align: left;
			// margin: 20px 20px 0px 30px;
			overflow: hidden;
			line-height: 20px; 
		 }
	}
	.owl-dots{
		position: absolute;
		top: -1px;
		margin-top: -28px;
		margin-left: 0;
		margin-right: 0;
		width: 100%;
	}
	.owl-prev{
		position: absolute;
		top: 50px;
		left: 0;
		margin-left: -18px;
		margin-top: 0;
		margin-bottom: 0;
	}
	.owl-next{
		position: absolute;
		top: 50px;
		right: 0;
		margin-right: -18px;
		margin-top: 0;
		margin-bottom: 0;
	}
	@include fontFamily("verdana");
	.owl-carousel .item{
		height: 234px;
	}
	.owl-theme .owl-nav [class*='owl-']{
		background: none;
	}
	.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span{
		background: #fff;
	}
	
	.li-content-graph {
	  border: none;
	  background-color: #eeeeee;
	  height: 100%;
	  padding: 20px 30px 25px;
	  margin: 0.625rem 0 0 10px;
	  
	  > a{
		  display: block;
		  background: #fff;
		  div{
			  div.amcharts-chart-div{
				  height: 150px !important;
				  svg{
					height: 150px !important;
				  }
			  }
		  }
	  }
	}
	.li-content-graph p {
	  font-size: smaller;
	  padding: 0 0 0 0;
	  margin-bottom: 22px;
	}
	.li-content-graph p a {
		color: #000;
	}
	.li-content-graph p a:hover {
	}
	.showin-desktop{
		float: left;
		
		@include breakpoint('small') {
			display: none;
		}
		
		
	}
	.showinMob{
		display: none;
		
		@include breakpoint('small') {
			display: block;
		}
		.li-content-graph {
			margin: 0.625rem 0 0 0;
		}	
	}
	
}

#mycarousel1, #mycarousel2{
	margin-bottom: 1rem;
	@include textMix('Roboto', 1em, 400);
	.companylogo{
		float: left;
		width: 43%;
		margin-top: 9px;
		img{
			max-width: 100px;
			max-height: 60px;
		}
	}
	.companyLinks{
		float: left;
		width: 57%;
		margin-top: 3px;
		@include textMix('Roboto', 1.25em, 400);
		
	}
	.li-content-graph{
		@include textMix('Roboto', 0.875em, 500);
		a{
			cursor: pointer;
		}
	}
	.owl-dots .owl-dot{
		span{
			background: #ad001b !important;
			&:hover{
				background: #fff !important;
			}
		}
		&.active{
			span{
				background: #fff !important;
			}
		}
	}
	
}
#mycarousel1 {
	.owl-item.active {
		.item-wrapper:last-child .li-content-graph{
			margin-right: 0 !important;
		}
	}
}	
