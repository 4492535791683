.id-merges-acquisition {
	font-family: 'Roboto';
	.table-responsive {
		position: relative;
	}
	.idi-table-heading {
		margin-top: 0;
		padding: 10px 20px;
	}
	#scrollbar-horizantal {
	    height: 10px;
    	background-color: #eee;
    	position: relative;
    	overflow: hidden;
    	display: none;
    	@media only screen and (min-width: 767px) and (max-width: 1024px) {
    		display: block;
    	}
    	&:after {
    		content: '';
    		height: 1px;
    		width: 100%;
    		background-color: #777777;
    		position: absolute;
    		top: 50%;
    	}
    	#scrollbar {
    		height: 8px;
    		background-color: #777777;
    		width: 20px;
    		position: absolute;
    		top: 1px;
    		left: 0;
    	}
	}
	.merges-form, .visible-xs {
		display: none;
		@include breakpoint('small') {
			display: block;
		}
	}
	.desktop-controls {
		float: right;
	}
	tbody.visible-xs {
		td.pad-10 {
			padding: 0.5rem 1rem !important;
		}
	}
	.show-largest-btn {
		padding: 10px;
	    color: $color-white !important;
	    float: left;
	    margin-right: 10px;
	    font-size: 14px;
	    &:hover, &:focus, &:active {
	    	color: $color-white !important;
	    	text-decoration: none !important;
	    }
	    &.active {
	    	position: relative;
	    	&:after {
    		    content: '';
			    height: 5px;
			    background-color: rgb(0, 166, 83);
			    bottom: 0;
			    position: absolute;
			    width: 100%;
			    right: 0;
	    	}
	    }
	}
	.small_table .table-responsive .pad-10 {
		padding: 1rem;
	}
	.small_table .table-responsive .R16 {
		font-size: 15px;
	}
	.dealsOmitted {
	    width: 100%;
	    padding: 16px 0 18px 0;
	    text-align: right;
	    background-color: #fff;
	    margin: 0;
	}
	hr {
		display: block !important;
		padding: 0;
		margin: 0 0 20px 0 !important;
		&.m-0 {
			margin: 0 !important;
		}
	}
	input.range-field {
	    width: 38px !important;
	    @media only screen and (min-width: 767px) and (max-width: 1024px) {
	    	width: 35px !important;
	    }
	    @include breakpoint('small') {
	    	width: 41% !important;
	    }
	 }
	 .wrap-merge {
	 	@media only screen and (min-width: 767px) and (max-width: 1024px) {
	 		width: 100%;
	 		overflow-x: scroll;
	 		overflow-y: scroll;
	 		height: 500px;
	 		position: relative;
	 	}
	 }
	.table.merge-acquistion {
		border-top: none;
		table-layout: fixed;
		.suggest-more{
			display: none !important;
		}
		@include breakpoint('small') {
			border: none;
		}
		@media only screen and (min-width: 767px) and (max-width: 1024px) {
			width: 1160px !important;
		}
		input[type="text"] {
		    width: 100%;
		    color: #000;
		}
		tbody.visible-lg tr:nth-child(even) {
			background-color: #eee;
		}
		tbody.visible-lg {
			tr {
				td {
					&:first-child {
						padding-left: 20px;
					}
					&:last-child {
						padding-right: 20px;
					}
				}
			}
		}
		tbody.visible-xs {
			tr {
				border-left: 1px solid #d1d3d4;
				border-right: 1px solid #d1d3d4;
				td:first-child {
					border-right: 1px solid #d1d3d4;
					border-left: 1px solid #d1d3d4;
					vertical-align: top;
					font-weight: bold;
				}
				td.boder-none {
					border: none !important;
				}
				td:last-child {
					border-right: 1px solid #d1d3d4;
				}
			}
		}
		.selectivity-input {
		    width: 100%;
		    color: #000;
		 }
		.range-wrapper {
		    float: left;
		    width: 100%;
		 }
		 
		 .selectivity-single-select {
		    box-sizing: border-box;
		 }
		 .selectivity-single-select-input {
		       padding: 2px !important;
		       width: 100% !important;
		 }
		 .selectivity-single-result-container {
		    top: 4px;
		 }
		 .form-control {
		    margin-bottom: 20px;
		 }
		 .form-control input {
		    width: 100%;
		 }
		 &.table {
			 .table_head th {
			 	padding-top: 0;
			 	padding-bottom: 0;
			 	&:first-child {
			 		.label-wrap, .field-wrap {
				 		padding-left: 20px;
				 	}
			 	}
			 	&:last-child {
			 		.label-wrap, .field-wrap {
				 		padding-right: 20px;
				 	}
			 	}
			 	.label-wrap {
			 		padding: 5px;
			 		&:after {
						visibility: hidden;
					     display: block;
					     font-size: 0;
					     content: " ";
					     clear: both;
					     height: 0;
					}
				 	span {
				 		float: left;
				 		width: calc(100% - 15px);
				 	    font-weight: 500;
		    			margin-top: 5px;
		    			font-size: 1rem;
		    			font-style: normal;
				 	}
				}
				.field-wrap {
					padding: 5px;
					font-size: 16px;
					background-color: #C0C0C0;
					&:after {
						visibility: hidden;
					     display: block;
					     font-size: 0;
					     content: " ";
					     clear: both;
					     height: 0;
					}
					input {
						border-radius: 5px;
					    font-weight: normal;
					    font-size: 16px;
					    padding: 2px;
					}
				}
			 }
		}
		
	 }
	.idi-table-heading {
		.heading {
			float: left;
			@media only screen and (min-width: 1024px) {
				margin-top: 6px;
				margin-bottom: 0;
			}
		}
		.dropdown-wrapper {
			float: right;
			background: #fff;
			color: #000000;
		}
	}
	.marketdata-links{
		.hideMarketData{
			&.hide{
				display: none;
			}
		}
		.shippingData{
			&.hide{
				display: none;
			}
		}
	}

	.graphs-container h4 { text-transform: uppercase; padding-top: 10px;}
	.graph-container h5 {
		font-size: 14px;
		font-weight: 500;
		vertical-align: middle;
		display: inline-block;
		text-align: left;
		margin: 20px 20px 0 0;
		overflow: hidden;
		line-height: 20px;
		height: 40px;
	}
	.graphs-container .graph-container {
	    text-align: left;
	    padding: 0 30px 25px;
	    background-color: #EEEEEE;
	    @include breakpoint('small') {
	    	width: 100%;
	    }
	    &:first-child {
	    	border-left: none;
	    }
		#chartdiv-deals, #chartdiv-type, #chartdiv-region, #chartdiv-region2{
			height: 150px !important;
		} 
	}
	.graphs-container .dealsOmitted {
		float: right;
	    width: 100%;
	    padding: 10px 0;
	    text-align: right;
	    background-color: #fff;
	}
	.graphs-container {
		@include breakpoint('small') {
			overflow-x: scroll;
		}
		.graphs {
			@include breakpoint('small') {
				width: 1053px;
			}
			
			.graph-wrapper {
				margin: 0.625rem 0.5rem 0 0;
				float: left;
				width: 24%;
				height: 234px;
				@include breakpoint('small') {
					width: 250px;
				}
				&:first-child{
					margin-left: 0 !important;
				}
				&:last-child{
					margin-right: 0 !important;
				}
				.graph-container {
					height: 234px;
				}
			}
		}
	}
	#market-data{
		font-size: 0.9375rem; 
		font-family: "Roboto", Helvetica, Arial, sans-serif;
		.carousel-div{
			position:relative;
			border-right: 1px solid #e9e9e9;
			width: 100%;
			.states_heading{
				position:relative;
				float:left;
				width:20%;
				z-index: 9;
				@media only screen and (min-width: 767px) and (max-width: 1024px) {
					width: 20%;
				}
				@include breakpoint('small') {
					width: 50%;
				}
				.title{
					background-color: #1191d1;
					color: #ffffff;
					padding: 8px;
					font-weight: 500;
				}
				.R16{
					padding: 12px 10px;
					border-bottom: 1px solid #e1e1e1;
					border-right: 1px solid #e9e9e9;
					@media only screen and (min-width: 767px) and (max-width: 1024px) {
						padding: 12px 6px;
					}
					&:hover{
						background: #efd0d8 !important;
					}
				}
				.leftbord{
					border-left: 1px solid #e9e9e9;
				} 
			}
		} 
		h2{
			font-size: 46px;
			font-weight: bold;
			margin-bottom: 40px;
			@include breakpoint('small') {
				font-size: 36px;
				margin-bottom: 20px;
			}
		}
		.oddCls{
			background: #f1f1f1;
		} 
		#marketDataTable{ 
			.R16{
				padding: 12px 10px;
				border-bottom: 1px solid #e1e1e1;
				border-right: 1px solid #e9e9e9;
			}
			.owl-controls{
				display: none;
			}
			@media only screen and (max-width: 767px) {
				.owl-controls{
					display: block;
				}
			}
			.item{
				height: auto !important;
				&:first{
					border-left: 1px solid red;
				}
			}
			.pad-20{
				padding: 10px 20px;
				@media only screen and (max-width: 1024px) {
		    		padding: 10px 5px;
		    	}
				@media only screen and (max-width: 768px) {
		    		padding: 10px 2px;
		    	}
				@media only screen and (max-width: 767px) {
		    		padding: 10px 10px;
		    	}
			}
			.title{
				background-color: #1191d1;
				color: #ffffff;
				padding: 8px;
				font-weight: 500;
			} 
			span.numData{
				width: 40px;
				display: inline-block;
			}
			span.rise {
				color: #56c405;
				width: 40px;
				text-align: right;
				display: inline-block;
			}
			span.fall {
				color: #f40600;
				text-align: right;
				width: 40px;
				display: inline-block;
			}
		}
	}
	.merges-form {
		font-family: 'Roboto';
		.merge-form-items {
			display: none;
			.show-largest-btn {
				margin: 0;
			    font-size: 12px;
			    padding: 12px 10px;
			    width: 100%;
			    text-align: center;
			}
			.forms {
				width: 50%;
				padding: 10px;
				float: left;
				color: $color-white;
				label {
					color: $color-white;
					display: block;
				}
				input {
					width: 100%;
					padding: 3px;
					color: #58595b;
				}
			}
			.controls {
				float: left;
				width: 100%;
				.selectivity-input {
					width: 100%;
					overflow: hidden;
					color: #58595b
				}
			}

		}
		&.open {
			.merge-heading .merge-acordian svg {
				transform: rotate(180deg);
				-moz-transform: rotate(180deg);
			}
			.merge-form-items {
				display: block;
			}
		}
		.merge-heading {
			padding: 10px;
			p {
				float: left;
				width: calc(100% - 40px);
				color: $color-white;
				padding-right: 5px;
				margin-bottom: 0;
			}
			.merge-acordian {
				width: 40px;
			    float: left;
			    height: 40px;
			    display: block;
			    border-left: 1px solid $color-white;
			    svg {
			        width: 30px;
	    			height: 25px;
	    			fill: $color-white !important;
	    			margin: 7px 0 0 7px;
	    			&:active {
	    				fill: $color-white !important;
	    			}
	    			&:hover {
	    				fill: $color-white !important;	
	    			}
			    }
			}
		}
	}
	.sorting-buttons {
		width: 15px;
		float: left;
		padding-top: 2px;
		    height: 24px;
    position: relative;
		a {
			display: block;
			position: absolute;
			left: 0;
			&[type="ascending"] {
				transform: rotate(180deg);
				margin-top: 3px;
				-moz-transform: rotate(180deg);
				top: -3px;
			}
			&[type="descending"] {
				margin-bottom: 3px;
				bottom: -3px;
			}
			svg {
				height: 15px;
				fill: $color-white !important;
				margin-top:-7px;
				&:active {
					fill: $color-white !important;
				}
			}
		}
	}
	.scrollbar-container {
	    position: absolute;
	    right: -1rem;
	    bottom: 0;
	    height: 500px;
	    width: 16px;
	    background-color: #f1f2f2;
	    z-index: 9;
	    display: none;
	    @media only screen and (min-width: 767px) and (max-width: 1024px) {
    		display: block;
    	}
	    &:after {
	    	content: '';
	    	position: absolute;
	    	top: 0;
	    	right: 7px;
	    	width: 1px;
	    	height: 100%;
	    	background:#d1d3d4;
	    	z-index:1;
	    }
	}
	.scrollbar {
		  position: absolute;
		  right: 4px;
		  height: 30px;
		  width: 7px;
		  background: #777777;
		  z-index:2;
		}
}
