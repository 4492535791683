.subscriptionPan{
	font-family: "Roboto", Helvetica, Arial, sans-serif;
	.hideCol{
		visibility: hidden;
	}
	.mySubscribe{
		background: #fff !important;
		color: #58595b !important;
	}

	@include breakpoint('small') {
		font-size: 1em;
		line-height: 20px;
		width: 100%;
	}
	#mySubscription{
		margin-bottom: 50px;
		width:100%;
	}
	#mySubscriptionTab{
		width: 100%;
		border: 1px solid #d2d4d3;
		border-collapse: collapse;
		@include breakpoint('small') {
			border: none;
			.hidden-lg{
				display: block !important;
		    }
		}
		@include breakpoint('medium-small') {
			.sortable-table__header{
				display: none;
			}
			.hidden-lg{
				display: block !important;
		    }
		} 
		.button--outline{
			&.mySubscribe{
				cursor: text;  
			}
			&:hover{
				text-decoration: none;
			}
		}
	}
	#mypurchases{
		width: 100%;
		border: 1px solid #d2d4d3;
		border-collapse: collapse;
		@include breakpoint('small') {
			border: none;
		}
	}
	.page-account__table{
		border: 1px solid #d2d4d3;
		@include breakpoint('small') {
			border: none;
		}
	}
	.page-account_text{
		font-family: 'PT Serif';
		font-style: normal;
		font-weight: 400;
		font-size: 1.125em;
		margin-bottom: 1.25rem;
		display: block;
			@include breakpoint('small') {
			font-size: 1em;
			line-height: 20px;
		}
	}
	.sortable-table__col{
		
		text-align: left;
		position:relative;
		padding: 1rem;
		& .accordian{
			background: url(/dist/img/sprite-image.png) no-repeat -18px -120px;
			height: 17px;
			min-width: 17px;
			display: inline-block;
			cursor: pointer;
			&.active{
				background: url(/dist/img/sprite-image.png) no-repeat 0 -100px;
			} 
			@include breakpoint('small') {
				display: none;
			}
		} 
		& .accordian:nth-child(2){
		    position: absolute;
			right: 20px;
		}
		@include breakpoint('medium-small') {
			width: 100%;
			display: table-cell;
			padding: 12px;
			font-size: 1em;
			line-height: 24px;
		}
	}
	.tableMrow{ 
		@include breakpoint('medium-small') {
			clear: both;
			border: 1px solid #d2d4d3;
			margin-bottom: 20px;
			.pubName{
				width: 60%;
				float: left;
				padding: 12px;
			}
			.pubSubscribe{
				width: 32%;
				float: left;
				padding: 12px 0;
				.button{
					padding-top: 7px;
					padding-bottom: 7px;
					display: inline-block;
				}
			}
		}
		.tablerow{
			clear: both;
			border: 1px solid #d2d4d3;
		}
		a.button--outlined {
			border: 1px solid $color-yellow-orange !important;
		}
	}
	.parent{
		border-top: 1px solid #d2d4d3;
	}
	.child{
		border-top: 1px solid #d2d4d3;
	} 
	.hiderow{
		display: none;
	}
	.title{
		background: #ececec;
		padding: 10px;
		font-size: 1.125em;
		color: #231f20;
		font-weight: bold;
		@include breakpoint('medium-small') {
			clear: both;
		}
	}
	.maintitle{
			background: #ececec;
			position: relative;
			&:after {
				content: '';
				position: absolute;
				background: url(/dist/img/sprite-image.png) no-repeat 0 -81px;
				height: 0.75rem;
				width: 1.4375rem;
				top: 12px;
				right: 20px;
			}
		&.active{
			background: #ececec;
			&:after {
				background: url(/dist/img/sprite-image.png) no-repeat 0 -48px
			}
		}
	}
	.hidden-lg{ 
		display: none !important;
	}
}

#purchases{
	@include breakpoint('medium-small') {
		.sortable-table__header{
			display: none;
		}
	}
	#mypurchases{
		tr{
			td:nth-child(1){
				width: 20%;
			}
			td:nth-child(2){
				width: 55%;
			}
			td:nth-child(3){
				width: 22%;
			}
		}
	}
	.purrow{
		border-top: 1px solid #d2d4d3;
	}
}

.tablesorter-headerAsc .sorting-arrows__arrow--up, .tablesorter-headerAsc .sorting-arrows__arrow--up use > svg{
	fill: #be1e2d;
}

.tablesorter-headerDesc .sorting-arrows__arrow--down, .tablesorter-headerDesc .sorting-arrows__arrow--down use > svg {
	fill: #be1e2d;
}

@include breakpoint('small') {
	font-size: 0.9375rem;
	.hidden-xs{
		display: none !important;
	}
	.page-account__header{
		font-size: 2.25rem;
		line-height: 32px;
	}
}
@include breakpoint('medium-small') {
	.subscriptionPan{
		.hidden-lg{
			display: block !important;
		}
		.hidden-xs{
			display: none !important;
		}
	}
}

@media only screen and (min-width: 481px) {
.subscriptionPan{
	.purchases{ 
		.sortable-table__col{
		position:relative;
			&.fdata{
				width: 30%; 
			}
			&.sdata{
				width: 50%; 
			}
			&.tdata{
				width: 20%; 
			}
		} 
	}
}
}

// TODO: move to theme files
@mixin subscribe-btn($c){
	.mySubscribe{
		border: 1px solid $c !important;
  }
	#mySubscriptionTab{
		tbody{
			td.sortable-table__col:nth-child(3) a{
				border: 1px solid $c;
				&:hover {
					background-color: $c;
				}
			}
		}
		.button--outline{
			border:1px solid $c;
			&:hover{
				background: $c;
			}
		}
	}
}

.theme-lloydslist{
    .mySubscribe{
			border: 1px solid #1191D1 !important;
    }
	@include subscribe-btn(#1191D1);
}
.theme-insurance-day{
    .mySubscribe{
			border: 1px solid #b0173d !important;
    }
	@include subscribe-btn(#b0173d);
}
.theme-commodities{
    .mySubscribe{
			border: 1px solid #9E310D !important;
    }
	@include subscribe-btn(#9E310D);
}