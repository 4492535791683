.longread-article {
    &.article-unentitled {
        float: left;
        max-width: 790px;
        @include breakpoint('medium') {
            width: 100%;
            clear: both;
            float: none;
        }
        .lra-body {
            .meta {
                width: auto;
                margin: 0;
            }
            .content {
                div.article-call-to-action--sign-in-up {
                    .paywall-message {
                        padding: 10px 25px;
                    }

                    @media (min-width: 640px) {
                        display: inline-block;
                        width: 100%;
                        word-break: break-word;
                        .article-call-to-action__column {
                            display: inline-block;
                            width: 50%;
                            float: left;
                            padding: 10px 25px;
                        }
                    
                        .article-call-to-action__column.trial-level {
                            width:100%;
                        }
                    }
                }
                .article-promotional-block-title {
                    margin-bottom: -.75rem;
                }
            }
        }
    }
    .hero-banner {
        position: relative;
        overflow: hidden;
        & ~ .main__content {
            margin-top: 20px;
        }
        @include breakpoint('medium-small') {
            height: 450px;
        }
        img {
            position: relative;
            display: block;
            width: 100%;
            height: auto;
            z-index: 1;
            @include breakpoint('medium-small') {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                width: auto;
                height: 100%;
            }
        }
        .text-overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            max-width: 1240px;
            margin: 0 auto;
            padding: 20px;
            z-index: 3;
            @include breakpoint('medium-small') {
                width: 100%;
                padding: 1rem;
                margin: 0;
            }
            h1 {
                width: 100%;
                max-width: 350px;
                margin: 0 0 0 auto;
                padding: 1rem 1.25rem;
                font-size: 19px;
                text-align: right;
                color: white;
                background-color: rgba(0,0,0,.5);
            }
        }
    }
    .hero-banner-metadata {
        margin-top: 0.5rem;
        &__source {
            float: right;
            width: 23%;
            @include textMix('Roboto', 13px, 400);
            color: $color-dusty-gray;
            line-height: 1.5rem;
            text-align: right;
            @include breakpoint('medium') {
                float: none;
                display: block;
                width: auto;
            }
        }
    }
    .main__content {
        max-width: none;
        margin: 0;
        padding: 0;
    }
    .lra-header {
        margin: 0 0 12px;
        h2 {
            font-size: 38px;
        }
        p {
            line-height: 2rem;
        }
    }
    .lra-body {
        font-size: 16px;
        font-weight: 400;
        p {
            line-height: 1.5625rem;
        }
        .meta {
            float: left;
            width: 350px;
            margin: 0 2rem 1rem 0;
            @include breakpoint('medium-small') {
                float: none;
                width: 100%;
                margin: 0 0 2rem;
            }
            .summary {
                margin-bottom: 1rem;
                .article-executive-summary {
                    border-bottom: none;
                    padding-bottom: 0;
                    margin-bottom: 0;
                }
            }
            .article-author {
                &__contact {
                    font-weight: 400;

                    a {
                        display: block;
                        margin-bottom: 0.375rem;
                        width: 100%;

                        &:first-child {
                            margin-right: 0;
                            padding-right: 0;

                            &:after {
                                display: none;
                            }
                        }
                    }
                }
            }
            .nav {
                padding-left: 1rem;
                padding-right: 1rem;
                & > * {
                    @include breakpoint('medium-small') {
                        display: inline-block;
                        margin: 0;
                        a {
                            &::after {
                                content: ' |';
                            }
                        }
                        &:last-child a::after {
                            content: '';
                        }
                    }
                }
                h4 {
                    margin-bottom: 0.5em;
                }
                p {
                    margin-bottom: 0.5em;
                    line-height: 1.4rem;
                }
            }
        }
        .content {
            h2[name] {
                font-size: 26px;
                @include breakpoint('medium') {
                    padding-top: 40px;
                    margin-top: -40px;
                }
            }
            table {
                float: left;
                max-width: 50%;
                margin: 0 1rem 0 0;
                &.article-table--mobile-link {
                    display: none;
                }
                @include breakpoint('medium') {
                    max-width: none;
                    width: 100%;
                    margin-right: 0;
                    margin-bottom: 1rem;
                    &:not(.article-table--mobile-link) {
                        display: none;
                    }
                    &.article-table--mobile-link {
                        display: table;
                    }
                }
            }
            .quick-facts {
                margin: 0 0 0 1rem;
            }
            .article-pullquote {
                float: left;
                clear: none;
                max-width: 50%;
                margin: 0 0 1rem 0;
                @include breakpoint('medium') {
                    max-width: none;
                    margin: 0 0 1rem;
                }
            }
            .article-sidebar {
                margin: 0 0 1rem 0;
            }
            .article-exhibit {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                clear: none;
                max-width: 100%;
                margin: 0;
                &--pull-left,
                &--pull-right {
                    float: none;
                    margin-left: 0;
                    text-align: left;
                }
                &--pull-center {
                    display: block;
                    clear: both;
                    float: none;
                    margin-right: auto;
                    margin-left: auto;
                    text-align: center;
                    .enlarge {
                        display: inline-block !important;
                        width: auto;
                        margin-left: auto;
                        margin-right: auto;
                        img {
                            width: auto;
                            margin-bottom: 0;
                            vertical-align: bottom;
                        }
                    }
                    .article-exhibit__caption {
                        flex: none;
                        width: auto;
                    }
                    .article-exhibit__source {
                        float: none;
                        width: auto;
                        text-align: inherit;
                    }
                }
                @include breakpoint('medium-small') {
                    width: 100%;
                    max-width: none;
                    margin: 1rem 0;
                }
                .enlarge {
                    display: block;
                }
                & + p {
                    clear: none;
                }
            }
            .iframe-component {
                float: left;
                margin: 2em 2em 2em 0;
            }
            .rte-video-container {
                overflow: auto;
                float: left;
                margin: 1em 1em 1em 0;
                @include breakpoint('medium-small') {
                    float: none;
                    margin-left: 0;
                    margin-right: 0;
                }
                & > * {
                    clear: both;
                    display: block;
                }
                .iframe-component {
                    margin: 0 0 1rem;
                    @include breakpoint('medium-small') {
                        width: 100%;
                    }
                    &__desktop {
                        display: block;
                        @include breakpoint('medium-small') {
                            display: none;
                        }
                    }
                    &__mobile {
                        display: none;
                        @include breakpoint('medium-small') {
                            display: block;
                        }
                    }
                }
                .article-exhibit__source {
                    clear: none;
                }
            }
            .inline-related-content {
                clear: right;
                float: right;
                width: 290px;
                max-width: 50%;
                margin: 2rem 0 2rem 2rem;
                @include breakpoint('small') {
                    float: none;
                    width: 100%;
                    max-width: none;
                    margin: 1rem 0;
                }
                h2 {
                    font-size: 26px;
                }
                .ContentRecomm-ReadNext .section-article {
                    float: none;
                    width: auto;
                    height: auto;
                }
                .article-metadata {
                    margin-bottom: 0;
                    h5 {
                        margin-bottom: 0 !important;
                    }
                }
            }
            .fade-required {
              position: relative;
              margin-bottom: 1rem;
              &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                // p line number x line-height
                height: 6.4rem;
                max-height: 100%;
                background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
              }
            }
        }
    }
}