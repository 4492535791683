/*---
title: Leaderboard
section: Advertising
---
Leaderboard-style ads are replaced with smaller images at smartphone width (480px)
```example:html
<div class="advertising advertising--leaderboard">
	<span class="advertising__label">Advertisement</span>
    <img src="http://placebear.com/728/90" width="728" height="90" alt=""></a>
</div>
```
*/

/*---
title: Rectangle
section: Advertising
---

```example:html
<div class="article-preview__ad advertising">
    <span class="advertising__label">Advertisement</span>
	<img src="http://placebear.com/300/250" width="300" height="250" alt="">
</div>
```
*/

.advertising {
	top: 0;

	&--leaderboard {
		width: 100%;
		text-align: center;
		padding: 30px 0;

		@include breakpoint('small') {

			padding: 0;

			img {
				width: 100%;
				display: block; /* Prevents unwanted space below img */
			}
		}

		& .advertising__label {
			display: block;
			margin: 0 auto 0.25rem;
			text-align: left;
			width: 728px;
            text-align:center;
            @include breakpoint('small') {//IPMP-2256
            	width: 100%;
            }
		}
	}

	&--filmstrip {

	}

	&--rectangle {

	}

	&__label {
		@include textMix('Roboto', 11px, 500);
		display: block;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: $color-light-gray;
		margin-bottom: 0.25rem;
	}

	@include breakpoint('wide') {
		// The last advertisement in the right sidebar of article pages should become sticky when scrolled past
		// on desktop/wide resolution only
		&--sticky .advertising {
			position: fixed;
			top: 4px;
			max-width: 290px;
		}
	}

}
.horizontal-menu {
	.advertising {
		&--sticky .advertising {
			top: 55px;
		}
	}
}
 .manually-curated-content__right-rail .article-preview-small .advertising
 {
     text-align:center;
 }