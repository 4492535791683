.article-key-documents {
    @include textMix('Roboto', 15px, 400);
    margin-bottom: 2rem;
}

.article-key-document {
    display: flex;
    padding: 0;

    & + li.article-key-document {
        padding-top: 8px;
    }

    &__icon {
        @include svg-fill($color-tree-poppy-orange);
        margin-right: 0.5rem;
        height: 1.375rem;
        width: 1.375rem;
        flex: 0 0 auto;
    }

    a {
        line-height: 1.375rem;
    }
}
