.carousel-infographic {
    margin-bottom: 2.5rem;
    .topic-featured-article__headline {
        font-size: 26px;
    }
    &.owl-carousel {
        .owl-stage {
            display: flex;
        }
        .owl-item {
            .article-metadata img {
                width: auto;
                height: 30px;
            }
            .topic-featured-article__image {
                width: 384px;
                max-width: 100%;
                margin-bottom: 0;
                object-fit: cover;
                @include breakpoint('lg') {
                    float: none;
                    margin-bottom: 1rem;
                }
            }
        }
        .owl-nav {
            margin-top: 0;
            [class*=owl-] {
                position: absolute;
                z-index: 1;
                top: calc(50% - 16px - 1rem);
                transform: translateY(-50%);
                width: 32px;
                height: 32px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                border: none;
                padding: 0;
                background-color: #000;
                svg {
                    width: 32px;
                    height: 32px;
                    fill: #FFF;
                }
            }
            .owl-prev {
                transform: translateY(-50%) rotate(90deg);
            }
            .owl-next {
                right: calc(1.5rem - 24px);
                transform: translateY(-50%) rotate(-90deg);
            }
        }
        .owl-dots {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 10px;
            margin-top: 2.5rem;
            .owl-dot {
                display: block;
                span {
                    width: 15px;
                    height: 15px;
                    margin: 0;
                    padding: 0;
                    background-color: #CCCCCC;
                }
                &.active {
                    span {
                        background-color: #000;
                    }
                }
            }
        }
    }
    section.sponsored_cont {
        min-height: auto;
        padding: 0 4rem;
        margin-bottom: 0;
        background-color: transparent;
        @include breakpoint('lg') {
            padding: 0 3rem;
        }
        &::after {
            display: none;
        }
    }
    .topic-featured-article {
        &__inner-wrapper {
            height: auto;
            overflow: visible;
            &:after {
                display: none;
            }
        }
    }
}