// * * * * *
// TOPIC - Featured Article — Full Width
// IIPP-69

.topic-featured-article {
    background-color: $color-concrete-gray;
    border-top: 2px solid $color-light-gray;
    margin-bottom: 1.5rem;
    width: 100%;
    min-height: 290px;

    // Overrides _article-preview.scss
    // TODO — Refactor so this isn't necessary
    &.article-preview {
        padding: 1.5rem;
    }

    &__image {
        float: left;
        margin-bottom: 2rem;
        margin-right: 24px;
        height: 216px;
        width: 384px;
    }

    &__inner-wrapper {
        height: 180px;
        overflow: hidden;
        z-index: 226;
        position: relative;

        &:after {
            content: "";
            position: absolute;
            width: 100%;
            height: 2.25rem;
            bottom: 0;
            left: 0;
            background: -moz-linear-gradient(top, rgba(241,242,242,0) 0%, rgba(241,242,242,1) 65%, rgba(241,242,242,1) 100%);
            background: -webkit-linear-gradient(top, rgba(241,242,242,0) 0%,rgba(241,242,242,1) 65%,rgba(241,242,242,1) 100%);
            background: linear-gradient(to bottom, rgba(241,242,242,0) 0%,rgba(241,242,242,1) 65%,rgba(241,242,242,1) 100%);
            z-index: 227;
        }
    }

    &__headline {
        @include textMix('Roboto', 30px, 700);
        line-height: 1.2em;
        margin-bottom: 1rem;
        margin-top: 0;

        &,
        & a {
            color: $color-zeus-black;

            @include on-event() {
                color: $color-cardinal-red;
            }
        }

    }

    .article-summary {
        margin-bottom: 1rem;
    }

    .article-preview__tags {
        bottom: 0;
        display: block;
        position: relative;
        width: auto;
        padding-left: 24px;
        margin-left: 384px;
    }

    .article-metadata {
        margin-bottom: 1rem;
    }

    @include breakpoint('lg') {
        &__image {
            float: none;
        }
        .article-preview__tags {
            margin-left: 0;
            padding-left: 0;
        }
    }
    @include breakpoint('medium') {
        height: auto;

        &__inner-wrapper {
            height: 270px;

            &:after {
                height: 3rem;
            }
        }

        .article-metadata {
            position: static;
            width: 100%;
        }
        
        &__image {
            height: auto;
            margin-bottom: 1rem;
        }

        &__image,
        &__headline {
            width: 100%;
            margin-top: 0;
        }
    }
    @include breakpoint('small') {
        height: auto;
        padding: 1rem;

        &__inner-wrapper {
            height: 245px;

            &:after {
                height: 2rem;
            }
        }

        &__headline {
            font-size: 1.2rem;
            margin-bottom: 1rem;
        }

        p {
            font-size: 1rem;
            line-height: 1.75rem;
        }

        &__headline,
        .article-preview__tags,
        p {
            margin-left: 0;
        }
    }
}
