.article-inline-promotional-sidebar {
    overflow: auto;
    float: right;
    width: 50%;
    padding: 2rem;
    margin-left: 2rem;
    margin-bottom: 1.5rem;
    background-color: #f1f2f2;

  &.left {
    float: left;
    margin-left: 0;
    margin-right: 2rem;
  }

  &.right {
    float: right;
    margin-right: 0;
    margin-left: 2rem;
  }

  @include breakpoint('small') {
    float: none;
    width: 100%;
    margin-left: 0;
    padding: 1rem;
    &.left, &.right {
        float: none;
        margin-left: 0;
        margin-right: 0;
    }
  }

  img,
  h3 {
      margin-bottom: 1rem;
      width: 100%;
  }

  h3 {
      color: $color-zeus-black;
  }

  p {
      margin-bottom: 2rem;
  }

  .button--gilded, .button--filled {
      float: right;
  }
}

// TODO: move to theme files
.theme-lloydslist{
    .article-inline-promotional-sidebar {
        border-top: 4px solid #1191D1;
    }
}

.theme-insurance-day{
    .article-inline-promotional-sidebar {
        border-top: 4px solid #DE4361;
    }

}

.theme-commodities{
    .article-inline-promotional-sidebar {
        border-top: 4px solid #9E310D;
    }

}
