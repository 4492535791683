.article-prologue {
    display: flex;
    justify-content: space-between;

    .action-flags {
        order: 2;
    }

    @include breakpoint('small') {
        flex-flow: column;

        .action-flags {
            align-self: flex-end;
        }
    }

}
