header{
	.header__wrapper{
	 	position: relative;
	}
	.header-publication-links{
		@include breakpoint('ipad') {
			font-size: 0.6875rem;
		}
		&.nomyView{
			@include breakpoint('ipad') {
				top: 10px;
				font-size: 0.875rem;
				margin-right: 0px;
			}
		}
	}
	.header-account-access{
		flex: 2 0 auto !important;
		padding: 0 !important;
		
		@media only screen and (min-width: 680px) and (max-width: 800px) { 
			margin-top: 0px;
		}
		// .js-pop-out__myViewregister {
		// 	// margin-top: -30px; //IPMP-
		// 	top: auto !important;
		// }
		.myView-placeholder{
				float: left;
				padding: 0.3em 0;
				position: relative;
				text-align: center;
				.btn-my-view{
					border: 1px solid transparent;
					color: $color-white;
					padding: 12px;
					width: 146px;
					font-family: 'Roboto', Arial Narrow, sans-serif;
					font-style: normal;
					font-weight: 700;
					font-size: 0.75em;
					display: block;
					@media only screen and (min-device-width: 480px) and (max-device-width: 767px) { 
						background: inherit !important; 
						border: inherit !important; 
					}
					@include breakpoint('desktop') {
						text-transform: uppercase;
					}
					@include breakpoint('ipad') {
						width: auto;
						padding: 7px 0;
						background: none;
						border: none;
						font-family: 'Roboto', Arial Narrow, sans-serif;
						font-style: normal;
						font-weight: 400;
						&:hover{
							background: inherit !important; 
							border: inherit !important;
						}
					}
				}
				@include breakpoint('ipad') {
					float: left;
					clear: none;
					width: auto;
					margin: 0 7px 0 0;
					padding: 0;
				}
			.arrow{
				display: none;
				float: left;
				margin-top: 3px;
				margin-right: 3px;
			}
		}
		.header-account-right-access {
			[class*=header-account-access__free-trial]{
				.header-account-access__label {
					&:hover{
						text-decoration: none;
					}
					@include breakpoint('ipad') {
						position: relative;
						top: 0;
						width: auto;
						height: auto;
					}
				}
			}
			.header-account-access__subscribe{
				.header-account-access__label {
					&:hover{
						text-decoration: none;
					}
					@include breakpoint('ipad') {
						position: relative;
						top: 0;
						width: auto;
						height: auto;
					}
				}
			}
		}
		&__my-account {
			@include breakpoint('ipad') {
				position: relative;
			}
		}
		&__label {
			@include breakpoint('ipad') {
				position: absolute;
				display: block;
				overflow: hidden;
				width: 1rem;
				height: 100%;
				left: 0;
				top: 0;
			}
			a {
				@include breakpoint('ipad') {
					display: block;
					position: relative;
					text-indent: -999px;
					width: 100%;
					height: 100%;
					&::before {
						content: '';
						display: block;
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
					}
				}
			}
		}
		.header__icons {
			& + .header-account-access__label a {
				margin-left: -1.75rem;
				padding-left: 1.75rem;
				@include breakpoint('ipad') {
					margin-left: 0;
					padding-left: 0;
				}
			}
		}
	}
	.header__logo{
		@include breakpoint('ipad') {
			margin-right:0 !important;
		}
	}
	.header__icons--search, .header__icons--dismiss-search{
		@include breakpoint('ipad') {
			position: absolute;
			top: 0;
			right: 8px;
			bottom: 0;
			margin: auto!important;
			background-color: white;
		}
		@include breakpoint('small') {
			position: relative;
			right: 0;
			margin: 5px 0 !important;
			background-color: transparent;
		}
	}

	
}

@include breakpoint('ipad') {
	div.header__logo {
		width:auto !important;
	}
}

main{
	.advertising--leaderboard.visible-desktop{
		padding: 13px 0 13px 0;
		@include breakpoint('ipad') {
			display: none;
		}
	}
	.advertising--leaderboard.visible-mobile{
		display: none;
		@include breakpoint('ipad') {
			display: block;
		}
	}
	.action-flags-bar, .banner--highlights{
		margin-top: 0 !important;
	}
}

.header__wrapper{
	@include breakpoint('desktop') {
		&.tabheader_wrapper{
			.header__logo{
				&.tabheader_logo{
					height: 54px;
					img{
						height: 54px;
					}
				}
			}
		}
	}
	@include breakpoint('ipad') {
		.header__logo{
			&.tabheader_logo{
				float: left;
				width: 100%!important;
				max-width: calc(100% - 70px);
				height: 70px;
				margin-bottom: 9px;
				border-bottom: 1px solid #ccc;
			}
		}

		.menu-toggler--mini{
			height: 117px !important;
			&.is-sticky{
				height: 40px !important;
				& ~ .header__logo.tabheader_logo {
					max-width: none;
				}
			}
		}
	}
	@include breakpoint('small') {
		.header__logo{
			&.tabheader_logo{
				max-width: calc(100% - 62px);
				margin-bottom: 10px;
			}
		}
	}
}