/*---
title: Topic - Header
section: Home/Topic Page Components
---
Header for "Latest News" sections of Home and Topic pages.

```example:html
<h1 class="topic-subtopic__header">
    Topic Title
    <a class="topic-subtopic__header-explore-link">Explore this Topic</a>
</h1>
```
*/

/*---
title: Topic - Featured Article
section: Home/Topic Page Components
---

```example:html
<section class="article-preview topic-featured-article">

    <div class="article-metadata">
        <div class="article-preview__bookmarker pop-out__trigger">
            <span class="article-prologue__share-label js-bookmark-label">
                Bookmark
            </span>
            <svg class="article-prologue__share-icon click-utag">
                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="svg-sprite.svg#bookmark"></use>
            </svg>
        </div>
        <ul>
            <li>
                <time class="article-metadata__date">02 Jan 2013</time>
            </li>
            <li>
                <h6>News</h6>
            </li>
        </ul>
    </div>
    <div class="topic-featured-article__inner-wrapper">
        <h3 class="topic-featured-article__headline">
            <a href="#">KaloBios ends first trading day flat after cutting IPO price, issuing more shares </a>
        </h3>

        <span class="article-preview__byline">
            by Mandy Jackson
        </span>

        <div class="article-summary">
            <p>Merck &amp; Co.'s bezlotoxumab for the treatment of recurrent <i>clostridium difficile</i> (C diff) infections is "a big step" for reatment of the debilitating and potentially deadly gut infection, according to Dale Gerding, a professor at Loyola University Chicago Stritch chool of Medicine and a lead investigator for the monoclonal antibody's Phase III clinical trials.</p>
        </div>
    </div>
    <div class="article-preview__tags bar-separated-link-list">
        <a href="#">Cancer</a>
    </div>

</section>
```
*/

.latest-news {

    &__header {
        color: $color-zeus-black;
        position: relative;
        text-align: center;

        &:before {
            background-color: $color-cardinal-red;
            content: "";
            display: block;
            height: 4px;
            position: absolute;
            top: calc(50% - 2px);
            width: 100%;
            z-index: 1;
        }
        span {
            background-color: #fff;
            display: inline-block;
            padding: 24px 36px;
            position: relative;
            z-index: 2;
        }

        @include breakpoint('small') {
            border-bottom: 4px solid $color-cardinal-red;
            margin-bottom: 1.5rem;
            text-align: left;

            span {
                padding: 0 0 0.5rem 0;
            }

            &:before {
                display: none;
            }
        }
    }

    /*---
    title: Topic - Latest News
    section: Home/Topic Page Components
    template: topic-latest-news.md
    ---
    Latest News articles are rendered in sets of 3, 6, 9, or 12 articles, which
    reflow as the browser window expands or contracts.
    */

    &__articles {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 0 -1rem;

        .article-preview--small {
            border: 1px solid $color-light-gray;
            flex: 1 0 auto;
            height: 450px;
            margin: 0 1rem 2.25rem;
            padding: 1rem 1.25rem 3rem;
            width: 300px;
            overflow: hidden;

            &:after {
                content: "";
                position: absolute;
                width: 99%;
                width: calc(100% - 2px);
                height: 7rem;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                // Permalink - use to edit and share this gradient:
                // http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+46,ffffff+100&0+0,1+46,1+100
                background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 45%, rgba(255,255,255,1) 100%); // FF3.6-15
                background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 45%,rgba(255,255,255,1) 100%);
                // Chrome10-25,Safari5.1-6
                background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 45%,rgba(255,255,255,1) 100%); // W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+
                z-index: 227;
            }
        }
    }
}
// do we need to remove the top level selector if this applies everywhere?
.article-left-rail, .longread-article {
    .latest-news__articles {
        flex-wrap: nowrap;
        padding: 0 0.5rem;
		@include breakpoint('medium') {
            flex-wrap: wrap;
        }
        .article-preview--small {
            flex: 1 1 auto;
            height: auto;
            margin: 0 -0.5rem;
            padding: 1rem;
            border: none;
            &::after {
                height: 5rem;
                @include breakpoint('small') {
                    height: 2rem;
                }
            }
            .article-preview__inner-wrapper {
                margin-bottom: 0;
            }
            .article-preview__headline {
                font-size: 18px;
                line-height: 25px;
                @include breakpoint('small') {
                    margin-bottom: 0.5rem;
                }
            }
            .article-summary {
                display: -webkit-box;
                overflow: hidden;
                font-size: 15px;
                line-height: 20px;
                -webkit-line-clamp: 4;
                -ms-line-clamp: 4;
                -webkit-box-orient: vertical;
                -ms-box-orient: vertical;
                & > span, & > p {
                    overflow: hidden;
                    font-size: inherit;
                    line-height: inherit;
                    color: inherit;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}