.ID-Responsive-Table{
			position:relative;
			width: 100%;
			float: left;
			border: 1px solid #d1d3d4;
			    font-family: 'Roboto';
			.text-center {
				text-align: center;
			}
			.table-sort {
				background: url('img/Bitmap.png');
				width: 7px !important;
				height: 5px !important;
				display: block;
				@include breakpoint('small') {
					display: none;
				}
			}
			&#financialresults {
				border: 1px solid #d1d3d4;
				@include textMix('Roboto', 1em, 500);
				.article {
					text-align: right;
					.year_heading, .R16 {
						padding-right: 10px;
					}
				}
			}
			.table_head{
				color: $color-white;
				padding: 20px !important;
				padding-right: 95px !important;
				&:after {
					visibility: hidden;
				    display: block;
				    font-size: 0;
				    content: " ";
				    clear: both;
				    height: 0;
				}
				.RB16{
				   @include textMix('Roboto', 1em, 500);
				   font-style: normal;
				   float: left;
				}
				a[data-toggle] {
					float: left;
					margin: 5px 0 0 15px;
					@media only screen and (min-width: 767px) and (max-width: 1024px) {
						position: absolute;
						top: 15px;
						right: 20px;
					}
				}
				.R16{
				   @include textMix('Roboto', 1.0625em, 400);
				   font-style: normal;
				}
			}
			.graybg {
			    display: none;
			}
			.states_heading{
				position:relative;
				float:left;
				width:20%;
				z-index: 9;
				.year_heading , .RB16 {
					padding-left: 20px;
				}
				@media only screen and (min-width: 767px) and (max-width: 1024px) {
					width: 40%;
				}
				@include breakpoint('small') {
					width: 40%;
				}
				.redbg{
					background-color: $color-red !important;
				}	
				div{
					// margin-bottom:10px;
					padding:10px;
					@include textMix('Roboto', 1em, 500);
				}
				div:first-child{ 
					@include textMix('Roboto', 1em, bold);
				}
				div:nth-child(odd){
					background-color: $color-concrete-table-gray;
				}
			}
			.owl-wrapper {
				width: 80%;
			    float: right;
			    @media only screen and (min-width: 768px) and (max-width: 1024px) {
					width: 60%;
				}
			    @include breakpoint('small') {
			    	width: 60%;
			    }
			}
			.RB16{
				   @include textMix('Roboto', 1em, 500);
				   font-style: normal;
				}
			.article{
				width:100%;
				text-align: center;
				div:nth-child(odd){
				 background-color: #f1f1f1;

				}
				.R16{
				    height: 58px;
					padding-top: 14px;
					// margin-top: 10px;
				}
				
			}
			.year_heading{
				color: white;
				padding-top: 10px;
				padding-bottom: 10px;
				position:relative;
				margin-right: 0;
				padding-right: 40px;
				height: 5em;
				word-wrap: break-word;
				@include textMix('Roboto', 1em, bold);
				@media only screen and (min-width: 767px) and (max-width: 1024px) {
				    padding-left: 10px;
				    height: 7em;
				}
				@include breakpoint('small') {
					padding-left: 5px;
					height: 8em;
				}
				div {
					background: transparent;
				}
			}
			
			.owl-nav {
				float:right;
			}
			.owl-item{
				&.active{
					// width:5% !important;
					// margin-right: 1px !important;
				}
				.year_heading span{ 
					width:100%;
					display: block;
					text-align: right;
					&.sub-item {
						width: 50%;
						float: left;
					}
				}
			} 
			.owl-next.disabled, .owl-prev.disabled {
				pointer-events: none;
			}
			.owl-controls{
				position:absolute;
				top:-51px;
				right: 15px;
			}	
			.owl-theme .owl-controls .owl-nav div{
				background: none;
				padding:0;
				margin:0 3px;
			}
			
}
.footnote {
   float: left;
    width: 100%;
    font-size: 14px;
    margin: 10px;
}
// #annualresults.ID-Responsive-Table .owl-item.active {
// 	width: 5% !important;
// }
#quarterlyresults { 
	border: 1px solid #d1d3d4;
	margin-bottom: 0;
	.table-wrapper .tableHead {
		text-align: right;
		@include textMix('Roboto', 1em, bold);
		&:first-child {
			text-align: left;
			padding-left: 20px;
		}
	}
}
#annualresults {
	.R16 {
		text-align: right;
		padding-right: 20px;
	}
}
#financialresults, #quarterlyresults {
	@media only screen and (min-width: 767px) and (max-width: 1024px) {
		.table_head {
			position: relative;
			a[data-toggle] {
				position: absolute;
				right: 15px;
				top: 15px;
			}
		}
	}
}
#financialresults .tableRow > .tableCell {
	text-align: right;
	@include textMix('Roboto', 1em, 400);
}
#financialresults .tableHead:first-child {
	text-align: left;
	padding-left: 20px;
}
#financialresults .tableHead:last-child, #quarterlyresults .tableHead:last-child {
	padding-right: 20px;
}
#financialresults .tableHead {
	@include textMix('Roboto', 1em, bold);
	text-align: right;
}
#quarterlyresults .tableRow > .tableCell:first-child, #financialresults .tableRow > .tableCell:first-child {
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    @include textMix('Roboto', 1em, 500);
    text-align: left;
    padding-left: 20px;
}
#quarterlyresults .tableRow > .tableCell:last-child, #financialresults .tableRow > .tableCell:last-child {
	padding-right: 20px;
}
#financialresults .owl-wrapper {
	width: 60%;
    float: right;

}
#tankerPureChemPage, #shipVehicle, #onOrderTable, #shipVehiclePage, #shipCoalExport{
	border: none;
	.R16 {
		min-height: 2.5rem;
	}
	.year_heading {
		height: 5em !important;
		div {
			background: transparent;
		}
		.sub-item {
			margin-top: 0.5rem;
		}
	}
	.sub-item {
		width: 50%;
		float: left;
	}
	
}
#tankerPureChemPage, #onOrderTable, #shipVehiclePage, #shipCoalExport{
	margin-bottom: 1rem;	
	.pad-10 {
		padding: 10px !important;
	}
	
	thead {
		tr:first-child th {
			text-align: left !important;
			//border-bottom: 1px solid $color-white;
		}
		/*tr th {
			border-bottom: 1px solid $color-white;
			border-right: 1px solid $color-white;
			&:last-child {
				border-right: none;
			}
		}*/
	}
	.states_heading {
		//border-right: 1px solid $color-white;
		padding-left: 0;
		padding-right: 0;
	}
	.owl-wrapper .states_heading { 
		padding-left: 0;
		padding-right: 0;
	}
}
#cockettBunker, #shipRoro {
	border: none;
	th, td {
		border: none;
	}
	.text-center {
		text-align: left;
		padding: 0 10px;
	}
}
#shipContainerShip {
	border: none;
}
#shipCoalExport{
	@include breakpoint('small') {
		.pad-10 {
			padding: 10px 8px !important;
		}
	}
}

#marketDataDryCargo{
	&.ID-Responsive-Table{
		@include breakpoint('medium') {
			.states_heading{
				width: 50%;
			}
			.owl-wrapper{
				width: 50%;
			}
		}
		.article{
			border-right: 1px solid #eee;
		}
	}
}
#tankerPureChemPage{
	margin-bottom: 0px;
}
#tankerPureChemPage, #onOrderTable{
	.states_heading{
		.year_heading{
			text-align: left;
		}
	}
	.year_heading{
		text-align: center;
		padding: 10px !important;
		.sub-item{
			width: 52%;
			text-align: right;
			&:nth-child(even){
				width: 32%;
			}
		}
		.topTitle{
			min-height: 35px;
		}
	}
	.R16{
		.sub-item{
			width: 32%;
			text-align: right;
			border: 1px solid transparent;
			&:nth-child(even){
				width: 50%;
			}
		}
	}
	@media only screen and (min-width: 600px) and (max-width: 768px){
		.states_heading{
			width: 30%;
		}
		.owl-wrapper{
			width: 70%;
		}
	}
}