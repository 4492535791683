.article-related-content {
    margin-bottom: 2rem;

    &__img {
        width: 100%;
        margin-bottom: 0.5rem;
    }

    &__category ,
    &__date {
        @include textMix('Roboto Condensed', 13px, 300);
        color: $color-dusty-gray;
    }
    &__category {
        display: inline-block;
        letter-spacing: 1px;
        margin-bottom: 0.5rem;
        text-transform: uppercase;
    }

    h5 {
        line-height: 1.5rem;
        margin-bottom: 0.5rem;

        a,
        a:visited {
            color: $color-zeus-black;
        }
        i,em{
            font-weight: 600;
        }
        b,strong{
            font-weight: 500;
        }
    }

    &__date {
        border-bottom: 1px solid $color-light-gray;
        display: inline-block;
        font-weight: 400;
        margin-bottom: 1.5rem;
        padding-bottom: 1.5rem;
        width: 100%;

        &:last-child {
            border: 0;
            margin-bottom: 0;
        }
    }
    
    .related-content-item {
        margin-bottom: 1.5rem;
        &:last-child {
            margin-bottom: 0;
        }
        .article-related-content__img {
            display: block;
            margin-bottom: 0.5rem;
        }
        .article-related-content__category {
            margin-bottom: 0.33rem;
        }
        .article-metadata {
            margin-top: 0;
            margin-bottom: 0.15rem;
            h5 {
                margin-top: 0;
                margin-bottom: 0;
            }
        }
        .article-related-content__date {
            margin-bottom: 0.33rem;
            padding-bottom: 0;
        }
        & > *:last-child {
            margin-bottom: 0;
        }
    }
}

.article-preview__ad {
    TEXT-ALIGN: center;
}
