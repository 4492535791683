.article-promotional-block-title {
    position: relative;
    margin-bottom: .75rem;
    &::before {
        content: '';
        position: absolute;
        bottom: 0.4rem;
        left: 0;
        width: 100%;
        height: 3px;
        background-color: #888;;
    }
    span {
        position: relative;
        padding-right: 0.5rem;
        font-size: 24px;
        font-size: 1.2rem;
        line-height: 1.8rem;
        color: #231f20;
        background-color: white;
    }
}

.article-executive-summary + .article-promotional-block-title {
    margin-top: -3.35rem;
    margin-bottom: -.75rem;
    background-color: white;
}