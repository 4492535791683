.messaging_webUsers{
	width: 100% !important; 
	background: $color-concrete-gray;
	clear: both;
	padding: 1.5625rem 2rem;
	position: relative;
	line-height: 20px;
	margin-bottom:1rem;
	margin-top: 0 !important;
	color: $color-abbey-gray;
	h3{
		font-family:"Roboto",​Arial Narrow,​sans-serif;
		font-style: normal;
		font-weight: 400;
		font-size: 1.2rem;
		margin-bottom:1rem;
		color: $color-abbey-gray;
	}
	.msg-text {
		clear: both;
		p{
			font-family: "PT Serif",​Georgia,​serif;
			font-style: normal;
			font-weight: 400;
			font-size: 0.9375em;
			color: $color-abbey-gray;
		}
		margin-bottom:1rem;
	}
	.button-msg-webUser{
		width: 100%;
		clear: both;
		font-family:"Roboto",​Arial Narrow,​sans-serif;
		font-style: normal;
		font-size: 0.9375em;
		font-weight: 400;
		position: relative;
		float: left;
		.msg_link{
			float: left;
			margin-top: 10px;
		}
		.find_more{
			float: right;
			text-transform: uppercase;
			padding-top: 0.5rem;
			padding-bottom: 0.5rem;
		}
		@include breakpoint('small'){
			.msg_link{
					display: block;
					float: none;
					clear: both;
					margin-bottom: 1rem;
				}
				.find_more{
					float: left;
				}
			
		}
	}
	.msg_dismiss{
		text-transform: uppercase;
		float: right;
		font-family:"Roboto",​Arial Narrow,​sans-serif;
		font-style: normal;
		font-weight: 400;
		font-size: 0.5625em;
		clear: both;
		@include breakpoint('medium') {
			margin: 0 0.5rem;
		}
		//@include breakpoint('large') {
			position: absolute;
			top: 0.625rem;
			right: 0.9375rem;
		//}
		a{
		padding-right: 15px;
		display: inline-block;
		position: relative;
			.dismiss-button__icon {
				content: '';
				position: absolute;
				top: 5px;
				right: 0;				
				width: 9px;
				height: 9px;
			}
		}
	}
}
.messaging_webUsers_white{
	margin: 0 auto;
	position: relative;
	line-height: 20px;
	width: 80%;
	clear: both;	
	padding: 1.25rem 2rem 1.5625rem;
	margin-bottom: 1em;
	.msg_dismiss{
		text-transform: uppercase;
		float: right;
		font-family:"Roboto",​Arial Narrow,​sans-serif;
		font-style: normal;
		font-weight: 400;
		font-size: 0.5625em;
		clear: both;
		position: absolute;
	    top: 0.3125rem;
	    right: 0.625rem;
		a{
		padding-right: 15px;
		display: inline-block;
		position: relative;
			.dismiss-button__icon {
				content: '';
				position: absolute;
				top: 5px;
				right: 0;
				width: 9px;
				height: 9px;
			}
		}
	}
	.msg-text{
		h3{
		  font-family: 'Roboto';
		  font-style: normal;
		  font-weight: 500;
		  font-size: 1.2rem;
		  color: $color-cardinal-red;
		}
		  font-family: 'Roboto';
		  font-style: normal;
		  font-weight: 700;
		  font-size: 1.125em;
		  color: $color-zeus-black;
		  width: 70%;
		  margin: 0 auto;
		  text-align: center;
		  line-height: 30px;
		  /*.red{
			color: $color-cardinal-red;
		  }*/
	}
	@include breakpoint('small'){
		width: 100%;
		float: left;
		.msg-text{
			float: left;
			width: 100%;
			text-align: left;
		}	
	}
}