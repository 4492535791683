.article-table,
.article-body-content table {
    width: 100%;
    border: 1px solid $color-mercury-gray;

    thead th, th, // Table header
    td.header.colored, // Table header
    td.cell p.highlight { // Table sub-header
        @include textMix('Roboto', 16px, 500);
        background-color: $color-ship-gray;
        color: $color-concrete-gray;
        border: 0;
        padding: 0.75rem;
        text-align: left;
    }
	td.header.colored {
		p{
		color: $color-concrete-gray;  
		}
	}
    td.highlight {
        background-color: transparent;
        color: #414042;
        font-weight: 600;
    } 
    // Table sub-header
    td.cell p.highlight {
        background-color: transparent;
        color: $color-ship-gray;
    }

    td.cell p.small,
    td p {
        padding: 0.75rem;
    }
    td.cell.colored {
        background-color: $color-concrete-gray;
    }

    th,
    tr {
        border-bottom: 1px solid $color-mercury-gray;
    }
    th {
        @include textMix('Roboto', 16px, 500);
        background-color: $color-concrete-gray;
        color: $color-zeus-black;
    }
    td {
        font-size: 15px;
    }

    &__rowspan {
        border-right: 1px solid $color-mercury-gray;
    }

    &--mobile-link {
        display: none;
    }

    @include breakpoint('medium') {

        &:not(.article-table--mobile-link) {
            display: none;
        }

        &--mobile-link {
            display: table;

            td {
                @include textMix('Roboto', 14px, 400);

                &:first-child {
                    font-weight: inherit;
                }

                a:after {
                    content: "\276f";
                    display: inline-block;
                    font-size: 10px;
                    line-height: 1rem;
                    vertical-align: middle;
                    margin-left: 0.25rem;
                }
            }
        }

        &__expand-table {
            @include svg-fill($color-dusty-gray);
            float: right;
            height: 20px;
            width: 20px;
            margin-top: 4px;
        }

    }

}

.mobile-table-exit {
    @include textMix('Roboto', 1rem, 400);
    @extend a;
    cursor: pointer;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
}
