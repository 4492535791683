.article-call-to-action {
	@include textMix('Roboto', 1rem, 400);
	font-family: "Roboto", Helvetica, Arial, sans-serif;
	&--sign-in-up,
	&--subscribe {
		background-color: $color-concrete-gray;
		border: 1px solid $color-light-gray;
		display: flex;
		margin-bottom: 0.75rem;
		overflow: auto;
		padding: 0.75rem 0;
		font-family: 'Roboto';
		@include breakpoint('medium-small') {
			display: block;
		}
		&.savealert{
            background-color: none;
            border: none;
            display: inherit;
            margin-bottom: 0px;
            overflow: inherit;
            padding: 0;
        }
	}

	&__column {
		/*@include columnize(2, 4, 1px solid $color-light-gray);*/
		border-right: 1px solid $color-light-gray;
		flex: auto;
		min-width: 50%;
		padding: 0 1rem;

		@include breakpoint('medium-small') {
			border-right: none;
			margin-bottom: 40px;
		}

		&:last-child {
			border-right: none;
			margin-bottom: 0;
			.full-width-button {
				margin-bottom: 0.75rem;
			}
		}

		.button--filled {
			display: inline-block;
		}
	}

	&__title {
		@extend h3;
		color: $color-zeus-black;
		display: block;
		margin-bottom: 0.75rem;
	}
	&__subtitle {
		@extend h5;
		display: block;
		margin-bottom: 1rem;
		color: $color-zeus-black;
	}
	&__caption,
	&__source {
		@include textMix('Roboto', 13px, 400);
		color: $color-dusty-gray;
		margin-bottom: 0 !important;
		line-height: 1.5rem !important;
		display: block; 
		text-transform: uppercase;
		letter-spacing: 1px;
	}

	hr {
		margin: 1rem 0;
	}
}

.article-call-to-action__column {
    .article-call-to-action__subtitle.no-hr hr {
        display: none !important;
    }
}