.package-control-articles__fullContent {
	clear: both;
	margin: 0 0 2rem;
	padding: 1.25rem;
	background: #f1f2f2;
	&.white-bg {
		padding: 0;
		background: #FFFFFF;
		.header-line-control span {
			background: #FFFFFF;
		}
	}
	.package-wrapper {
		padding-bottom: 2rem;
		margin: 0 -0.625rem;
		display: flex;
		@include breakpoint("small") {
			display: block;
		}
	}
	.article-metadata {
		ul {
			display: block;
			li {
				display: inline-block;
				vertical-align: middle;
				margin-right: 0.375rem;
				padding-right: 0.375rem;
				&:nth-child(2) {
					max-width: 85px;
					text-overflow: ellipsis;
				}
				&:nth-last-child(2) {
					border-right: none;
				}
				&:last-child {
					right: 0;
					float: right;
					position: absolute;
					margin-right: 0;
				}
			}
		}
		@include breakpoint("large") {
			li {
				margin-right: 0.25rem;
				padding-right: 0.25rem;
			}
		}
	}
	.header-line-control {
		width: 100%;
		position: relative;
		margin: 0 0 1.25rem 0;
		&:after {
			content: "";
			position: absolute;
			width: 100%;
			height: 4px;
			background: #000;
			top: 50%;
			margin-top: -2px;
			left: 0;
			@include breakpoint("small") {
				display: none;
			}
		}
		span {
			background: #f1f2f2;
			position: relative;
			z-index: 9;
			padding-right: 1.25rem;
		}
	}
}
.package-control-articles__half {
	clear: both;
	margin-bottom: 2rem;
	padding: 1.25rem;
	background: #f1f2f2;
	&.white-bg {
		padding: 0;
		background: #FFFFFF;
		.header-line-control span {
			background: #FFFFFF;
		}
	}
	.article-metadata li:nth-last-child(2) {
		border-right: none;
	}
	.article-metadata li:nth-child(2) {
		max-width: 85px;
		text-overflow: ellipsis;
	}
	.header-line-control {
		width: 100%;
		position: relative;
		margin: 0 0 1.25rem 0;
		&:after {
			content: "";
			position: absolute;
			width: 100%;
			height: 4px;
			background: #000;
			top: 50%;
			margin-top: -2px;
			left: 0;
			@include breakpoint("small") {
				display: none;
			}
		}
		span {
			background: #f1f2f2;
			position: relative;
			z-index: 9;
			padding-right: 1.25rem;
		}
	}
	.package-wrapper .package-control-preview {
		width: 50%;
		float: left;
		@include breakpoint("small") {
			width: 100%;
		}
		&:first-child {
			.article-preview__byline {
				float: none;
			}
		}
		.article-preview__inner-wrapper {
			position: relative;
			.article-metadata li {
				&:last-child {
					right: 0;
					float: right;
					position: absolute;
					padding-right: 0;
					margin-right: 0;
				}
			}
		}
		.package-heading-wrapper {
			margin-top: 0;
			h3.article-preview__headline {
				&:first-child {
					border-top: none;
					padding-top: 0;
					margin-top: 0;
				}
				&:last-child {
					padding-bottom: 0;
				}
				font-family: Roboto;
				font-size: 1.25rem;
				font-weight: bold;
				line-height: 24px;
			}
		}
	}
	.package-control-preview:first-child .article-metadata {
		float: none;
	}
}
.package-control-articles__quarter {
	float: right;
	width: 50.4%;
	margin: 2rem 0 2rem 2rem;
	padding: 1rem 2rem;
	background: #f1f2f2;
	&.white-bg {
		padding: 0;
		background: #FFFFFF;
		.header-line-control span {
			background: #FFFFFF;
		}
	}
	h1.article-preview__headline {
		// margin-bottom: 0.625rem;
		&:hover {
			color: $color-zeus-black;
		}
	}
	.moreText {
		text-align: left;
		padding-bottom: 0;
		margin-bottom: 5px;
	}
	h2 {
		margin-bottom: 1rem;
	}
	h3.article-preview__headline {
		padding: 1rem 0 0 0;
		font-family: Roboto;
		font-size: 1.25rem;
		font-weight: bold;
		line-height: 24px;
		border-top: 1px solid $color-light-gray;
	}
	h3 {
		&:first-child {
			border-top: none;
		}
	}
	.article-sidebar {
		width: 100%;
		float: left;
		padding: 0;
		margin: 0;
		border-bottom: 4px solid;
	}
	@include breakpoint("small") {
		width: 100%;
		float: none;
		margin: 1rem 0;
		padding: 1rem;
	}
	@include breakpoint("medium-small") {
		width: 100%;
		float: none;
		margin: 1rem 0;
		padding: 1rem;
	}
}
.package-control-articles__wrapper {
	padding-bottom: 0.5rem;
}
.package-wrapper {
	&:after {
		margin-bottom: 2rem;
	}
	.package-control-preview {
		position: relative;
		width: 25%;
		margin: 0;
		padding: 0 0.625rem;
		float: left;
		@include breakpoint("small") {
			width: 100%;
			margin-bottom: 2rem;
		}
		@media only screen and (min-width: 767px) and (max-width: 1024px) {
			width: 50%;
		}
		&:first-child {
			width: 50%;
			@include breakpoint("small") {
				width: 100%;
			}
			.article-preview__headline {
				margin-bottom: 1rem;
				@include breakpoint("small") {
					font-size: 1.5rem;
				}
			}
		}
		&:last-child {
			.article-summary p {
				margin-bottom: 0.9375px;
			}
		}
		.article-metadata {
			clear: both;
			margin-top: 0;
			@include breakpoint("small") {
				float: none;
			}
		}
		.article-preview__byline {
			float: left;
			@include breakpoint("small") {
				float: none;
			}
		}
		.article-preview--small {
			margin: 0;
			width: 100%;
		}
		.package-heading-wrapper {
			margin-top: 1.25rem;
			h3.article-preview__headline {
				margin: 0;
				padding: 1rem 0;
				font-family: Roboto;
				font-size: 1.25rem;
				font-weight: bold;
				line-height: 24px;
				border-top: 1px solid $color-light-gray;
			}
		}
		.article-preview__inner-wrapper {
			h3.article-preview__headline {
				font-family: Roboto;
				font-size: 1.25rem;
				font-weight: bold;
				line-height: 24px;
			}

			.article-summary {
				p {
					line-height: 27px;
				}
			}
		}
	}
}
.article-left-rail {
	.article-body-content {
		.article-call-to-action {
			clear: both;
		}
		&:after {
			clear: both;
			content: "";
			display: block;
		}
	}
}
.package-img-wrapper {
	overflow: hidden;
	margin-bottom: 1.25rem;
	img {
		width: 100%;
		height: 100%;
	}
	&.first {
		min-height: 220px;
		@include breakpoint("small") {
			height: auto;
		}
	}
	&.second {
		min-height: 165.56px;
		@include breakpoint("small") {
			height: auto;
		}
	}
	&.third {
		height: 200px;
		@include breakpoint("small") {
			height: auto;
		}
	}
}
.moreText {
	text-align: right;
	padding-bottom: 2rem;
	font-family: Roboto;
	font-size: 1rem;
	font-weight: bold;
	line-height: 19px;
}

.sponsored_cont {
	&.topic-featured-article {
		.article-metadata {
			margin-top: 0rem;
		}
	}
	background: transparentize(#f7941e, 0.9);
	.topic-featured-article__inner-wrapper:after,
	&.article-preview--small:after {
		background: linear-gradient(to bottom, #fef4e8 0%, #fef4e8 65%, #fef4e8 100%);
		background: -webkit-linear-gradient(top, #fef4e8 0%, #fef4e8 65%, #fef4e8 100%);
		background: -moz-linear-gradient(top, #fef4e8 0%, #fef4e8 65%, #fef4e8 100%);
	}
	.sponsored_title {
		text-transform: uppercase;
	}
	&.article-executive-summary {
		padding: 1rem;
		.sponsored_title {
			margin-right: 0.5rem;
			text-transform: capitalize;
			font-size: 1.125rem;
		}
	}
	&.article-preview {
		padding: 1rem;
		margin-bottom: 1rem;
		//height: 320px;
		.sponsored_title {
			font-size: 18px;
			font-style: normal;
			margin-right: 0.5rem;
			text-transform: capitalize;
		}
	}
	&.contentRecomm-article {
		padding: 1rem;
	}
	&.section-article {
		padding: 1rem;
		margin-bottom: 1.5rem !important;
	}
	&.topic-featured-article {
		border-top: none;
	}
	&.article-executive-summary-bottom {
		padding: 2rem;
		margin-bottom: 2rem;
		.sponsored_title {
			font-size: 18px;
			font-style: italic;
			margin-right: 0.5rem;
			text-transform: capitalize;
		}
	}
	ul {
		margin-bottom: 0.75rem;
		li {
			display: inline;
			border-right: 0;
			img {
				max-height: 30px;
			}
		}
	}
}
@media only screen and (min-width: 767px) and (max-width: 768px) {
	.article-preview.article-preview--small.sponsored_cont ul li .sponsored_title {
		margin-right: 0px;
	}
	.article-preview.article-preview--small.sponsored_cont ul li img {
		max-height: 22px;
	}
}
