.menu-toggler {
	@include textMix('Roboto', 12px, 500);

	background-color: $color-ship-gray;
	color: $color-light-gray;
	float: right;
	height: 100%;
	letter-spacing: 1px;
	margin-right: -70px;
	overflow: hidden;
	position: absolute;
	left: 100%;
	text-align: center;
	text-transform: uppercase;
	width: $main-menu-handle-width;

	@include breakpoint('ipad') {
		display: none; // hide main toggler, show mini (see below)
		height: 100px;
		left: auto;
		position: relative;

		// Make mini-toggler sticky when scrolled on tablet/mobile
		&.is-sticky {
			position: fixed;
			z-index: 999;
			top: 0;
			left: 0;
			width: 100%;
			height: 40px;
			margin: 0;
			text-align: left;
			line-height: 40px;

			.menu-toggler__button {
				height: 36px;
				width: 36px;
				float: left;

				span {
					top: 18px;
					left: 11px;
					right: 11px;

					&:before {
						top: 6px;
					}
					&:after {
				  		bottom: 6px;
					}
				}
			}
		}
	}

	/* 	Taken from:
		http://callmenick.com/post/animating-css-only-hamburger-menu-icons */

	&__button {
		appearance: none;
		background-color: $color-ship-gray;
		border-radius: none;
		border: none;
		box-shadow: none;
		cursor: pointer;
		display: block;
		font-size: 0;
		height: 60px;
		margin: 0;
		overflow: hidden;
		padding: 0;
		position: relative;
		text-indent: -9999px;
		width: 70px;

		&:focus { outline: none; }

		span {
			display: block;
		    background: $color-concrete-gray;
		    height: 2px;
		    left: 25px;
		    position: absolute;
		    right: 25px;
		    top: 28px;
		    transition: background 0s 0.3s;

			&:before, /* c.hamburger span */
			&:after {
				background-color: $color-concrete-gray;
				content: "";
				display: block;
				height: 2px;
				left: 0;
				position: absolute;
				transition-delay: 300ms, 0s;
				transition-duration: 300ms, 300ms;
				width: 100%;
			}
			&:before {
				transition-property: top, transform;
				top: 8px;
			}
			&:after {
				transition-property: bottom, transform;
		  		bottom: 8px;
			}
		}

		@include breakpoint('ipad') {
			span {
				top: 34px;
				left: 25px;
				right: 25px;
			}
			&::before {
				top: 6px;
			}
			&::after {
		  		bottom: 6px;
			}
		}

		@include breakpoint('small') {
			width: 62px;

			span {
				top: 34px;
				left: 20px;
				right: 20px;
			}
			&::before {
				top: 6px;
			}
			&::after {
		  		bottom: 6px;
			}
		}
	}

	&--mini {
		display: none;
		float: left;
		flex: 0 0 auto;
		margin-right: 0;
		z-index: 9997; // above header logo

		@include breakpoint('ipad') {
			display: block;
			height: 100%;
		}

		@include breakpoint('small') {
			width: 62px;
		}
	}
}



// TODO re-figure out why this isn't a more precise rule
// .js- class for this and parent?
.menu-toggler.is-active .menu-toggler__button span {
	background: none;

	&:before {
  		top: 0;
  		transform: rotate(45deg);
	}

	&:after {
		bottom: 0;
		transform: rotate(-45deg);
	}

	&:before,
	&:after {
		transition-delay: 0s, 0.3s;
	}

}
