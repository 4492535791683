.search-sort {
    margin-bottom: 1rem;
    @include search-page-structure();

    @include breakpoint('medium') {
        display: block;
        float: left;
        order: 5;
        margin-bottom: 1rem;
    }

    &__sort-by {
        display: inline-block;
        float: right;

        @include breakpoint('small') {
            float: none;
            margin-bottom: 1rem;
        }
    }

    &__title {
        display: inline-block;
        text-transform: uppercase;
        margin-right: 5px;
    }

    &__line-separator {
        @extend .search-result__line-separator;
        margin-right: 0;
    }

    &__control-list {
        display: inline-block;
    }

    &__control-type {
        display: inline-block;

        a {
            @include textMix('Roboto', 15px, 400);
            display: inline-block;
        }

        &.is-selected span,
        &.is-selected a {
            text-decoration: underline;
        }

        .sorting-arrows__arrow--up {
            margin-top: 2px; // Better vertical alignment
        }

        .sorting-arrows__arrow {
            @include svg-fill($color-light-gray);
        }

        &.is-sorted-up .sorting-arrows__arrow--down,
        &.is-sorted-down .sorting-arrows__arrow--up {
            @extend .sorting-arrows__arrow.is-active;
        }

        &.is-sorted-up a,
        &.is-sorted-down a {
            @include on-event(true) {
                text-decoration: none;
            }
        }
    }

}
