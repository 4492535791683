﻿.headercolored_new{
	background: rgb(193, 91, 7);
	color: #FFF;
	font-size: 11.5pt;
}
p.article-paragraph_new{
	font-family:  Arial;
	font-size: 11pt;
	line-height: 20pt;
	padding: 6pt 0;
	text-align: left;
}
p.nomargin_new {
	padding:0;
	margin:0;
}
div.article-interview__question_new {
	font-size: 10pt;
	line-height: 20pt;
	padding:3pt 0 0 3pt;
}
div.article-interview__answer_new{
	font-size: 10pt;
	line-height: 20pt; 
	padding:3pt 0 0 3pt;
}  
.article-pullquote_new{
    font-family: Arial;
    font-size: 12.5pt; 
    text-align: center;
    border: 3px solid #000;
    box-shadow: 4px 4px 4px #000;
    background: #ccc;
}
.article-list--ul_new li{
	list-style-type: disc;
	padding: 0.5pt;
}
.article-list--ol_new li{
	list-style-type: decimal;
	padding: 0.5pt;
}
.quick-facts__header_new{
    font-family: Arial;
    font-size: 12pt;
    color: #000;
    text-align: justify;
    padding: 3px;
    margin: 0;
    line-height: 15px;
    font-weight: normal;
}
ul.quick-facts__list--ul_new, ol.quick-facts__list--ol_new{
	margin: 6pt 0;
	padding: 0 1rem;
}
ul.quick-facts__list--ul_new li{
	font-family:  Arial;
	font-size: 8pt;
	list-style-type: disc;
	padding: 0 6pt;
}

ol.quick-facts__list--ol_new li{
	font-family:  Arial;
	font-size: 8pt;
	list-style-type: decimal;
	padding: 0 6pt;
}
p.quick-facts__source_new{
	font-size: 8pt;
	font-style: italic;
}
p.article-exhibit__header_new{
	font-family:  Arial;
	font-size: 10.5pt;
	text-align: left;
	border-top: 3px solid #000;
	border-bottom: 3px solid #000;
	margin-bottom:8pt; 
}
p.article-exhibit__title_new{
	font-family:  Arial;
	font-size: 10pt;
	color: #000;
	border-top: 3px solid #000;
	border-bottom: 3px solid #000;
	margin-bottom:8pt; 
	font-weight: bold;
}
p.article-exhibit__caption_new{
	font-family:  Arial;
	font-size: 10.5pt;
	text-align: left;
	border-top: 3px solid #000;
	border-bottom: 3px solid #000;
	margin-bottom:8pt; 
}
p.article-exhibit__source_new{
	font-family:  Arial;
	font-size: 10.5pt;
	text-align: left;
	border-top: 3px solid #000;
	border-bottom: 3px solid #000;
	margin-bottom:8pt; 
}
.companyname_new{
	font-family:  Arial;
	font-size: 11pt;
	line-height: 20pt;
	padding: 6pt 0;
	text-align: left;
	font-weight: bold;
	color: rgb(102, 153, 0);
}
.storytitle_new{
	text-align: center;
	font-size: 11pt;
	font-style:bold !important;
}

.quick-facts_new {
  background-color: #CCC;
    border: 2px solid #000;
    font-weight: normal;
	margin:0;
	padding:0;
 }
 .quick-facts_new p{
     padding: 0 6pt;
    font-size: 10pt;
}
p.executivesummary{
	border-top-style: double;
    border-bottom-style: double;
    border-left-style: none;
    border-right-style: none;
    background: #E6E6FF;
}
.tablesubhead{
	color:rgb(193, 91, 7);
	font-size: 11.5pt;
	font-weight:bold;
}
.subheadalternate{
	background:#FFF0F7;
	color:rgb(193, 91, 7);
	font-size: 11.5pt;
	font-weight:bold;
}
.tablestorytextalternate{
	background:#FFF0F7;
	font-size: 11.5pt;
}



/** Clearing Floats on RTE components **/
.GeneralRTEComponent {
	clear: both;
}

//RTETable

.Insight-Table-Style, .rtetable{
    border-width: 0px;
    border-style: none;
    border-collapse: collapse;
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    tr{
        &.rtetableTableHeaderRow{
            font-size: 16px;
            font-weight: 600;
            background-color: #414042;
            color: #f1f2f2 !important;
            border: 0;
            padding: 0.75rem;
            text-align: left;
            padding: 0.5rem;
            p{
                color: #f1f2f2;
            }
        }
        &.rtetableTableOddRow{
            color: #666666;
            background-color: #ffffff;
            font-size: 1rem;   
            vertical-align: top;
            border: 1px solid #f1f2f2;
        }
        &.rtetableTableEvenRow{
            color: #58595b;
            background-color: #F1F2F2;
            font-weight: 400;
            font-size: 1rem;
            padding: 0.5rem; 
            border: 1px solid #f1f2f2;
        }
        &.rtetableTableFooterRow{
            background-color: white;
            color: #58595b !important;
            font-weight: bold;
            font-size: 1rem;  
            font-family: Tahoma;  
            line-height: 11pt;
            border: 1px solid #f1f2f2;
        }
    }
    td{
    	padding: 0 12px;
        &.rtetableTableHeaderFirstCol{
            padding: 0.5rem;  
            color: #ffffff;
            line-height: 14pt;
        }
        &.rtetableTableHeaderLastCol{
            padding: 0.5rem;   
            color: #ffffff;
            line-height: 14pt;
        }
        &.rtetableTableHeaderOddCol{
            padding: 0.5rem; 
            color: #ffffff;
            line-height: 14pt;
        }
        &.rtetableTableHeaderEvenCol 
        {
            padding: 0.5rem; color: #ffffff;
            line-height: 14pt;
        }
        
        &.rtetableTableFirstCol{
            padding: 0.5rem;  
        }
        &.rtetableTableLastCol{
            padding: 0.5rem;;
        }
        &.rtetableTableOddCol{
            padding: 0.5rem;  
        }
        &.rtetableTableEvenCol{
            padding: 0.5rem;;
        }
        
        &.rtetableTableFooterFirstCol{
            padding: 0.5rem;      
            text-align: left;
        }
        &.rtetableTableFooterLastCol{
            padding: 0.5rem;;
            text-align:left;
        }
        &.rtetableTableFooterOddCol{
            padding: 0.5rem;  
            text-align: left;
        }
        &.rtetableTableFooterEvenCol{
            padding: 0.5rem;  
            text-align: left;
        }
    }
}

.article-body-content .Insight-Table-Style {
    th, td {
        p {
            padding: 0;
        }
    }
}