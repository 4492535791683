.article-metadata {
    @include textMix('Roboto', 15px, 400);
    margin-top: 0.5rem;

    ul {
        display: inline-flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-bottom: 0;
    }
    li {
        align-self: center;
        border-right: 1px solid #ccc;
        flex: 0 0 auto;
        margin: 0 0.625rem 0.625rem 0;
        padding-right: 0.625rem;
		position: relative;
		// &:nth-child(2){
		// 	max-width: 75px;
		// 	text-overflow: ellipsis;
		// }
        @include breakpoint('small') {
            max-width: 150px;
        }

        h6 {
            max-width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &:last-child {
            border: 0;
        }

        &:before {
            background: none;
            margin-left: 0;
        }
    }

    &__media-type {
        @include svg-fill($color-dusty-gray);
        height: 16px;
        width: 16px;
        vertical-align: bottom;
        margin-bottom: 2px;
    }
}