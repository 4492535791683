	.data-tool-landing{
		width: 100%;
		h1{
			font-family: "Roboto Condensed", Arial Narrow, sans-serif;
			font-style: normal;
			font-weight: 700;
			margin-bottom: 1.875rem;
		}
		.sub-heading-text{
			  font-family: "PT Serif", Georgia, serif;
			  font-style: italic;
			  font-weight: 400;
			  color: $color-abbey-gray;
			  margin-bottom: 1.25rem;
			  font-size: 1.5em;
		}
		.js-toggle-demo{
			width: 100%;			
			float: left;
			background-color:$color-concrete-gray;
			border-top: 1px solid $color-light-gray;
			 margin-bottom: 0.75rem;
			 &.collapsed .toggle-demo{
				display: none;
			 }
			.show-demo{
				text-transform: uppercase;
				text-align: center;
				font-family: 'Roboto', Arial Narrow, sans-serif;
				font-style: normal; 
				font-weight: 400;
				font-size: 0.75em;
				color: $color-dusty-gray;
				padding-bottom: 5px;
				cursor: pointer;
				padding-top: 5px;
				li a{color: #979797; text-decoration: none;  padding-right: 25px; position: relative; display: inline-block}
				li a:after{content: ''; background: url("/dist/img/sprite-image.png") no-repeat; width: 12px; height: 7px; position: absolute; top: 4px; right: 0;}
				.sd{&:after{background-position: -15px -144px;}}
				.hd{&:after{background-position: -1px -160px;} display: none;}

			}
			.toggle-demo{
				width: 100%;
				display: block;
				padding: 23px 40px 40px 0;
				float: left;
				.demoText{
					font-family: 'Roboto', Arial Narrow, sans-serif;
					font-style: normal;
					font-weight: 400;
					font-size: 1.125em;
					padding-left:40px;
					text-align: left;
					line-height: 30px;
					color: $color-abbey-gray;
					width: 50%;
					float: left;
					@include breakpoint('small') {
						width:100%;
					}
					@include breakpoint('medium-small') {
						width:100%;
					}
				}
				.video-demo{
						padding-left: 40px;
						width: 50%;
						float: left;
						@include breakpoint('small') {
							width:100%;
							.visible-sm{
								display: block;
							}
							.visble-lg{
								display: none;
							}
						}
						
						.visible-lg{
							position: relative;
							.play-icon{
								position: absolute;
								width: 60px;
								height: 60px;
								background: url("/dist/img/sprite-image.png") no-repeat -6px -450px;
								top: 50%;
								left: 50%;
								margin-top: -27px;
								margin-left: -27px;
								cursor: pointer;
								@include breakpoint('medium-small') {
								left: 50%;
								}
								@include breakpoint('small') {
								left: 50%;
								}
							}
							.video-mini-container {
								@include breakpoint('medium-small') {
								padding: 0;
								}
								@include breakpoint('small') {
								padding: 0;
								}
							}
						}
						
					}
					@include breakpoint('medium-small') {
						width:100%;
						.visible-sm{
							display: block;
						}
						.visble-lg{
							display: none;
						}
					}
					.add-width-100{
						width: 100%;
					}
					
				}
				
			}
			
		}
		.article-data-tool-placeholder{
		   width: 100%;
		   float: left;
		   margin-bottom: 0.75em;
		   .data-tool-placeholder{
				 float: left;
				 width: 50%;
				 margin-bottom:0;
				@include breakpoint('medium-small') {
					width:100%;
				}
				@include breakpoint('small') {
					width:100%;
				}	
		   }
		   .articleText{
				float: left;
				width: 50%;
				clear: right;
				padding: 15px;
				line-height: 30px;
				@include breakpoint('medium-small') {
					width:100%;
				}	
				@include breakpoint('small') {
					width:100%;
				}	
		   }
		}
		.data-tool-placeholder{
			width: 100%;
			margin-bottom: 3.125rem;
			h3.data-tool-heading{
				font-family: "Roboto Condensed", Arial Narrow, sans-serif;
				font-style: normal;
				font-weight: 700;
				font-size: 1.2rem;
			}
			.data-tool-graph{
				width: 100%;
				clear: both;
			}
			.articleText {
				padding: 40px 40px 60px;
				@include breakpoint('small') {
					padding: 10px;
				}
			}
		}
		.data-tool-placeholder-right-rail{
			width: 100%;
			margin-bottom:1.75em;
			h3.data-tool-heading{
				font-family: "Roboto Condensed", Arial Narrow, sans-serif;
				font-style: normal;
				font-weight: 700;
				font-size: 1.2rem;
			}
			.data-tool-graph{
				width: 100%;
				clear: both;
			}
			p 
			 {
				margin-top:20px;
				clear: both;
				 a{
					font-family: "Roboto", Arial Narrow, sans-serif;
					font-style: normal;
					font-weight: 400;
				 }
			}
		}
		.articleText{
			width: 100%;
			font-family: "PT Serif", Georgia, serif;
			  font-style: italic;
			  border-top: 1px solid $color-light-gray;
			  font-weight: 400;
			  font-size: 1.125em;
			  color: $color-abbey-gray;
			  background-color: $color-concrete-table-gray;
			  clear: both;
			  text-align: left;
			  padding: 10px;
			  line-height: 30px;
			 p 
			 {
				margin-top:20px;
				 a{
					
					font-family: "Roboto", Arial Narrow, sans-serif;
					font-style: normal;
					font-weight: 400;
				 }
				&:last-child{
					margin-bottom: 3.125rem;
				} 
			}	
		}
		.visible-sm{
			display: none;
		}
		  .modal {
			  .modal-dialog {
				  width: 60%;
				  margin: 0 auto;
			  }
			  .modal-content {
				margin-top:10%;
				@include breakpoint('medium-small') {
					margin-top:30%;
				}	
				@include breakpoint('small') {
					margin-top:30%;
				}	
			  }
			  .modal-header {
				  border-bottom: 0;
				  .close {
					  color: $color-white;
					  opacity: 2;
				  }
			  }
        }
		.embed-responsive .embed-responsive-item,
		.embed-responsive iframe,
		.embed-responsive embed,
		.embed-responsive object {
		  position: absolute;
		  top: 0;
		  left: 0;
		  bottom: 0;
		  height: 100%;
		  width: 100%;
		  border: 0;
		}

		.embed-responsive.embed-responsive-16by9 {
		  padding-bottom: 56.25%;
		}
		
		.embed-responsive.embed-responsive-4by3 {
		  padding-bottom: 75%;
		}
		.video-mini-container .embed-responsive.embed-responsive-16by9 {padding-bottom:0;}
	
		.dataTool-related-content{
			float: left;
			clear: both;
			h1{
				 font-family: 'Roboto Condensed';
				 font-style: normal;
				 font-weight: 700;
				 font-size: 1.875em;
			}
			.section-article{
				width: 29%;
				float: left;
				margin: 0 2em 0 0;
				
				span{
					 font-family: 'Roboto';
					 font-style: normal;
					 font-weight: 500;
					 font-size: 0.75em;
					 color: $color-dusty-gray;
					 letter-spacing: 1px;
					 display: block;
					 
				}
				.article-related-content__date:last-child {
					border-bottom: 1px solid #d1d3d4 !important;
				}	
				.article-related-content__category{
					 text-transform: uppercase;
					 margin-top:10px;
					 margin-bottom:5px;
				}
				h5{	
					font-family: 'Roboto';
					font-style: normal;
					font-weight: 500;
					font-size: 1.125em;
					line-height: 25px;
					margin: 2px 0;
					clear: both;
				}
				@include breakpoint('medium'){
					width: 45%;
				}
				
				@include breakpoint('medium-small'){
					width: 100%;
				}
				@include breakpoint('small'){
					width: 100%;
				}
			}
			
		}
		#chartdiv{
			width:100% !important;
		}
div#feedbacklite.fbl_r{
	top: 65px !important;
}
.contentRecomm-article{
	border-bottom: 1px solid #d1d3d4 !important;
	margin-bottom: 1.5rem;
}
.article-left-rail {
	.contentRecomm-article{
		width: 28%;
    	float: left;
    	margin: 0 2em 0 0;
    	clear: initial;
    	height: 15.625rem;
    	margin-bottom: 2em;
	}
	.article-related-content h5 {
		height: 70px;
		overflow: hidden;
	}
	.ContentRecomm-ReadNext .section-article {
		height: 21.875rem;
		margin-bottom: 2em;
	}
}
.article-right-rail {
	.contentRecomm-article { 
		width: 100%;
		clear: both;
	}
	.ContentRecomm-ReadNext { 
		.section-article{ 
			width: 100%;
			clear: both;
			height: auto;
		}
	}
}
.contentRecommendations {
	clear: both;
	.article-related-content{
		margin-bottom:0;
		h2 {
			margin-bottom: 20px;
		}
	}	
}

.ContentRecomm-ReadNext {
	clear: both;
	h2{
		margin-bottom: 1.5rem;
	}
	.section-article{
		width: 28%;
		float: left;
		margin: 0 2em 0 0;
		height: 13.75rem;
    	border-bottom: 1px solid #d1d3d4 !important;
	}
	@include breakpoint('small') {
		.section-article{
		width: 100%;
		float: left;
		margin: 0 0 0 0;
		}
	}
	@include breakpoint('medium-small') {
		.section-article{
		width: 100%;
		float: left;
		margin: 0 0 0 0;
		}
	}
}
.ContentRecomm-RelatedContent {
	clear: both;
	h2{
		margin-bottom: 1.5rem;
	}
	.section-article{
		width: 32.3%;
		float: left;
		padding: 0 2em 0 0;	
		border-right: #d1d3d4 1px solid;
		&:nth-child(2n+1) {
			margin-left: 2rem;
		}
		&:last-child{
			padding-left:2em !important;
			padding-right: 0 !important;
			border-right: 0 !important;
		}
	}
	@include breakpoint('medium') {
		.section-article{
			width: 31%
		}
	}
	@include breakpoint('small') {
		.section-article{
		width: 100%;
		float: left;
		padding: 0 0 0 0;	
		border-right: 0;
		margin: 0;
			&:nth-child(2n+1) {
			margin-left: 0;
			}
			&:last-child{
			padding-left:0 !important;
			padding-right: 0 !important;
			border-right: 0 !important;
			}
		}
	}
	
	@include breakpoint('medium-small') {
		.section-article{
		width: 100%;
		float: left;
		padding: 0 0 0 0;	
		border-right: 0;
		margin: 0;
			&:nth-child(2n+1) {
			margin-left: 0;
			}
			&:last-child{
			padding-left:0 !important;
			padding-right: 0 !important;
			border-right: 0 !important;
			}
		}
	}
}
.ContentRecomm-RelatedContent-vertical {
	clear: both;
	h2{
		margin-bottom: 1.5rem;
	}
	&.right{
		.section-article{
			.section-article-img {
					width: 100%;
			}
		}
	}
	.section-article{
		display: table;
		.section-article-img{
			position: relative;
			display: table;		
			float: left;
			margin-right: 2em;
			width: 29%;
			img{
				width: 100%;
			}
		}
		.section-article-text{
			display: table;
			div{
				margin-bottom: 20px;
			}
		}
		
		width: 100%;
		float: left;
		margin: 0 2em 0 0;	
		border-bottom: #d1d3d4 1px solid;
		padding-bottom: 5px;
		padding-top:15px;
		&:last-child{
			border-bottom: 0 !important;
		}
	}
}

.suggested-article {
	float: left;
	width: 100%;
}
.suggested-article h2 {
    margin: 25px 0;
}