.rolling-stream {
  margin-top: 2rem;
  padding: 20px 25px 17px;
  width: 100%;
  background-color: $color-concrete-gray;
  &.white-bg {
    background-color: #ffffff;
    .rolling-stream-wrapper:after {
      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 100%
      );
    }
    .scrollbar-container {
      background-color: #ffffff !important;
    }
  }
  .tabs-header {
    display: flex;
    margin: -20px -25px 25px;
  }
  h3 {
    position: relative;
    padding-bottom: 15px;
    margin-bottom: 20px;
    &[data-target-tab] {
      width: 50%;
      padding: 15px 5px;
      margin-bottom: 0;
      text-align: center;
      user-select: none;
      pointer-events: none;
      transition: all 0.2s ease-in-out;
      background-color: $color-concrete-gray;
      &:not(.active) {
        color: white;
        background-color: $color-light-gray;
        cursor: pointer;
        pointer-events: all;
      }
      &::after {
        display: none;
      }
    }
  }
  h5 {
    a {
      color: #231f20;
      &:hover {
        color: #be1e2d;
      }
      i,
      em {
        font-weight: 600;
      }
      b,
      strong {
        font-weight: 500;
      }
    }
  }
  .article-related-content__date {
    color: #be1e2d;
  }
  .tabs-content {
    position: relative;
    .rolling-stream-wrapper {
      &:not(.active) {
        position: absolute;
        top: 0;
        opacity: 0;
        pointer-events: none;
      }
    }
    & > .full-diminished-button {
      &:not(.active) {
        position: absolute;
        bottom: 0;
        opacity: 0;
        pointer-events: none;
      }
    }
  }
  .rolling-stream-wrapper {
    height: 600px;
    overflow: hidden;
    position: relative;
    &.hide-scroll {
      height: auto;
      .content {
        overflow: hidden;
      }
      .scrollbar-container {
        display: none;
      }
    }
    &:after {
      content: "";
      width: calc(100% - 20px);
      background: rgba(241, 242, 242, 0.5);
      background: linear-gradient(
        0deg,
        rgba(241, 242, 242, 1) 0%,
        rgba(241, 242, 242, 0) 100%
      );
      position: absolute;
      height: 85px;
      left: 0;
      bottom: 0;
      z-index: 10;
    }
    .rolling-stream-article {
      padding: 20px 0;
      border-bottom: 1px solid #d1d3d4;
      @media only screen and (min-width: 767px) and (max-width: 1024px) {
        padding-right: 10px;
      }
      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        border-bottom: none;
        z-index: 12;
        position: relative;
      }
      &.article-metadata {
        margin: 0;
      }
    }
    .content {
      height: 100%;
      width: 100%;
      overflow-y: scroll;
      margin: 0;
      padding: 0;
      position: relative;
      padding-right: 5px;
      .article-related-content__date {
        float: right;
        width: auto;
        padding: 0;
        margin-top: 5px;
        font-family: "Roboto", Arial Narrow, sans-serif;
      }
    }
    .scrollbar-container {
      position: absolute;
      right: 0;
      bottom: 0;
      top: 0;
      width: 16px;
      background-color: $color-concrete-gray;
      z-index: 9;
      &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 3px;
        width: 1px;
        height: 100%;
        background: #d1d3d4;
        z-index: 1;
      }
    }
    h5 {
      a {
        font-family: "Roboto", Arial Narrow, sans-serif;
        font-size: 1.125rem;
      }
      @include breakpoint("small") {
        padding-right: 12px;
      }
      span {
        font-size: 0.875rem;
        line-height: 1.18rem;
      }
    }
    .scrollbar {
      position: absolute;
      right: 0;
      height: 30px;
      width: 7px;
      background: #777777;
      z-index: 2;
    }
  }
  .full-diminished-button {
    margin-top: 1rem;
    margin-bottom: 0;
    background-color: #ffffff;
    border: 1px solid #e5e1e6;
  }
}
