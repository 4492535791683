.job_listing{
	h1{
		margin-bottom: 0.75rem;
		@include breakpoint('small') {
			font-size: 2.25rem;
		}
	}
	#page_navigation{
		a{
			margin: 0 10px;
		}
	}
	.sub-heading-text{
		span{
			font-family: "PT Serif", Georgia, serif;
			font-style: italic;
			font-weight: 400;
			color: $color-zeus-black;
			margin-bottom: 0.75rem;
			font-size: 1.5em;
			line-height:30px;
			@include breakpoint('small') {
				font-size: 1rem;
			}
		}
		padding-bottom: 0.75rem;
		border-bottom: 1px solid $color-light-gray;
	}
	.stacked_job_view{
		clear: both;
		width: 100%;
		.job_list_individual{
			@include breakpoint('small'){
				padding: 1em 0;
			}
			width: 100%;
			padding: 1.5em 1em;
			border-bottom: 1px solid $color-light-gray;
			float: left;
			position: relative;
			
			.job_logo{
				@include breakpoint('small'){
					width: 100%;
					display: block;
					position: static;
				}
				position: absolute;
				top: 0;
				left: -220px;
				width: 200px;
				height: 100%;
				img{
					max-width: 100%;
					max-height: 100%;
					position: absolute;
					left: 50%;
					transform: translateX(-50%);
					@include breakpoint('small'){
						position: static;
						transform: translateX(0);
					}
				}
			}
			.job_description{
				@include breakpoint('small'){
					width: 100%;
					display: block;
					float: none;
					margin-left:0;
				}
			    width: calc(100% - 220px);
    			float: right;
    			position: relative;
				.job_heading{
					h3{
					  font-family: 'Roboto Condensed';
					  font-style: normal;
					  font-weight: 700;
					  font-size: 1.2rem;
					  margin-bottom: 0.3em;
					 }
					clear: both;
					
				}
				.job_description_detail{
					p{
					font-family: 'PT Serif';
					font-style: normal;
					font-weight: 400;
					font-size: 1.125em;
					line-height: 30px;
					color: $color-abbey-gray;
					@include breakpoint('small') {
							font-size: 0.875rem;
							line-height: 25px;
						}
					}
				}
			}
		}
	}
	.pagination{
	padding: 1.75em 0;
	clear: both;
		li{
			> a{
				background: url(/dist/img/pagination-arr.gif) no-repeat;
				height: 40px;
				width: 40px;
				overflow: hidden;
				display: block;
				float: left;
				margin-right: 10px;
				text-indent: -9999px;
			}
		}
		span a{
			float: left;
			height: 40px;
			width: 40px;
			margin-right: 10px;
			background: #f1f1f1;
			text-align: center;
			color: #000;
			line-height: 40px;
			&:hover{
				text-decoration: none;
			}
			&.active{
				background: #c6c2c2;
				cursor: text;
			}
		}
		a.prev{ 
			background-position: 0 0;
			&.active{ 
				opacity: 0.1;
				filter: alpha(opacity=10);
				cursor: text;
			}
		}
		a.next{
			background-position: -41px 0;
			&.active{ 
				opacity: 0.1;
				filter: alpha(opacity=10);
				cursor: text;
			}
		}
		
			
		.nums{
			text-align: center;
			li{
				display: inline-block; 
			}
		}
	}
}
.job_detail{
	h1{
		@include breakpoint('small') {
			font-size: 2.25rem;
		}
	}
	.sub-heading-text{
		font-family: "PT Serif", Georgia, serif;
		font-style: italic;
		font-weight: 400;
		font-size: 1.5625em;
		margin: 1em 0;
		@include breakpoint('small') {
			font-size: 1rem;
		}
		p{
			margin-bottom:0;
		}
		span{
			&.date{
				font-size: 1.125rem;
			}
		}
	}
	.job_logo{
		border-top: 1px solid $color-light-gray;
		border-bottom: 1px solid $color-light-gray;
		clear: both;
		padding: 20px 0;
		margin-bottom: 2em;
		width:100%;
		height: 100%;
		img{
			display: block;
			margin-left: auto;
			margin-right: auto;
			max-height: 100%;
			@include breakpoint('small') {
				width: 100%;
			}
		}
	}
	.job_description{
		font-family: "PT Serif", Georgia, serif;
		font-style: normal;
		font-weight: 400;
		line-height: 30px;
		font-size: 1.125em;
		@include breakpoint('small') {
			font-size: 0.875rem;
			line-height: 25px;
		}
		ul {
			padding-left: 20px;
			li {
				list-style: disc;
			}
		}
	}
}