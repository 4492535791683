@mixin chartHeight($h){
	display: block; 
	min-height: $h;
}

.compareChart {
	font-family: "Roboto", Arial Narrow, sans-serif;
	width: 100%;
	margin-bottom: 2rem;
	position: relative;
	.eachChartData {
		margin-bottom: 10px;
		@include breakpoint('small') {
			width: 100%;
			margin-bottom: 0px;
		}
		
		#GWP-CHART, #NWP-CHART, #UR-CHART, #NP-CHART, #SF-CHART, #NWPNR-CHART, #SFNR-CHART, #NPSFR-CHART {
			@include chartHeight(300px);
			@include breakpoint('small') {
				> div{
					float: none !important;
					width: 100%;
				}
			}
		}
		.amChartsCompareList {
			border: 1px solid #ccc;
			height: auto !important; 
			> div{
				/*margin-bottom: 5px;*/
				font-size: 12px;
				height: 22px !important;
			}
		}
		.dataTitle{
			background: #f9f9f9;
			padding: 12px;
			border: 1px solid #d9dadb;
			@include breakpoint('small') {
				border-top: none;
			}
			&.act{
				background: #cacaca;
			}
			.title{
				float: left;
				font-size: 1rem;
				&.act{
					font-weight: 500;
				}
			}
			.chartexpand{
				float: right;
				background: url(/dist/img/sprite-image.png) no-repeat 0px -44px;
				height: 20px;
				width: 28px;
				@include breakpoint('small') {
					background: url(/dist/img/sprite-image.png) no-repeat -13px -615px;
					height: 13px;
					width: 12px;
				}
				display: inline-block;
				cursor: pointer;
				&.active{
					background-position: 0 -77px;
					@include breakpoint('small') {
						background-position: 0px -615px;
					}
				}
			}
			&:after{
				clear: both;
				content: '';
				display: table;
			}
		}
		.chartData{
			padding: 12px; 
			border: 1px solid #d9dadb;
			border-top: none;
			&.hide{
				display: none;
			}
		}
		.graph-container{
			padding: 12px;
			border: 1px solid #d9dadb;
			border-top: none;
			&.hide{
				display: none;
			}
		}
	}
	.showAll{
		margin-bottom: 12px;
		@include breakpoint('small') {
			display: none;
		}
	}
	.showinMob{
		display: none;
	}
	@include breakpoint('small') {
		.showinMob{
			display: block;
			background: #DE4361;
			padding: 10px 0 10px 13px;
			text-transform: uppercase;
			color: #fff;
			font-size: 18px;
			font-weight: normal;
		}
	}
}
@include breakpoint('small') {
	.hideinMob{
		display: none;
	}
}

.article-related-content{
	h2{
		margin-bottom: 1.5rem;
	}
}

.theme-insuranceday{
	a{
		color: #DE4361 !important;
		&:hover, &:focus{
			color: #91211E !important;
		}
	}
}