/*---
title: Article Topics
section: Article Components
---

```example:html
<div class="article-topics">
    <h2 class="article-topics__header">Topics</h2>
    <ul>
        <li class="article-topics__section-header">Therapy Areas</li>
        <li class="article-topics__li">
            <a href="#">Infectious Diseases</a>
            <ul>
                <li><a href="#">Grandchild Topic</a></li>
                <li>
                    <a href="#">Grandchild Topic</a>
                    <ul>
                        <li><a href="#">Great Grandchild Topic</a></li>
                        <li><a href="#">Great Grandchild Topic</a></li>
                        <li><a href="#">Great Grandchild Topic</a></li>
                    </ul>
                </li>
                <li><a href="#">Grandchild Topic</a></li>
            </ul>
        </li>
        <li class="article-topics__li"><a href="#">Orthopedics</a></li>
        <li class="article-topics__li"><a href="#">Respiratory</a></li>
        <li class="article-topics__li"><a href="#">Immune Disorders</a></li>
        <li class="article-topics__section-header">Regions</li>
        <li class="article-topics__li"><a href="#">Belgium</a></li>
    </ul>
</div>
```
*/

.article-topics {
    @include textMix('Roboto', 15px, 400);
    margin-bottom: 2rem;

    &__header {
        @extend h2;
        color: $color-zeus-black;
        margin-bottom: 1.5rem;
    }

    &__li {
        padding: 0;
        margin-left: 18px;

        & + .article-topics__li {
            padding-top: 8px;
        }

        &:before {
            // See shame.scss
        }

        & ul li {
            padding: 8px 0 0;

            &:before {
                color: $color-tree-poppy-orange;
                background: none;
                content: "•";
                margin-left: -18px;
                padding-right: 0.5rem;
            }

            &:first-child {
                padding-top: 0;
            }
        }
    }

    ul ul {
        margin-left: 18px;
        padding-top: 8px;
        & + .article-topics__li {
            padding-top: 8px;
        }
    }

	&__section-desc {
		margin-bottom: 1.25rem;
	}

    &__section-header {
        @extend h6;
        color: $color-zeus-black;
        margin: 10px 0;

        &:before {
            display: none;
            content: "";
            width: 0;
        }
    }
}
