// TODO: change to follow naming convention for search results section

.search-metadata {
  @include textMix('Roboto', 18px, 400);
  display: block;
  color: $color-zeus-black;
  @include search-page-structure();

  @include breakpoint('medium') {
    order: 4;
  }
}
