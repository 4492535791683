/*START STYLE FOR BADGES*/
    .badge {
      @include textMix('Open Sans', 12px, 400);
      position: absolute;
      left: 0;
      top: 0;
      z-index: 300;
      padding: 10px;
      color: white;
  }
  /*END STYLE FOR BADGES*/