.ll-casualty-form {
	font-family: "Roboto", Helvetica, Arial, sans-serif;
	font-weight: 500;
	.form-control {
        float: left;
        margin-bottom: 20px;
        @include breakpoint('small') {
    		width: 100%;
    		float: none;
    	}
    	@media only screen and (min-width: 768px) and (max-width: 1024px) {
    		width: 50%;
    	}
        label {
        	float: left;
        	margin-top: 8px;
        	margin-right: 5px;
        	@include breakpoint('small') {
        		width: 100%;
        		margin-bottom: 1em;
        	}
        	@media only screen and (min-width: 768px) and (max-width: 1024px) {
	    		width: 100%;
	    		margin-bottom: 1em;
	    	}
        }
        &.jumpToSection {
        	float: right;
        }
     } 
     .selectivity-single-select-input {
     	visibility: hidden;
     }
     .selectivity-input {
     	position: relative;
     } 
     .selectivity-dropdown {
        right: 0;
    	top: 100% !important;
    	left: auto !important;
     }
}

.casualtyPan{
	.alert-error{
		width: 100%;
	}
	h2{
		font-size: 46px;
		font-weight: bold; 
	}
	&.table-responsive {
		.table {
			.table_head {
				background-color: #0f5487;
				color: #ffffff;
				th{
					font-size: 1rem;
					font-weight: 500;
					&.pad-20{
						padding: 20px;
					}
				}
			}
			tbody{
				.R16{
					border-bottom: 1px solid #e0e0e0;
					padding: 20px;
				}
				.R16:nth-child(1){
					border-right: 1px solid #e0e0e0;
					font-weight: bold;
				}
			}
		}
		hr{
			margin: 20px 0;
		}
	}
}
#dryCargoIcap{
	.table.descView{
		@include breakpoint('small') {
				&.descView, thead{
					display: block !important;
					tbody, tr{
						&.visible-lg{
							display: block !important;
						}
					}
				}
		}
	}
	.blueBg{
		//background: #1191D1;
	}
}
#tanker-Pure-ChemPage, #shipVehicle-Page, #shipCoalExport-page{ 
	width: 100%;
	@include breakpoint('small') {
		float: left;
		width: 100%;
	}
	.ID-Responsive-Table .year_heading {
		@include breakpoint('small') {
			padding-left: 24px;
		}
	}
}
#market-data-tool, #dry-cargo, #dry-cargo-cap, #dry-cargo-bulk-fixtures, #tanker-fixtures, #ship-Container-ShipFixtures, #ship-Coal-Export, #market-Data-Dry-Cargo,
#cockett-Bunker, #ship-Roro, #ship-Container-Ship, #ship-Vehicle, #tanker-Pure-ChemPage, #shipContainerFixtures, #fisDryBulkContainer, #howeRobinsonContainer, #market-imarex, #shipVehicle-Page, #shipCoalExport-page{
	font-family: "Roboto", Helvetica, Arial, sans-serif;
	
	.alert-error{
		width: 100%;
	}
	.borTop{
		height: 1px;
		border-top: 1px solid #d1d3d4;
		display: block;
	}
	h4{
		margin-bottom: 18px;
	}
	.borderpad {
		padding: 12px 12px 12px 20px;
		border-bottom: 1px solid #e1e1e1;
		border-right: 1px solid #e9e9e9;
		&.rigalign{
			text-align: right;
		}
	}
	.headerPad{
		padding: 8px 12px 8px 20px;
		&.rigalign{
			text-align: right;
		}
	}
	.oddCls{
		background: #f1f1f1;
	} 
	&.table-responsive .table {
		margin-bottom: 2rem;
		&.mobView{
			display: none;
		}
		&.descView{
			display: table;
		}
		@include breakpoint('small') {
			&.mobView{
				display: table;
			}
			&.descView{
				display: none;
			}
		}
		.table_head{
			color: #ffffff;
			th{
				text-transform: capitalize;
				font-size: 16px;
				font-weight: 500; 
			}
			.blueBg{
				//background: #1191d1;
			}
			.visible-lg{
				color: #fff;
				th{
					font-weight: bold;
				}
			}
		} 
		tbody.visible-lg td{
			border-bottom: 1px solid #d1d3d4;
		}
		.R21_BlueColor{
			color: #1191d1;
		}
		.pad-10 {
			padding: 20px 16px; 
			@media only screen and (max-width: 767px) {
	    		padding: 16px 10px;
	    	} 
		}
		.mobleftCol{
			font-size: 18px;
			border-right: 1px solid #e9e9e9;
		}
		.mobrigCol{
			font-size: 16px;
			a{
				color: #1191d1;
				text-decoration: none;
			}
		} 
		.RB18{
			color: #000;
			.top{
				    color: #58595b;
					width: 40px;
					height: 40px;
					display: block;
					border-left: 1px solid #979797;
					text-decoration: none;
					font-size: 17px;
					@include breakpoint('small') {
						display: inline-block;
						float: right;
						text-align: right;
					}
					span{
						background: url(img/sprite-image.png) no-repeat 0 -606px;
						width: 11px;
						height: 8px;
						display: inline-block;
						margin-right: 8px;
					}
				&:hover{
					text-decoration: none;
				}
			}
		}
		.R16{
			font-size: 1rem; 
			a{
				color: #1191d1;
			}
		} 
		@include breakpoint('small') {
			.visible-sm {
				display: block;
			} 
		}
	}

	.lli-brand-container {
		//margin-bottom: 20px;
		h3 {
			background-color: #414042;
			padding: 10px;
			color: #fff;
		}
		.lli-brand-box {
			padding: 10px 0;
			font-weight: 500;
			p{
				font-weight: normal;
			}
			.selectivity-single-select-input {
				visibility: hidden;
			}
		 }
		 p{
			margin-bottom: 35px;
		 }
		 fieldset {
			border: 0;
			margin: 0px;
			padding: 0px;
		 }
		 .form-control {
			margin-bottom: 20px;
			float: left;
			width: 50%;
			@media screen and (max-width: 767px) {
				width: 100%;
				float: inherit;
			}
			&:nth-child(2){
				float: right;
				text-align: right;
				.selectivity-single-result-container{
					text-align: left;
				}
			}
		 }
		 .dataProImg{
			float: right;
			text-align: right;
			@media screen and (min-width: 768px) {
				margin-top: -11px;
			}
			@media screen and (max-width: 767px) {
				float: inherit;
				text-align: left;
				width: 100%;
			}
			span{
				position: relative;
				top: -26px;
				margin-right: 10px;
			}
		  }
		 .selectivity-single-select {
			background: $color-white;
		 }
		 .select-field {
			position: relative;
			display: inline-block;
		 }
		 .selectivity-input {
			position: relative;
		 }
		 #areaCode{
			&.selectivity-input {
				width: 300px;
				@include breakpoint('small') {
					width: 285px;
				}
				@media screen and (max-width: 320px) {
					width: 230px;
				}
			}
		 }
		 #movementType.selectivity-input, #selectDay.selectivity-input {
			width: 210px;
		 }
		 .selectivity-dropdown {
			right: 0;
			top: 100%;
		 } 
		.form-control label{
			/*width: 200px;*/
			margin-right: 20px;
			display: inline-block;
			 @include breakpoint('small') {
				margin-bottom: 0.5em;
			 }
		}
		.submit {
			border: 1px solid transparent;
			color: #ffffff;
			padding: 12px 80px;
			background-color: #1191d1;
			font-family: 'Roboto', Arial Narrow, sans-serif;
			font-style: normal;
			font-size: 1rem;
			float: right;
			@include breakpoint('small') {
				margin-left: 0;
			}
		}  
	}
	h2{
		font-size: 46px;
		font-weight: bold;
		margin-bottom: 40px;
		@include breakpoint('small') {
			font-size: 36px;
			margin-bottom: 20px;
		}
	}
	h3{
		font-size: 1.2rem;
		font-weight: normal;
		font-style: italic;
	}
	hr{
		margin: 20px 0;
		@include breakpoint('small') {
			margin: 25px 0 15px;
			display: block;
		}
	}
	.borTop{
		height: 1px;
		border-top: 1px solid #d1d3d4;
		display: block;
	}
}
 
/*.graybg {
	border-bottom: 1px solid #d1d3d4;
	border-top: 1px solid #d1d3d4;
}*/
.lloyd-related-links {
    margin-top: 1.25rem;
}

.marketdata-links{
	.hideMarketData{
		&.hide{
			display: none;
		}
	}
	.shippingData{
		&.hide{
			display: none;
		}
	}
}
 
#market-data{
	@include textMix('Roboto', 1em, 400);
	margin-bottom: 2rem;
	width: 100%;
	.alert-error{
		width: 100%;
	}
	.owl-wrapper{
		width: 80%;
		float: left;
		@media only screen and (min-width: 600px) and (max-width: 1024px) {
			width: 60%;
			border-right: 1px solid #e9e9e9;
		}
		@include breakpoint('small') {
			width: 50%;
		} 
		@media only screen and (max-width: 680px) {
			width: 45%;
		}
	}
	.owl-dotsNav{
		height: 0px;
		@media only screen and (max-width: 1024px) { 
			background: #0f5487; height: 30px;
		}
	}
	.owl-theme .owl-nav{display: none !important;}
	.showinMob{
		display: none;
		@media screen and (max-width: 1024px) {
			display: block;
		}
	}
	.hideinMob{
		display: none;
		@media screen and (min-width: 1025px) {
			display: block;
		}
	}
	.carousel-div{
		position:relative;
		@include breakpoint('medium-small') {
			border-right: 1px solid #e9e9e9;
		}
		@include breakpoint('medium-small') {
			width: 100%;
		}
		.states_heading{
			position:relative;
			float:left;
			width:20%;
			z-index: 9;
			@media only screen and (min-width: 767px) and (max-width: 1024px) {
				width: 40%;
			}
			@include breakpoint('small') {
				width: 50%;
			}
			@media only screen and (max-width: 320px) {
				width: 62%;
			}
			@media only screen and (min-width: 481px) and (max-width: 680px) {
				width: 40%;
			}
			@media only screen and (max-width: 680px) {
				width: 55%;
			}
			.title{
				background-color: #1191d1;
				color: #ffffff;
				padding: 8px 5px 8px 12px;
				@include textMix('Roboto', 1em, bold); 
			}
			.R16{
				padding: 12px 10px;
				border-bottom: 1px solid #e1e1e1;
				border-right: 1px solid #e9e9e9;
			}
			.leftbord{
				border-left: 1px solid #e9e9e9;
			} 
		}
	} 
	h2{
		font-size: 46px;
		font-weight: bold;
		margin-bottom: 40px;
		@include breakpoint('small') {
			font-size: 36px;
			margin-bottom: 20px;
		}
	}
	.oddCls{
		background: #f1f1f1;
	} 
	.marketDataTable{
		width: 100%;
		border: inherit;
		border-left: 1px solid #d1d3d4;
		@media only screen and (max-width: 1024px) {
			border-left: none;
		}
		.owl-dots{
			background: #0f5487;
			margin-top: -29px;
			height: 30px;
			text-align: right;
			right: 0px;
			padding-right: 15px;
			.owl-dot{
				span{
					margin: 10px 7px;
					background: #1191d1;
				}
				&.active span{
					background: #fff;
				}
			}
		}
		.R16{
			.pad{
				padding: 12px 16px;
				white-space: nowrap; 
				@media only screen and (min-width: 376px) and (max-width: 667px) {
					padding: 0px;
					white-space: normal;
				} 
			}
			@media only screen and (max-width: 1024px) {
				padding: 12px 0px;
			}
			border-bottom: 1px solid #e1e1e1;
			border-right: 1px solid #e9e9e9;  
		}
		tr{
			.R16:first-child{
				.pad{
					white-space: normal;
					padding: 12px 12px 12px 20px; 
					@media only screen and (min-width: 769px) and (max-width: 1024px) {
						padding: 12px 6px;
					}
				} 
				@media only screen and (min-width: 769px) and (max-width: 1024px) {
					padding: 0px 6px;
					font-size: 1rem;
				}
			}
		}
		.owl-controls{
			display: none;
		}
		@media only screen and (max-width: 1024px) {
			.owl-controls{
				display: block;
			}
		}
		.pad-20{
			padding: 10px 20px;
			@media only screen and (max-width: 1024px) {
	    		padding: 10px 5px;
	    	}
			@media only screen and (max-width: 768px) {
	    		padding: 10px 2px;
	    	}
			@media only screen and (max-width: 767px) {
	    		padding: 10px 10px;
	    	}
		}
		.title{
			background-color: #1191d1;
			color: #ffffff;
			padding: 8px 12px 8px 20px;
			text-align: left;
			@include textMix('Roboto', 1em, bold);
		} 
		span.numData{
			width: 40px;
			text-align: right;
			display: inline-block;
			@include breakpoint('small') {
				width: 50px; 
				margin-right: 6px;
			}
			@media only screen and (min-width: 768px) and (max-width: 1024px) {
				width: 40%; 
				margin-right: 10%
			}
		}
		span.rise {
			color: #56c405;
			width: 50px;
			text-align: right;
			display: inline-block;
			@include breakpoint('small') {
				width: 40px; 
				margin-right: 16px;
			} 
			@media only screen and (min-width: 768px) and (max-width: 1024px) {
				width: 40%; 
				margin-right: 10%
			}
		}
		span.fall {
			color: #f40600;
			width: 50px;
			text-align: right;
			display: inline-block; 
			@include breakpoint('small') {
				width: 40px; 
				margin-right: 16px;
			}
			@media only screen and (min-width: 768px) and (max-width: 1024px) { 
				width: 40%; 
				margin-right: 10%
			}
		}
	}
}
.article-preview{clear: both;}
.theme-lloydslist{
	.article-related-content{
		a{
			color: #1191D1 !important;
			&:hover{
				color: #002d4f !important;
			}
		}
	}
}
#shipCoalExport {
	&.ID-Responsive-Table{
		border: none;
	}
}

#latest-value{
	.rise{
		padding-left: 5px;
	}
	.fall{
		padding-left: 5px;
	}
}
#market-Data-Dry-Cargo {
	width: 100%; 
	#marketDataDryCargo {
		border: none;
	} 
	.title{
		background-color: #1191d1;
		color: #ffffff;
		padding: 8px 12px;
		@include textMix('Roboto', 1em, bold); 
	}
	.headerPad{
		font-weight: bold !important;
	}
	@media only screen and (max-width: 1024px) {
		.R16{
			border-right: 1px solid #e9e9e9;
		}
	}
}

#howeRobinsonContainer{
	width:100%;
	.states_heading{
		position:relative;
		float:left;
		width:40%;
		z-index: 9;
		@media only screen and (max-width: 600px) {
			width:48%;
		}
	}
	.carousel-div{
		position:relative;
		width: 100%;
	}
	.owl-wrapper{
		width: 60%;
		float: left;
		@media only screen and (max-width: 600px) {
			width:52%;
		}
	}
	.title{
		background-color: #1191d1;
		color: #ffffff;
		padding: 8px 10px 8px 12px;
		@include textMix('Roboto', 1em, bold);
		@media only screen and (min-width: 768px) and (max-width: 1024px) {
			padding: 8px 10px 8px 0px;
		}
		@media only screen and (max-width: 680px) {
			height: 55px;
		}
		@media only screen and (min-width:680px) and (max-width: 1024px) {
			font-size: 14px;
		}
	}
	.R16{
		padding: 12px 10px;
		border-bottom: 1px solid #e1e1e1;
		border-right: 1px solid #e9e9e9; 
	}
	.titleHead{
		width: 100%;
	}
	.leftbord{
		border-left: 1px solid #e9e9e9;
	}
	.owl-dotsNav{
		background: #0f5487; height: 30px;
	}
	.owl-dots{
		background: #0f5487;
		margin-top: -29px;
		height: 30px;
		text-align: right; 
		padding-right: 15px; 
		position: absolute;
		top: -1px;
		width: 100%;
		 
		.owl-dot{
			span{
				margin: 10px 7px;
				background: #1191d1;
			}
			&.active span{
				background: #fff;
			}
		}
	}
	.owl-nav{
		display: none;
	}
}

#fisDryBulkContainer{
	width: 100%;
	.tableTitle{
		background-color: #1191d1;
		color: #fff;
	}
	.tableheader{
		background-color: #f1f1f1;
	}
	.lli-brand-container .form-control{
		width: 100%;
	}
	.boedTop{
		border: 1px solid #e9e9e9;
	}
	.showinMob{
		display: none;
	}
	 
	@media only screen and (max-width: 480px) {
		.showinMob{
			display: block;
		}
		.showinDesk{
			display: none;
		}
	}
}
 #market-imarex {
	 .lli-brand-container .form-control{
		width: 100%;
	}
 }
 
 .rowbordinMob{
	border: 1px solid #e9e9e9; 
}

.selectivity-input{
	@media only screen and (min-width: 600px) and (max-width: 768px) {
		width: 230px;
	}
}

#tanker-fixtures{
	.table_head
	{
		.pad-full-10{
			@media only screen and (max-width: 767px) {
				padding: 10px;
			}
		}
	} 
	&.table-responsive .table{
		margin-bottom: 1rem;
		@media only screen and (min-width: 1025px) {
			tr .pad-10{
				padding: 12px 0 12px 16px;
				&:nth-child(8){
					padding-right: 16px;
				}
			}
		}
		&.descView{
			@media only screen and (min-width: 768px) and (max-width: 1020px) {
				width: 475px;
				overflow-x: auto;
				display: block !important;
			}
			@media only screen and (min-width: 1024px) and (max-width: 1025px) {
				width: 570px;
				overflow-x: auto;
				display: block !important;
			}
		}
		@media only screen and (max-width: 767px) { 
			&.mobView{
				display: table;
			}
			&.descView{
				display: none;
			} 
			.visible-sm {
				display: block;
			}
		}
		
		&.mobView{
			.mobleftCol{
				width: 40%;
			}
			.mobrigCol{
				width: 60%;
			}
		}
	}
}

#tanker-Pure-ChemPage{
	&.table-responsive{
		.table{
			margin-bottom: 1rem !important;
		}
	}
}

.article-left-rail{width: 100%;}