//css for wards
.widget-container {
    width: 1200px;
    position: relative;
    margin: 25px auto;
}

div#child {
    max-height: 440px;
    overflow-y: auto;
}


.search-container {
    margin: 20px 0px;
    width: 100%;
    text-align: center;
    display: inline-block;
}

.search-container input {
    width: 200px;
    padding: 10px;
    border: 1px solid transparent;
    background: #00719c;
    color: #fff;
    font-family: sans-serif;
}

.search-container input:hover {
    background: #00ac9d;
    border: 1px solid transparent;
}

@media screen and (max-width: 767px) {
    .widget-container {
        width: 100%;
    }

    .widget-container .unit {
        width: 100%;
        float: none;
        display: block;
        margin-bottom: 20px;
        margin-left: 0;
    }
}

@media screen and (min-width: 768px) {
    .widget-container {
        display: flex;
        flex-direction: row;
    }
}

.widget-container {
    width: 100%;
    float: left;
}


/*Search Criteria styles*/
.facet-grid {
    &.search__left-sidebar {
        min-width: 100%;
        max-width: 100%;
        margin-right: 15px;
        float: left;
        border: none;
    }

    .facets__content {
        max-width: 900px;
        margin: 0 auto;
    }

    .facets__section {
        font-size: 15px;
        line-height: 23px;
        width: 33.333%;
        width: calc(100% / 3);
        float: left;
        border: 1px solid #d1d3d4;
        padding: 20px 0;
        height: 350px;
        border-right: 0px solid #d1d3d4;

        &:not(:first-child) {
            padding-top: 20px;
            border-top: 1px solid #d1d3d4;
        }

        &:last-child {
            border-right: 1px solid #d1d3d4;
        }
    }

    .facets__section-list {
        padding-bottom: .5rem;
        border-top: 1px solid #ccc;
        padding: 0px 0px 0px 0px;

        &--sub {
            padding-left: 0rem;
            padding-bottom: 0px;

            li {
                width: 100%;
                padding: 10px 10px 3px 2rem;
                border-top: 1px solid #ccc;
                background: #fff;
                &.active {
                    background: #ededed;
                }
            }
        }
    }

    .facets__section-item {
        padding-bottom: .5rem;
        border-top: 1px solid #ccc;
        padding: 0px 0px 0px 0px;

        li label {
            padding-left: 10px;
            display: inline-block;
            height: auto;
            width: 100%;
            cursor:pointer;
        }
    }

    .facets__legend {
        width: 100%;
        padding-bottom: 15px;
        position: relative;
        padding-left: 10px;
        padding-right: 10px;
    }

    .facets__section-list {
        li label {
            padding-left: 10px;
            display: inline-block;
            height: auto;
            width: 100%;
            cursor:pointer;
        }

        & > li {
            padding-top: 8px;
            padding-bottom: 0px;
            &.active-grid {
                background: #ededed;
            }
        }
    }

    label.active .facets__button--toggle-subcat svg {
        fill: #58595b;
        width: 18px;
    }

    .facets__repeat-wrapper-container {
        height: 287px;
        overflow: auto;
    }
}

.button-container {
    margin: 20px 0;
    width: 100%;
    text-align: center;
    display: inline-block;
      
    @include breakpoint('medium') {
        &.button-datacenter input{
        display: none;
        }
   }
   
}

.button-container input {
    width: 200px;
    padding: 10px;
    border: 1px solid transparent;
    background: #00719c;
    color: #fff;
    font-family: sans-serif;
  
}

.button-container input:hover {
    background: #00ac9d;
    border: 1px solid transparent;
}

@media (max-width: 800px) {
    .button-container {
        display: none;
    }
}

