.article-body-content {
  @include textMix('PTSerif', 16px, 400);
  margin-bottom: 1.5rem;

  > h1 {
    @extend h1;
    margin-bottom: 0.75rem;
    color: $color-zeus-black;
  }

  > h3 {
    @include textMix('PT Serif', 1.2rem, 400);
    color: $color-zeus-black;
    margin-bottom: 2rem;
    font-style: italic;
	ul{
	   list-style: disc;
	   margin-left: 22px;
	   li{
			list-style: disc;
	   }
	}
	ol{
		margin-left: 22px;
		list-style: decimal;
		li{
			list-style: decimal;
	    } 
	}
  }
  
  

  h2 {
    font-size: 26px;
  }

  img {
    display: block;
    max-width: 100%;
  }

  .plugin-hide {
    display: none;
  }

  > ul {
    @extend .carrot-list;
    margin-bottom: 1rem;
  }
  
  .article-exhibit {
    img {
      display: block;
      width: auto;
      max-width: 100%;
      height: auto;
      margin: auto;
      margin: 0 auto .5rem;
    }

    &--pull-right,
    &--pull-left {
      line-height: normal;
      color: $color-dusty-gray;
    }

    &--pull-right {
      margin: 0 0 0 2rem;
    }

    &--pull-left {
      margin: 0 2rem 0 0;
    }
  }

  .fade-required {
    position: relative;
    margin-bottom: 1rem;
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      // p line number x line-height
      height: 6.4rem;
      max-height: 100%;
      background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
    }
  }

  @include breakpoint('medium') {
    font-size: 1rem;

    p { line-height: 1.75rem; }

    h1,
    h3 {
      margin-bottom: 1rem;
    }

    h1 { font-size: 2.25rem; }

    h3 { font-size: 1.2rem; }
  }
}
