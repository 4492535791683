@mixin line-clamp($count) {
    display: -webkit-box;
    -webkit-line-clamp: $count;
    -webkit-box-orient: vertical;  
    overflow: hidden;
    height: 1.6875em * $count;
}
@mixin removefloat() {
    float: none;
    clear: both;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
}
@mixin setCleanRow($colCount) {
    clear: none;
    &:nth-child(#{$colCount}n) {
        clear: left;
        @include breakpoint('medium') {
            clear: none;
        }
    }
}
@mixin setCleanRightColumn() {
    float: right;
    clear: right;
    @include breakpoint('medium') {
        float: none;
    }
}
.package-control-articles-v2 {
    @include removefloat();
    margin-bottom: 2rem;
    &.greybg {
        background: #f1f2f2;
        padding: 1.25rem;
        .pcav2-wrapper > h1.header-line-control span {
            background: #f1f2f2;
        }
    }
    // overrides for col/story
    &.col2 {
        // col2 specific css per story
        &.story5 {
            .pcav2-article {
                @include setCleanRow(2);
            }
        }
        &.story6 {
            .pcav2-article {
                @include setCleanRow(2);
            }
        }
        &.story7 {
            .pcav2-article {
                @include setCleanRow(2);
                &.secondary {
                    .article-preview {
                        &__inner-wrapper {
                            .article-summary {
                                @include line-clamp(2);
                            }
                        }
                    }
                }
            }
        }
        // shared col2 styling regardless of story
        .pcav2-article {
            float: left;
            width: calc(50% - 0.75rem);
            margin-right: 0.75rem;
            @include breakpoint('medium') {
                @include removefloat();
            }
            &:nth-child(2n+1) {
                margin-right: 0;
                margin-left: 0.75rem;
                @include breakpoint('medium') {
                    margin-left: 0;
                }
            }
            &.primary, &.tertiary:last-child:nth-child(even) {
                @include removefloat();
            }
        }
    }
    &.col3 {
        // col3 specific css per story
        &.story1 {
            .pcav2-article {
                &.primary {
                    @include removefloat();
                }
            }
        }
        &.story2 {
            .pcav2-article {
                &.secondary {
                    @include setCleanRightColumn();
                }
            }
        }
        &.story3 {
            .pcav2-article {
                &.tertiary {
                    @include setCleanRightColumn();
                }
            }
        }
        &.story4 {
            .pcav2-article {
                &.tertiary {
                    @include setCleanRightColumn();
                }
            }
        }
        &.story5 {
            .pcav2-article {
                &.secondary {
                    @include setCleanRightColumn();
                }
                &.tertiary {
                    &:nth-child(3) {
                        clear: left;
                        margin-left: 0;
                    }
                }
            }
        }
        &.story6, &.story7 {
            .pcav2-article {
                &.secondary {
                    &:nth-child(2) {
                        @include setCleanRightColumn();
                    }
                    &:nth-child(3) {
                        clear: left;
                        margin-left: 0;
                    }
                }
                &.tertiary {
                    @include setCleanRightColumn();
                }
            }
        }
        // shared col3 styling regardless of story
        .pcav2-article {
            float: left;
            @include breakpoint('medium') {
                float: none;
                clear: both;
            }
            &.primary {
                width: calc(66.666% - 0.5rem);
                @include breakpoint('medium') {
                    width: 100%;
                }
            }
            &.secondary {
                width: calc(33.333% - 1rem);
                margin-left: 1.5rem;
                @include breakpoint('medium') {
                    width: 100%;
                    margin-left: 0;
                }
            }
            &.tertiary {
                width: calc(33.333% - 1rem);
                margin-left: 1.5rem;
                @include breakpoint('medium') {
                    width: 100%;
                    margin-left: 0;
                }
            }
        }
    }
    // shared styling regardless of col/story
    .pcav2-wrapper {
        & > h1 {
            margin: 0 0 1.25rem;
            &.header-line-control {
                position: relative;
                &:after {
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: .595em;
                    width: 100%;
                    height: 4px;
                    margin-bottom: -2px;
                    background: #000;
                    @include breakpoint('small') {
                        display: none;
                    }
                }
                span {
                    background: #fff;
                    position: relative;
                    z-index: 9;
                    padding-right: 1.25rem;
                }
            }
        }
        & > .pcav2-subtitle {
            margin-top: -1.25rem;
            margin-bottom: 1.25rem;
            font-style: italic;
        }
        .pcav2-article {
            margin-bottom: 2rem;
            &.primary {
                .package-img-wrapper {
                    float: right;
                    position: relative;
                    width: calc(66.666% - 1rem);
                    margin-left: 1rem;
                    @include breakpoint('medium') {
                        @include removefloat();
                    }
                }
            }
            &.secondary {
                .article-preview {
                    &__inner-wrapper {
                        .article-summary {
                            @include line-clamp(4);
                        }
                    }
                }
            }
            &.tertiary {
                .package-img-wrapper {
                    display: none;
                }
                .article-preview {
                    &__inner-wrapper {
                        margin-bottom: 0;
                        .article-metadata {
                            display: none;
                        }
                        .article-summary {
                            @include line-clamp(2);
                        }
                    }
                    &__byline, &__tags {
                        display: none;
                    }
                }
            }
            // shared styling regardless of article type
            .package-img-wrapper {
                margin-bottom: 0.5rem;
            }
            .article-preview {
                &__inner-wrapper {
                    display: block;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    .article-metadata {
                        margin-top: 0;
                        &:last-child {
                            margin-bottom: 0;
                        }
                        ul {
                            display: block;
                            &::after {
                                content: " ";
                                display: table;
                            }
                        }
                        li {
                            float: left;
                            &:last-child {
                                margin-right: 0;
                                padding-right: 0;
                            }
                        }
                    }
                    .article-summary {
                        clear: left;
                        p {
                            line-height: 27px;
                        }
                    }
                }
                &__headline {
                    margin-bottom: 1rem;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
                &__byline {
                    margin-bottom: 1rem;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
        .moreText {
            padding-bottom: 1rem;
        }
    }
    .pcav2-article {
        &.sponsored_cont {
            padding: 10px;
            overflow: hidden;
        }
    }
}