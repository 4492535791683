.media-exhibit {

}

.exhibit-number,
.exhibit-bottomcaption,
.exhibit-source {
	@include textMix('Roboto', 13px, 400);
    color: $color-dusty-gray;
    margin-bottom: 0 !important;
    line-height: 1.5rem !important;
    display: block;
    text-transform: uppercase;
    letter-spacing: 1px;
}

 .exhibit-source {
    float: right;
	font-style: italic;
	text-align: right;
}
.exhibit-title {
	@extend h4;
	margin-bottom: 1rem !important;
    line-height: 1.5rem !important;
}
