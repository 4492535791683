/*---
title: Bar-separated Link List
section: UI Elements
---
Styling for a horizontal list of links, separated by a thin bar. This design pattern
is found on most pages of the site, usually for displaying a list of tags.

```example:html
<div class="bar-separated-link-list">
    <a href="#">Person</a>
    <a href="#">Place</a>
    <a href="#">Thing</a>
</div>
```
*/

.bar-separated-link-list {
    display: inline-block;

	&--wrap {
    	align-items: flex-start;
		flex-wrap: wrap;
		line-height: 20px;

		.bar-separated-link-list__label {
			line-height: 18px;
		}

		a {
			margin-bottom: 6px;
		}
	}

	&__label {
		line-height: 18px;
		padding-right: 0.75rem;
		white-space: nowrap;
	}

    a {
        @include textMix('Roboto', 14px, 400);
        border-right: 1px solid $color-light-gray;
        display: inline-block;
        padding: 0 0.75rem 0 0;
        margin-right: 0.75rem;
        flex: 0 0 auto;

        &:last-child {
            border: 0;
            padding-right: 0;
            @include breakpoint('small') {
                padding-right: 0.75rem;
            }
        }
    }
}
