.manually-curated-content {
    // Makes this element the source for absolutely positioning children elms
    position: relative;
    width: 100%;
    clear: both;
    // overflow prop added to resolve ISW-4933 (TopicJumpTo link is taking to wrong component)
    overflow: auto;

    &__main-well {
        float: left;
        width: calc(66.666% - 1rem);
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .article-preview--featured p,
        .article-preview--small p {
            font-size: 1.125rem;
            line-height: 1.875rem;
        }
        .article-preview--small {
            border-top: 2px solid $color-light-gray;
            padding-top: 6px;
        }
    }
    &__right-rail {
        float: right;
        width: calc(33.333% - 1rem);

        .article-preview--featured p,
        .article-preview--small p {
            font-size: 0.9375rem;
            line-height: 1.5rem;
        }
    }

    @include breakpoint('small') {
        &__main-well,
        &__main-well .article-preview,
        &__main-well .article-preview--small,
        &__right-rail {
            width: 100%;
        }
    }

    .article-pullquote {
        margin: 0 0 2rem;
    }
}
