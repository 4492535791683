.podcast-widget {
    background: #f1f2f2;
    padding: 20px 15px;
    .topic-subtopic__title {
        @include textMix('Roboto', 20px, 700);
        padding-bottom: 10px;
        padding-right: 0;
        border-bottom: 4px solid black;
    }
    .podcast-body {
        padding-top: 30px;
        iframe {
            vertical-align: bottom;
        }
        .iframe-component {
            margin-bottom: 0;
        }
        .iframe-component__mobile {
            margin-bottom: 1rem;
        }
    }
    .full-diminished-button {
        margin-bottom: 0;
        font-size: 1rem;
        background-color: #ffffff;
        border: 1px solid #e5e1e6;
    }
}