/*---
title: Quick Facts Box
section: Article Components
---

```example:html
<div class="quick-facts">
  <p class="quick-facts__header">Quick Facts Title</p>
  <p>Quick Facts Text</p>
  <ul class="quick-facts__list--ul">
      <li>Quick text bullets</li>
  </ul>
  <hr>
  <ol class="quick-facts__list--ol">
      <li>Quick facts numbered</li>
  </ol>
  <p class="quick-facts__source">Quick Facts Source</p>
</div>
```
*/

.quick-facts {
    background-color: $color-antique-white;
    float: right;
    margin: 0 0 0 1rem;
    overflow: auto;
    padding: 1rem 1rem 0;
    width: 50%;

    @include breakpoint('small') {
        width: calc(100% - 1rem);
        float: none;
        margin: 0 1rem 1rem;
    }

    p,
    ul,
    ol {
        color: $color-zeus-black;
        margin-bottom: 1rem;
    }

    &__header {
        @include textMix('Roboto Condensed', 1.625rem, 700);
        color: $color-zeus-black;
        margin: 0 0 0.5rem;
    }

    &__list {
        color: $color-zeus-black;

        &--ul {
	        @extend .default-style-list;
	        @extend .carrot-list;
        }

        &--ol {
	        @extend .default-style-list;

			li {
		        list-style-type: decimal;
			}
        }
    }

    &__source {
        @include textMix('Roboto', 0.9375rem, 400);
        color: $color-abbey-gray;
        float: right;
        font-style: italic;
    }

    hr {
        border: 0;
        background-color: $color-yellow-orange;
        height: 1px;
        margin-bottom: 1.75rem;
    }
}
