/*---
title: Newsletter Signup
section: Home/Topic Page Components
---
This component is almost always displayed in a sidebar.
```example:html
<div class="newsletter-signup">
	<h3>Be informed first</h3>
	Receive the SCRIP Intelligence newsletters.
	<div class="form--single-row">
		<input class="inf-standard-form-field">
		<button class="button--filled">Sign Up</button>
	</div>
</div>
```
*/

.newsletter-signup {
    @include textMix('PT Serif', 16px, 400);
    background-color: $color-concrete-gray;
    margin-bottom: 2rem;
    padding: 1rem;
    width: 100%;

    h3 {
        font-weight: 400;
        margin-bottom: 0.5rem;
    }

    &__error {
        @extend .error-text;
        display: none;
        margin-top: 0.5rem;
    }

    &-after-submit {
        display: none;
    }

     &-needs-registration {
        @extend .error-text;
        display: none;
        margin-top: 0.5rem;
        font-style: normal;
    }
}
