.agroBuyers{
	h1{
		margin-bottom: 0.75rem;
		@include breakpoint('small') {
			font-size: 2.25rem;
		}
	}
	.sub-heading-text{
	    font-family: "PT Serif", Georgia, serif;
		font-style: italic;
		font-weight: 400;
		color: #231f20;
		margin-bottom: 1.625rem;
		font-size: 1.5em;
		line-height: 30px;
		@include breakpoint('small') {
			font-size: 1rem;
		}
	}
	.buyers-guide-listing{
		width: 100%;
		float: left;
		margin-bottom: 0.25rem;
			@media only screen and (min-width: 481px) and (max-width: 767px) { 
				.article-preview__headline{
					line-height: 26px;
				}
			}
			.buyers-guide-row{
				width: 100%;
				float: left;
				.buyers-guide{
					width: 32%;
					@include breakpoint('small'){
						width: 100%;
					}
					float: left;
					margin-top: 0.75rem;	
					margin-bottom: 1rem;
					margin-right: 2%;
					&:nth-child(3n){
						margin-right: 0%;
					}
					@media only screen and (max-width: 767px) { 
						margin-bottom: 0rem;
					}
					.image{
						width: 360px;
						//height: 152px; 
						text-align: center;
						vertical-align: top;
						display: table-cell;
						@include breakpoint('small'){
							width: 100%;
							height: auto;
						}
						img{
							width: 100%;
							margin-bottom: 8px;
							@include breakpoint('small'){
								width: 100%;
								margin-bottom: 4px;
							}
						}
					}
					h4{
						font-family: 'Roboto Condensed' Helvetica, Arial, sans-serif;
						font-size: 1.5em;
						font-weight: 700;
						font-style: normal;
											
					}
				}
			}
	}
	.buyers-guide-listing-link{
		width: 100%;
		margin-bottom: 1.5rem;
			.buyers-guide-row{
				width: 100%;
				.buyers-guide{
					width: 48%; 
					&:nth-child(even) {
						margin-right: 0;
					} 
					float: left;
					margin-top: 0.75rem;	
					margin-right: 3%;
					@media only screen and (max-width: 1024px) { 
						margin-top: 0rem;
					}
					@include breakpoint('small'){
						width: 100%;
					}
					.image{
						text-align: center;
						vertical-align: top; 
						@include breakpoint('small'){
							width: 100%;
							height: auto;
						}
						img{
							max-width: 100%;
							width: 100%;
						}
					}
					
					h4{
						font-family: 'Roboto Condensed' Helvetica, Arial, sans-serif;
						font-size: 1.5em;
						font-weight: 700;
						font-style: normal;
						margin-bottom: 1rem;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						@include breakpoint('small'){
							margin-bottom: 0.375rem;
						}
					}
					a.link{
						margin-top: 1rem;
						margin-bottom: 1rem;
						font-family: "PT Serif", Georgia, serif;
						font-style: regular;
						font-weight: 400;
						font-size: 1.125rem;
						line-height: 30px;
						display: block;
						clear: both;
						@include breakpoint('small'){
							margin-top: 0.375rem;
						}
					}
				}
			}	
	}
	.buyers-guide-listing-text{
		width: 100%; 
		margin-bottom: 1.5rem;
			.buyers-guide-row{
				width: 100%;
				.buyers-guide{
					width: 48%;
					@include breakpoint('small'){
					width: 100%;
					margin-bottom: 0;
					}
					&:nth-child(even) {
						margin-right: 0;
					}
					float: left;
					margin-top: 0.75rem;	
					margin-bottom: 2rem;
					margin-right: 3%;
					border: 1px solid $color-light-gray;
					border-radius: 2px;
					padding: 1.5rem 1.5rem 1rem;
					.text {
						p{
							-webkit-box-flex: 1;
							-ms-flex: 1 0 auto;
							flex: 1 0 auto;
							height: 200px; 
							position: relative;
							overflow: hidden;
							&:after{
								content: "";
								position: absolute;
								width: 100%;
								height: 50px;
								bottom: 0;
								left: 0;
								background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, white 45%, white 100%);
								background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 45%, white 100%);
								z-index: 227;
							}
						}
					}
					h4{
						font-family: 'Roboto Condensed' Helvetica, Arial, sans-serif;
						font-size: 1.5em;
						font-weight: 700;
						font-style: normal;
						margin-bottom: 1rem;					
					}
					a.link{
						font-family: "PT Serif", Georgia, serif;
						font-style: regular;
						font-weight: 400;
						font-size: 1.125rem;
						line-height: 25px;
						height: 50px;
						overflow: hidden;
						display: block;
					}
				}
			}	
	}
	hr{
		margin-bottom: 2.125rem;
		@media only screen and (max-width: 767px) { 
			margin-bottom: 1rem;
		}
	}
	.contact_terms{
		margin-bottom: 1.5rem;
		clear: both;
		font-family: "PT Serif", Georgia, serif;
		font-style: regular;
		font-weight: 400;
		font-size: 1.125rem;
		.orange{
			color: $color-pumpkin-orange;
		}
	}
}