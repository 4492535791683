.generic-content {
	margin-bottom: 2rem;

	&__dateline {
		@include textMix('Roboto', 14px, 500)
		margin-bottom: 2rem;
	}

	h1 {
		@extend h1;
		color: $color-zeus-black;
		margin-bottom: 0.75rem;

		@include breakpoint('medium') {
			font-size: 2.25rem;
			margin-bottom: 1rem;
		}
	}

	h2, h3, h4, h5, h6 {
		color: $color-zeus-black;
	}

	h3 {
		margin-bottom: 1rem;
	}

	p {
		@include breakpoint('medium') {
			line-height: 1.75rem;
		}
	}

	h6, ul, ul li {
		padding-bottom: .75rem;

		&:last-child {
			padding-bottom: 0;
		}
	}

	hr {
		clear: both;
		margin: 2rem 0;
	}
}
