.topic-subtopic {
    clear: both;

    &__header {
        position: relative;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1.5rem;

        @include breakpoint('medium') {
            margin-bottom: 3rem;
        }
        &.header-line-control {
            &::after {
                content: '';
                position: absolute;
                height: 4px;
                left: 0;
                right: 0;
                z-index: 0;
            }
            & > * {
                position: relative;
                flex: none;
                background: white;
                z-index: 1;
            }
            .topic-subtopic__alert {
                padding-left: 1rem;
                .action-flag {
                    margin-left: 0;
                }
            }
        }
    }

	&__set-alert {
		text-align: right;
	}

	&__sponsor {
		@include textMix('Roboto', 1.2rem, 300);
		margin-bottom: 2rem;
		text-align: center;

		img {
			display: inline-block;
			margin-left: 0.5rem;
			vertical-align: middle;
		}
	}

	&__subtitle {
		margin-bottom: 1.5rem;
	}

    &__title {
        color: $color-zeus-black;
        flex: 1 1 auto;
        flex: none;
        padding-right: 1rem;

        .topic-subtopic__header-explore-link {
            @include textMix('Roboto', 1rem, 500);
            margin-left: 1.375rem;
        }

        @include breakpoint('medium') {
            .topic-subtopic__header-explore-link {
                margin-left: 0;
                display: block;
                position: absolute;
            }
        }

        @include breakpoint('small') {
            font-size: 2rem;

            .topic-subtopic__header-explore-link {
                margin-top: 0.5rem;
            }
        }
    }

    &__alert {
        @include textMix('Roboto', 0.875rem, 400);

		& .action-flag {
			padding-top: 0;
		}

        &-icon {
            @include svg-fill($color-dusty-gray);
			display: none;
            height: 1.5rem;
            margin-right: 0.5rem;
            width: 1.5rem;
            vertical-align: middle;

			&.is-active {
				display: inline-block;
			}
        }

        @include breakpoint('small') {

            &-icon {
                margin: 0;
            }

            .js-set-topic-label {
                display: none;
            }

        }
    }

    &:after {
        content: "";
        clear: both;
        display: block;
    }
}
