.related-left-rail{
	width: 100%;
	float: inherit;
	clear: both;
	.relatedlinks{
		width: 100%;
	}
	.allrelatedlinks{
		width: 100%;
		
		@include breakpoint('medium') {
			width: 98%;
		}
		ul{
			margin-bottom: 1.25rem;
			padding-bottom: 0.625rem;
			border-bottom: 1px solid #d1d3d4;
			float: left;
			width: 50%;
			padding: 0 1%;
			@include breakpoint('small') {
				float: none;
				width: 100%;
			}
		}
		&:last-child{
			ul{
				border-bottom: none;
				@include breakpoint('medium') {
					border-bottom: 1px solid #d1d3d4;
				}
			}
		}
	}
}
.article-right-rail, .manually-curated-content__right-rail{
	.allrelatedlinks{
		ul{
			float: inherit;
			width: 100%;
		}
	}
}

.companyList{
	ul{
		height: 464px;
		overflow: auto;
		margin-bottom: 1.5rem;
		margin-top: 1rem;
	}
	.article-topics__li{
		&:before{
			position: relative;
		}
		a{
			position: relative;
			left: 5px;
			top: -2px;
		}
	}
}

.companyPeerGrp{
	.button-msg-webUser{
		float: none;
	}
	.goBtn{
		float: right;
		margin-bottom: 1rem;
	}
	.button-msg-webUser{
		.msg_link{
			float: none;
			a{
				display: block;
				float: none;
				margin-bottom: 10px;
				&:last-child{
					margin-bottom: 0px;
				}
			}
			&:after{
				clear: both;
				content: '';
				display: table;
			}
		}
	}
}

.swissgroup{
	.swissImg{
		float: right;
		margin-left: 10px;
	}
}


.article-related-content{
	.article-related-content__date{
		margin-bottom: 0px;
		border-bottom: none !important;
	}
	h2{
		&.title{
			margin-bottom: 24px;
		}
	}
} 
.balticChartsWrapper{
	margin-bottom: 2rem;
	width: 100%;
	margin-top: 10px;
#balticDryData{
	text-transform: initial;
	float: left;
}
#latest-value{
		float: right;
	span.fall{
         color: #f40600;
         background: url("/dist/img/chart_down.gif");
         background-repeat: no-repeat;
         background-position: right center;
         padding-right: 10px;
        margin-right: 0;
   }
   span.rise{
		color: #56c405;
		background: url("/dist/img/chart_up.gif");
        background-repeat: no-repeat;
        background-position: right center;
        padding-right: 10px;
         margin-right: 0;
     }

}	
.balticChart{	
	width: 100%; 
	height: 350px;
	font-family:"Roboto",​Arial Narrow,​sans-serif;
	font-style: normal;
	font-weight: 400;
	.amcharts-chart-div{
		
	}
	.amChartsLegend.amcharts-legend-div{
		margin-top: 2rem;
	}
	.amChartsButton{
		border-radius: 3px;
		border: 1px solid $color-light-gray;
		font-size: 0.7rem;
		margin-left: 3px;
	}
	.amcharts-period-input-selected{
		background: $color-cardinal-red;		
		color: #FFF;
		border-radius: 3px;
		border: 1px solid $color-light-gray;
		font-size: 0.7rem;
		margin-left: 3px;
	}
	.amChartsButton.amcharts-period-input{
		background: none;		
	}
	input.amChartsButton.amcharts-period-input:hover{
		background: $color-cardinal-red;
		color: #FFF;
	}
	input.amChartsInputField{
		border-radius: 3px;
		border: 1px solid $color-light-gray;
		font-size: 0.7rem;
		width: 70px !important;
	}
	.amChartsInputField.amcharts-end-date-input{
		margin-left: 3px;
	}
	.amChartsPeriodSelector.amcharts-period-selector-div{
		margin-bottom: 2rem;
	}
	input.amChartsInputField.amcharts-start-date-input{
		margin-left: 6px;
	}
	@include breakpoint('small') {
		.amChartsPeriodSelector.amcharts-period-selector-div div{
			display: block !important;
			float: left !important;
			margin-bottom: 0.5rem;
		}
	}
	@include breakpoint('medium') {
		.amChartsPeriodSelector.amcharts-period-selector-div div{
			display: block !important;
			float: left !important;
			margin-bottom: 0.5rem;
		}
	}
	@include breakpoint('medium-small') {
		.amChartsPeriodSelector.amcharts-period-selector-div div{
			display: block !important;
			float: left !important;
			margin-bottom: 0.5rem;
		}
	}
}
}

	