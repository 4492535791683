.article-tags-bar {
    align-self: flex-start;
    background-color: $color-concrete-gray;
    display: inline-block;
    padding: 6px 12px;

    a {
        text-transform: none;
    }

    &__label {
        @extend h6;
        font-size: 14px;
    }

    @include breakpoint('medium') {
        max-width: 100%;

        & .bar-separated-link-list {
            width: calc(100% - 2.5rem);
            display: inline-flex;
        }

        &__label {
            display: inline-block;
            width: 2.2rem;
        }

        a {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 100%;
        }

        // Two tags
        a:nth-last-child(2):first-child,
        a:nth-last-child(2):first-child ~ a {
            width: 50%;
        }

        // Three tags
        a:nth-last-child(3):first-child,
        a:nth-last-child(3):first-child ~ a {
            width: calc(100% / 3);
        }

    }
}
