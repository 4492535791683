/*---
title: Search Facets
section: Search
---

```example:html

```
*/



.search__facets {
    @include textMix('Roboto', 13px, 400);
    border: 1px solid $color-light-gray;
    margin-bottom: 20px;

    @include breakpoint('medium') {
        display: none;
        order: 3;

        &.is-expanded {
            display: block;
            margin-right: 0;
            margin-bottom: 0;
        }
    }
}

.facets {

    &__mobile-button {
        display: none;

        @include breakpoint('medium') {
            display: block;
            width: 100%;
            margin-bottom: 30px;
            border: 0;
            background-color: $color-yellow-orange;
            text-align: center;

            &--show {
                order: 2;
            }
            &--hide {
                display: none;
                order: 3;
            }

            &--show.is-expanded,
            &--hide.is-expanded {
                display: block;
                margin-bottom: 0;
            }

            &--bottom.is-expanded {
                margin-bottom: 1rem;
            }

            h6.show-filters {
                display: block;
                padding: 10px;
            }

            h6.hide-filters {
                display: none;
            }

            &.is-expanded {
                h6.show-filters {
                    display: none;
                }

                h6.hide-filters {
                    display: block;
                    padding: 10px;
                }
            }
        }
    }

    &__header {
        padding: 20px;
        border-bottom: 2px solid $color-light-gray;

        h3 {
            display: inline-block;
            color: $color-zeus-black;
        }
        cursor: pointer;
      cursor: pointer;
    }

    &__content {
        padding: 20px;
    }

    &__section {
        font-size: 15px;
        line-height: 23px;

        &:not(:first-child) {
            padding-top: 20px;
            border-top: 1px solid $color-light-gray;
        }
    }

    &__fieldset {
        border: 0;
        margin: 0 0 0.5rem;
        padding: 0;
    }

    &__legend {
        display: inline-block;
        width: 100%;
        padding-bottom: 15px;
        color: $color-zeus-black;
        position: relative;

        h4 {
            display: inline;
        }

        button {
            position: absolute;
            right: 5px;
        }
    }

    &__section-item,
    &__section-list {
        padding-bottom: 0.5rem;
    }

    &__section-list {

        &--sub {
            padding-top: 0.25rem;
            padding-left: 2rem;
            padding-bottom: 0.25rem;

            // if the subfacet is collapsed, hide the subfacet list
            &.collapsedSubfacet {
                display: none;
            }

            input[type="checkbox"] + label {
                font-size: 13px;
                line-height: 22px;
            }
        }


        /* * *

        FACET LABELS

        * * */
        input[type="checkbox"],
        input[type="radio"] {
            position: absolute;
            opacity: 0; // hiding to replace with custom checkbox style
        }
    }

    &__label {
        display: flex;
        font-size: 15px;
        line-height: 23px;
        position: relative;

        &:before {
            content: " ";
            display: block;
            flex: 0 0 auto;
            height: 17px;
            width: 17px;
            border: 1px solid $color-light-gray;
            border-radius: 20%;
            margin-right: 15px;
            margin-top: 2px;
        }

        &-content {
            flex: 1 1 auto;
        }
        &-count {
            color: $color-dusty-gray;
            font-size: 0.75rem;
            margin-left: 0.25rem;
        }

		input[disabled] ~ & {
			opacity: 0.5;
		}
    }

    input[type="checkbox"]:checked + &__label,
    input[type="radio"]:checked + &__label {
        font-weight: 700;
    }

    input[type="checkbox"] + &__label {
        &:before {
            width: 22px;
            height: 22px;
            margin-top: 0;
            margin-right: 10px;
            background-color: white;
            border: 1px #adadad solid;
            border-radius: 0;
            cursor: pointer;
            user-select: none;
        }
        &:after {
            content: '';
            display: block;
            position: absolute;
            border-right: 3px solid transparent;
            border-bottom: 3px solid transparent;
            height: 16px;
            width: 8px;
            transform: rotate(45deg) skew(10deg);
            transform-origin: 80% 120%;
            pointer-events: none;
        }
    }

    input[type="radio"] + &__label:before {
        border-radius: 50%; // override above border-radius from style shared with checkbox
    }

    input[type="checkbox"]:checked + &__label:after {
        border-color: white;
    }

    input[type="radio"]:checked + &__label:after {
        content: "\2022";
        position: absolute;
        left: 3px;
        top: -1px;
        font-size: 30px;
    }


    &__field-group {
        padding-left: 32px;
        &--from, &--to {
          position: relative;
          overflow: visible;
        }

        &--from {
            margin-top: 10px;
            padding-bottom: 10px;
        }

        label {
            color: $color-old-silver-gray;
            font-size: 13px;
            display: block;

            h4 {
                display: inline;
            }
        }

        input {
            @extend .inf-standard-form-field;
            float: left;
            width: 78%;
        }

        svg {
            @include svg-fill($color-dusty-gray);
            width: 20px;
            height: 30px;
            margin-left: 10px;
            vertical-align: middle;
        }

        &--companies {
            padding: 0;

            input {
                float: none;
                width: 100%;
            }

            .dropdown-menu {
                position: absolute;
                border: 1px solid $color-light-gray;
                border-top: 0;
                max-height: 150px;
                overflow-y: auto;
                overflow-x: hidden;
                padding: 0.5rem;
                background-color: $color-white;
                width: 100%;
                z-index: 999;
            }
        }
    }

    &__button {
        @extend .button--plain;
        color: $color-cardinal-red;

        &--clear {
            float: right;
            line-height: 29px;
            cursor: pointer;
        }

        &--clear-company {
            padding: 0;
            margin-right: 11px;

            svg {
                @include svg-fill($color-cardinal-red);
                margin: 0;
                width: 15px;
                height: 15px;
            }
        }

        &--more,
        &--less,
        &--add {
            &.collapsed {
                display: none;
            }

            svg {
                @include svg-fill($color-cardinal-red);
                width: 10px;
                vertical-align: middle;
                margin-left: 3px;
            }
        }

        &--add {
            svg {
                vertical-align: text-top;
            }
        }

        &--toggle-section {
            @include svg-fill($color-old-silver-gray);
            // transition: all 300ms ease-in 0s;
            // transform-origin: 50%;
            width: 16px;
            height: 23px;

            &.collapsed {
                transform: rotate(180deg);
            }
        }

        &--toggle-subcat {
            float: right;

            svg {
                @include svg-fill($color-light-gray);
                width: 18px;
            }
        }

        &--collapse-subfacet {
            display: inline-block;

            &.collapsedSubfacet {
                display: none;
            }
        }

        &--expand-subfacet {
            display: none;

            &.collapsedSubfacet {
                display: inline-block;
            }
        }

    }

    &__company-typeahead-wrapper {
        position: relative; // child with width: 100% will expand to this elm width
        display: inline-block;
        width: calc(100% - 30px);
    }
}

.saved-company__delete-icon {
    @include svg-fill($color-light-gray);
    height: 14px;
    width: 12px;
    vertical-align: baseline;
    float: right;
    cursor: pointer;
}
.saved-company:hover .saved-company__delete-icon{
    @include svg-fill($color-zeus-black);
}
