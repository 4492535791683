﻿.grid-main-col-dir {
    display: grid;
    display: -ms-grid;
    display: -webkit-grid;
    grid-auto-flow: column;
    -webkit-grid-auto-flow: column;
    -ms-grid-auto-flow: column;
    margin-bottom: 40px;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
}

.flex_col {
    position: relative;
    overflow: hidden;
    > a {
        position: relative;
        display: inherit;
    }
    &.sponsored_cont {
        padding: 10px;
        ul li img { 
            display: block;
            margin-top: -6px;
            margin-bottom: -6px;
        }
    }
}

.grid-main-row-dir {
    display: grid;
    display: -ms-grid;
    display: -webkit-grid;
    grid-auto-flow: row;
    -webkit-grid-auto-flow: row;
    -ms-grid-auto-flow: row;
    margin-bottom: 20px;
    grid-column-gap: 20px;
    grid-row-gap: 60px;
}

.flex_col .article-preview__headline, 
.article-preview--small .flex_col .article-preview__headline  {
    font-size: 20px;
    margin-top: 0;
}

.flex_col img.article-preview__image {
    width: 100%;
    vertical-align: bottom;
    margin-bottom: 1.25rem;
}

.flex_col .article-summary.blur {
    &, p, span {
        position: relative;
        -webkit-line-clamp: 4;
        -ms-line-clamp: 4;
        display: -webkit-box;
        overflow: hidden;
        -webkit-box-orient: vertical;
        -ms-box-orient: vertical;
    }
}

.flex_col .article-metadata {
    margin-top: 0;
    margin-bottom: 5px;
}

.comp-headline {
    font-family: "Roboto Condensed",Arial Narrow,sans-serif;
    font-weight: 700;
    font-size: 38px;
    margin-bottom: 12px;
    color: #000;
}

.flex_col.blank {
    height: 0px;
    border:none;
}

.grid-main-4col_1 {
    grid-template-rows: repeat(1, 1fr);
    -webkit-grid-template-rows: repeat(1, 1fr);
    -ms-grid-template-rows: 1fr;
    grid-template-columns: repeat(4,1fr);
}

.grid-main-4col_2 {
    grid-template-rows: repeat(2, 1fr);
    -webkit-grid-template-rows: repeat(2, 1fr);
    -ms-grid-template-rows: 1fr 1fr;
    grid-template-columns: repeat(4,1fr);
}

.grid-main-4col_3 {
    grid-template-rows: repeat(3, 1fr);
    -webkit-grid-template-rows: repeat(3, 1fr);
    -ms-grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: repeat(4,1fr);
}

.grid-main-4col_4 {
    grid-template-rows: repeat(4, 1fr);
    -webkit-grid-template-rows: repeat(4, 1fr);
    -ms-grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-template-columns: repeat(4,1fr);
}

/***3 col 2 row***/
.grid-main-3col_1 {
    grid-template-rows: repeat(1, 1fr);
    -webkit-grid-template-rows: repeat(1, 1fr);
    -ms-grid-template-rows: 1fr;
    grid-template-columns: repeat(3,1fr);
}

.grid-main-3col_2 {
    grid-template-rows: repeat(2, 1fr);
    -webkit-grid-template-rows: repeat(2, 1fr);
    -ms-grid-template-rows: 1fr 1fr;
    grid-template-columns: repeat(3,1fr);
}

/***3 col 3 row***/
.grid-main-3col_3 {
    grid-template-rows: repeat(3, 1fr);
    -webkit-grid-template-rows: repeat(3, 1fr);
    -ms-grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: repeat(3,1fr);
}
/***3 col 4 row***/
.grid-main-3col_4 {
    grid-template-rows: repeat(4, 1fr);
    -webkit-grid-template-rows: repeat(4, 1fr);
    -ms-grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-template-columns: repeat(3,1fr);
}

.grid-main-row-4col_1 {
    grid-template-columns: repeat(4, 1fr);
    -webkit-grid-template-columns: repeat(4, 1fr);
    -ms-grid-template-columns: 1fr 1fr 1fr 1fr;
}

.grid-main-row-4col_2 {
    grid-template-columns: repeat(4, 1fr);
    -webkit-grid-template-columns: repeat(4, 1fr);
    -ms-grid-template-columns: 1fr 1fr 1fr 1fr;
}

.grid-main-row-4col_3 {
    grid-template-columns: repeat(4, 1fr);
    -webkit-grid-template-columns: repeat(4, 1fr);
    -ms-grid-template-columns: 1fr 1fr 1fr 1fr;
}

.grid-main-row-4col_4 {
    grid-template-columns: repeat(4, 1fr);
    -webkit-grid-template-columns: repeat(4, 1fr);
    -ms-grid-template-columns: 1fr 1fr 1fr 1fr;
}

.grid-main-row-3col_1 {
    grid-template-columns: repeat(3, 1fr);
    -webkit-grid-template-columns: repeat(3, 1fr);
    -ms-grid-template-columns: 1fr 1fr 1fr;
}

.grid-main-row-3col_2 {
    grid-template-columns: repeat(3, 1fr);
    -webkit-grid-template-columns: repeat(3, 1fr);
    -ms-grid-template-columns: 1fr 1fr 1fr;
}

.grid-main-row-3col_3 {
    grid-template-columns: repeat(3, 1fr);
    -webkit-grid-template-columns: repeat(3, 1fr);
    -ms-grid-template-columns: 1fr 1fr 1fr;
}

.grid-main-row-3col_4 {
    grid-template-columns: repeat(3, 1fr);
    -webkit-grid-template-columns: repeat(3, 1fr);
    -ms-grid-template-columns: 1fr 1fr 1fr;
}

@media only screen and (max-width: 1200px) {
    .grid-main-col-dir {
        grid-template-rows: repeat(4, 1fr);
        -webkit-grid-template-rows: repeat(4, 1fr);
        -ms-grid-template-rows: 1fr 1fr 1fr 1fr;
        grid-template-columns: repeat(3, 1fr);
        -webkit-grid-template-columns: repeat(3, 1fr);
        -ms-grid-template-rows: 1fr 1fr 1fr;
    }

    .grid-main-row-dir {
        grid-template-columns: repeat(3, 1fr);
        -webkit-grid-template-columns: repeat(3, 1fr);
        -ms-grid-template-rows: 1fr 1fr 1fr;
    }
}

@media only screen and (max-width: 980px) {
    .grid-main-col-dir {
        grid-template-rows: repeat(6, 1fr);
        -webkit-grid-template-rows: repeat(6, 1fr);
        -ms-grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
        grid-template-columns: repeat(2, 1fr);
        -webkit-grid-template-columns: repeat(2, 1fr);
        -ms-grid-template-columns: 1fr 1fr;
    }

    .grid-main-row-dir {
        grid-template-columns: repeat(2, 1fr);
        -webkit-grid-template-columns: repeat(2, 1fr);
        -ms-grid-template-columns: 1fr 1fr;
    }
}

@media only screen and (max-width: 520px) {
    .grid-main-col-dir {
        grid-template-rows: repeat(8, 1fr);
        -webkit-grid-template-rows: repeat(8, 1fr);
        -ms-grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
        grid-template-columns: repeat(1, 1fr);
        -ms-grid-template-columns: 1fr;
    }

    .grid-main-row-dir {
        grid-template-columns: repeat(1, 1fr);
        -ms-grid-template-columns: 1fr;
    }
}