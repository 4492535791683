    /*---
title: Global
section: Colors
---
```example:color
@color: #231f20 @name: Zeus Black
@color: #414042 @name: Ship Gray
@color: #58595b @name: Abbey Gray
@color: #979797 @name: Dusty Gray
@color: #f1f2f2 @name: Concrete Gray
@color: #e2e4e4 @name: Mercury Gray
@color: #d1d3d4 @name: Light Gray
@color: #ffffff @name: White
@color: #f1f1f1 @name: gray
@color: #bbbcbe @name: Light Gray B
@color: #808285 @name: Medium Gray 8
@color: #6D6E71 @name: Dove Gray
```
*/

$color-zeus-black:          #231f20;
$color-ship-gray:           #414042;
$color-abbey-gray:          #58595b;
$color-dove-gray:           #6D6E71;
$color-old-silver-gray:     #808285;
$color-dusty-gray:          #979797;
$color-concrete-gray:       #f1f2f2;
$color-concrete-table-gray: #f1f1f1;
$color-mercury-gray:        #e2e4e4;
$color-light-gray:          #d1d3d4;
$color-sub-gray: 			#6e6c6d;
$color-x11-gray:            #bbbcbe;
$color-iceberg-green:       #d6ebdc;
$color-antique-white:       #ffeedd;
$color-white:               #ffffff;
$color-misty-pink:          #fce5e6;
$btn-bg-blue:               #00719c;
$btn-bg-hover-mint:         #00ac9d;


/*---
title: SCRIP
section: Colors
---
```example:color
@color: #be1e2d @name: Cardinal Red
@color: #f7941e @name: Tree Poppy Orange
@color: #f9af40 @name: Butterscotch Orange
@color: #fbb040 @name: Yellow Orange
@color: #daa520 @name: Goldenrod
@color: #31be48 @name: Lime Green
```
*/

$color-butterscotch-orange: #f9af40;
$color-red:                 #bb1528;          
$color-cardinal-red:        #be1e2d;
$color-goldenrod:           #daa520;
$color-lime-green:          #31be48;
$color-tree-poppy-orange:   #f7941e;
$color-yellow-orange:       #fbb040;
$color-tree-poppy-orange-ten: #FEF4E8;



/*---
title: Pink Sheet
section: Colors
---
```example:color
@color: #b1005b @name: Hibiscus Pink
@color: #d82b6a @name: Dogwood Rose
@color: #B2547F @name: Pearly Pink
@color: #a8618d @name: Light Plum
@color: #BC608C @name: Mystic Maroon
@color: #e6c0d5 @name: Blossom Pink
@color: #d3bac3 @name: Flair Pink
@color: #FEE2E7 @name: Piggy Pink
@color: #FFF2F4 @name: Wisp Pink
@color: #fbf2f4 @name: Soft Peach
@color: #f8EFF3 @name: mystic marroon Ten
```
*/

$color-hibiscus-pink:       #b1005b;
$color-dogwood-rose:        #d82b6a;
$color-pearly-pink:         #B2547F;
$color-light-plum:          #a8618d;
$color-mystic-maroon:       #BC608C;
$color-flair-pink:          #d3bac3;
$color-blossom-pink:        #e6c0d5;
$color-piggy-pink:          #FEE2E7;
$color-soft-peach:          #fbf2f4;
$color-wisp-pink:           #FFF2F4;
$color-mystic-maroon-pink:  #f8EFF3;


/*---
title: Rose Sheet
section: Colors
---
```example:color
@color: #ba005b @name: Rose Red
@color: #DD3580 @name: Rubine Red
@color: #c8206e @name: Valerian Red
@color: #f8d7e6 @name: Cherub Pink
@color: #F9F8F0 @name: Valerian Red Ten
```
*/

$color-rose-red:            #ba005b;
$color-rubine-red:          #DD3580;
$color-valerian-red:        #c8206e;
$color-cherub-pink:         #f8d7e6;
$color-my-view:    			#9E310D;
$color-valerian-red-rose: #F9E8F0;



/*---
title: Medtech
section: Colors
---
```example:color
@color: #007cc3 @name: Sapphire Blue
@color: #034a7c @name: Marine Blue
@color: #FDD95F @name: Jonquil Yellow
@color: #F1CE54 @name: Maize Yellow
@color: #edefef @name: Porcelain White
@color: #FFFBEF @name: Jonquil Yellow Ten
```
*/

$color-sapphire-blue:       #007cc3;
$color-marine-blue:         #034a7c;
$color-jonquil-yellow:      #FDD95F;
$color-maize-yellow:        #F1CE54;
$color-porcelain-white:     #edefef;
$color-jonquil-yellow-med:  #FFFBEF;

/*---
title: Invivo
section: Colors
---
```example:color
@color: #6a1b13 @name: Rosewood Red
@color: #7e2718 @name: Copper Red
@color: #b03718 @name: Rusty Red
@color: #ca4d2c @name: Dark Coral
@color: #ca4d2c @name: Dark Coral Ten
```
*/
$color-rosewood-red:        #6a1b13;
$color-copper-red:          #7e2718;
$color-rusty-red:           #b03718;
$color-dark-coral:          #ca4d2c;
$color-dark-coral-ten:      #faede9;


/*---
title: Agri
section: Colors
---
```example:color
@color: #9E310D @name: primary
@color: #F5821F @name: Pumpkin
@color: #c12d3b @name: Persian Red
@color: #FEF2E8 @name: secondary color ten percent
```
*/
$color-primary-orange: #9E310D;
$color-pumpkin-orange: #F5821F;
$color-persian-red: #c12d3b;
$color-sec-agri: #FEF2E8;


/*---
title: Insurance Day
section: Colors
---
```example:color
@color: #DE4361 @name: primary Default State
@color: #b0173d @name: secondary color 
@color: #91211E @name: primary Hover State
@color: #F7E7EB @name: secondary color ten percent
```
*/
$color-primary-crimson: #DE4361;
$color-cardinal-color: #b0173d; 
$color-primary-insurance-hover: #91211E;
$color-sec-ID: #F7E7EB;


/*---
title: Lloyds
section: Colors
---
```example:color
@color: #1191D1 @name: primary Default State
@color: #0f5487 @name: secondary color 
@color: #002d4f @name: primary Hover State
@color: #E7EEF3 @name: secondary color ten percent
```
*/
$color-primary-default: #1191D1;
$color-secondary-color: #0f5487; 
$color-primary-hover: #002d4f;
$color-sec-ll: #E7EEF3;

/*

updated colour variables: ISW-4832

*/

$color-pharma-pink: #ef426f;
$color-scrip-plum: #510039;
$color-scrip-redvio: #b23d96;
$color-invivo-lilac: #B23D96;
$color-genbull-lilac: #B23D96;
$color-medins-teal: #00A7B5;
$color-hbwi-teal: #00A7B5;

$color-pharma-pink-hover: #B22E52;
$color-scrip-plum-hover: #2B0018;
$color-scrip-redvio-hover: #b23d96;
$color-invivo-lilac-hover: #862871;
$color-genbull-lilac-hover: #862871;
$color-medins-teal-hover: #007F8A;
$color-hbwi-teal-hover: #007F8A;

// 10% of primary
$color-pharma-pink-bg: #fdecf1;
$color-scrip-plum-bg: #eee6eb;
$color-invivo-lilac-bg: #f7ecf5;
$color-genbull-lilac-bg: #f7ecf5;
$color-medins-teal-bg: #e6f6f8;
$color-hbwi-teal-bg: #e6f6f8;

/*---
title: Chemist and Druggist
section: Colors
---
```example:color
@color: #1f1f1f @name: primary text color
@color: #00b1eb @name: secondary text color
```
*/
$color-primary-text: #1f1f1f;
$color-secondary-text: #00b1eb;
$color-primary-button: #15abeb;
$color-primary-button-text: #ffffff;
$color-primary-button-hover: #00bcee;
$color-secondary-button: #ffffff;
$color-secondary-button-text: #6c6c6c;
$color-warning: #db4242;
$color-warning-hover: #973131;
$color-subscription: #f5a623;
$color-subscription-hover: #f29502;
$color-yellow: #f7b142;
$color-yellow-text: #fda00c;
$color-salmon: #f38181;
$color-turquoise: #17ead9;
$color-turquoise-text: #28d0dd;
$color-purple: #6078ea;
$color-light-green: #42e695;
$color-light-green-text: #12e287;
$color-dark-green: #3bb2b8;
$color-dark-purple: #6a11b9;
$color-dark-purple-text: #5c27bf;
$color-blue: #01b3e9;
$color-grey-1: #1f1f1f;
$color-grey-2: #444444;
$color-grey-3: #656668;
$color-grey-4: #bebebe;
$color-grey-5: #f0eff4;
$color-grey-6: #f4f4f4;
$cnd-micro-sub-menu-link: #ffffff;
$cnd-micro-sub-menu-link-hover: #15abeb;
$cnd-micro-sub-menu-link-highlight: #c8a560;
$cnd-micro-sub-menu-link-chevron: #ffffff;
$cnd-micro-sub-menu-nav-bg-hover: #6b6b6b;
$cnd-micro-sub-menu-nav-link: #ffffff;
$cnd-micro-sub-menu-hamburger-icon: #ffffff;
$cnd-audience-newsletter-camp-bg: #dff3fa;
$cnd-tabing-border: #f0eff4;
$cnd-tabing-active-border: #15abeb;
$cnd-tabing-background: #ffffff;