/*---
title: Article Preview
section: Home/Topic Page Components
---

```example:html
<section class="article-preview">

    <div class="article-metadata">
        <div class="article-preview__bookmarker pop-out__trigger">
            <span class="action-flag__label">Bookmark</span>
            <svg class="action-flag__icon action-flag__icon--bookmark">
                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="svg-sprite.svg#bookmark"></use>
            </svg>
        </div>
        <ul>
            <li>
                <time class="article-metadata__date">15 Sep 2015</time>
            </li>
            <li>
                <h6>News</h6>
            </li>
            <li>
                <svg class="action-flag__icon">
                    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="svg-sprite.svg#audio"></use>
                </svg>
            </li>
        </ul>
    </div>

    <div class="article-preview__inner-wrapper">
        <h1 class="article-preview__headline">
            <a href="#">ICAAC 2015: Merck's Bezlotoxumab A 'Big Step' In C Diff</a>
        </h1>

        <span class="article-preview__byline">
            by Mandy Jackson
        </span>

        <div class="article-summary">
            <p>Merck &amp; Co.'s bezlotoxumab for the treatment of recurrent <i>clostridium difficile</i> (C diff) infections is "a big step" for treatment of the debilitating and potentially deadly gut infection, according to Dale Gerding, a professor at Loyola University Chicago Stritch School of Medicine and a lead investigator for the monoclonal antibody's Phase III clinical trials.</p>
        </div>
    </div>

    <div class="article-preview__tags bar-separated-link-list">
        <a href="#">Metabolic Disorders</a>
        <a href="#">Switzerland</a>
        <a href="#">Clinical Trials</a>
    </div>
</section>
```
*/

.article-preview {
    padding-bottom: 48px;
    position: relative;
    width: 100%;

    & > a {
        display: block;
        position: relative;
    }

    &__inner-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      & > * {
        order: 2;
        width: 100%;
      }
      .article-metadata {
        order: 1;
      }
    }

    &__two-column {
        display: flex;
        justify-content: space-between;
        width: calc(100% + 2rem);
        flex-wrap: wrap;
        margin: 0 -1rem;

        .article-preview {
            width: 300px;
            flex: 1 0 auto;
            margin: 0 1rem;
        }
    }

    &__image {
        width: 100%;
    }

    &__category,
    &__icon {
        display: inline-block;
        position: relative;
        margin-right: 12px;
    }

    &__icon {
        @include svg-fill($color-dusty-gray);
        height: 24px;
        width: 24px;
        display: inline-block;
        vertical-align: bottom;
    }

    &__bookmarker {
        float: right;
		padding-top: 0;

		.action-flag__label {
			@include textMix('Roboto', 11px, 500);
			letter-spacing: 1px;
			color: $color-dusty-gray;

	        @include breakpoint('medium') {
	            display: none;
	        }
		}

		&:hover .action-flag__label {
			color: $color-zeus-black;
		}
    }

    &__category {
        text-transform: uppercase;
        @include textMix('Roboto Condensed', 15px, 600);
    }

    &__headline {
        @include textMix('Roboto', 30px, 700);
        line-height: 1.4em;
        margin-bottom: 0.875rem;
        &,
        & a {
            color: $color-zeus-black;

            &:hover {
                color: $color-cardinal-red;
            }
        }
    }
    &__byline {
        @include textMix('Roboto', 16px, 700);
        display: block;
        line-height: 18px;
        margin-bottom: 0.875rem;

		a {
	        @include textMix('Roboto', 16px, 700);
	        color: black;
	        line-height: 18px;
		}
    }

    &__ad {
        margin: 12px auto 0;
        width: 100%;

        img {
            max-width: 100%;
        }
    }

    &__tags {
        width: 100%;

        a {
            &:first-child {
                padding-left: 0;
            }
        }
        
        &.bar-separated-link-list {
            font-size: 0;
            margin-top: .875rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
    
            a {
                margin-right: 5px;
                padding-right: 5px;
                vertical-align: bottom;
    
                &:first-child {
                    padding-left: 0;
                }
                
                &:last-child {
                    margin-right: 0;
                    padding-right: 0;
                }
            }
        }
    }

    p {
        font-size: 18px;
        line-height: 27px;
        color: black;
    }


    &--small &__date,
    &--small &__category {
        font-size: 14px;
    }
    &--small &__headline {
        font-size: 18px;
    }
    &--small &__byline {
        font-size: 14px;
    }
    &--small p {
        font-size: 16px;
        line-height: 25px;
    }

    &--bordered {
        border-top: 2px solid #ccc;
    }
    &--boxed {
        border: 2px solid #ccc;
		margin-bottom: 2.25rem;
        padding: 12px;
    }

    &:after {
        clear: both;
        content: "";
        display: block;
    }

    // .latest-news previews need a wrapper for all content besides the image
    // Image floats left, but other content needs to stay within right "col"
    &__wrapper {
        margin-left: 388px;
    }

    &.latest-news-flexi {
        margin-bottom: 1.5rem;
        padding-bottom: 0;
        &.greybg {
            background: #f1f2f2;
            padding: 1.25rem;
            .blur p::before {
                background: linear-gradient(to bottom,rgba(241,242,242,0) 0,rgb(241,242,242) 100%);
            }
            .full-diminished-button {
                position: relative;
                width: auto;
                border-top: 1rem solid white;
                margin-left: -1.25rem;
                margin-right: -1.25rem;
                margin-bottom: -1.25rem;
            }
        }
        &.headingline {
            .comp-headline {
                display: flex;
                align-items: baseline;
                column-gap: 5px;
                @include breakpoint('small') {
                    display: block;
                }
                &::after {
                    content: '';
                    flex-grow: 1;
                    height: 2px;
                    background-color: gray;
                    @include breakpoint('small') {
                        display: none;
                    }
                }
            }
        }
        .grid-main-row-dir {
            margin-bottom: 0;
        }
        .flex_col {
            display: flex;
            flex-direction: column;
            row-gap: 1rem;
            border-bottom: none;
            img {
                vertical-align: bottom;
                margin-bottom: 0;
            }
            .article-metadata {
                margin-top: 0;
                margin-bottom: -1rem;
                li {
                    margin-top: 0;
                    margin-bottom: 1rem;
                }
            }
            .article-preview__headline {
                margin-top: 0;
                margin-bottom: 0;
            }
            .article-preview__byline {
                margin-top: 0;
                margin-bottom: 0;
            }
            .article-summary.blur {
                margin-top: 0;
                margin-bottom: 0;
            }
            .article-preview__tags.bar-separated-link-list {
                position: static;
                bottom: auto;
                margin-top: auto;
            }
            & > *:last-child {
                margin-bottom: 0;
            }
        }
        .full-diminished-button {
            margin-top: 1rem;
            margin-bottom: 0;
        }
    }
}

div.article-call-to-action--subscribe {
    display: inline-block;
    width: 100%;
    word-break: break-word;

    .paywall-message {
        padding: 10px 25px;
    }

    .article-call-to-action__column {
        display: inline-block;
        width: 100%;
        float: left;
        padding: 0 0 5px;
    }

    .article-call-to-action__column.trial-level {
        width:100%;
    }
}

.article-body-content div.article-call-to-action--sign-in-up {
    .paywall-message {
        padding: 10px 25px;
    }

    @media (min-width: 640px) {
        display: inline-block;
        width: 100%;
        word-break: break-word;
        .article-call-to-action__column {
            display: inline-block;
            width: 50%;
            float: left;
            padding: 10px 25px;
        }
    
        .article-call-to-action__column.trial-level {
            width:100%;
        }
    }
}

div.article-call-to-action--subscribe {
  .article-call-to-action__title {
    padding: 0px 20px;
  }
}